import { Button, Form } from 'antd';
import Lottie from 'lottie-react';
import styled, { css } from 'styled-components';

import ONBOARDING_BACKGROUND_SVG from '@/assets/onboarding_background.svg';

type ButtonProps = {
  step: number;
};

export const StyledForm: typeof Form = styled(Form)`
  height: 100vh;
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const Wrapper = styled.div`
  height: 100%;
  background-image: url(${ONBOARDING_BACKGROUND_SVG});
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
`;

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  padding: 40px 10px 30px 10px;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  height: fit-content;
  width: 100%;
  justify-content: center;

  @media (min-width: 340px) {
    padding: 40px 30px 30px 30px;
  }
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.white};

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    width: 75%;
  }
  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    padding: 70px 40px 0px 40px;
  }
  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    overflow: visible;
    width: 880px;
    padding: 40px 110px 0px 110px;
  }
`;

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

export const StyledButton = styled(Button)<ButtonProps>(
  ({ step }) => css`
    z-index: 10;
    border-radius: 50px;

    @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
      align-self: ${step === 4 || step === 0 ? `center` : step === 6 ? 'flex-start' : `unset`};
    }
  `
);

export const CurvedArrowVector = styled.img`
  position: absolute;
  bottom: 50px;
  right: 180px;
`;

export const StraightRightArrowVector = styled.img`
  position: absolute;
  bottom: -10px;
  right: 180px;
`;

export const StraightLeftArrowVector = styled.img`
  position: absolute;
  left: 180px;
`;

const getContentJustify = (step: number) => {
  switch (step) {
    case 0:
    case 5:
      return 'center';
    case 6:
      return 'flex-start';
    default:
      return 'space-between';
  }
};

export const ButtonWrapper = styled.div<ButtonProps>(
  ({ step }) => css`
    position: relative;
    display: flex;
    width: 100%;
    align-items: flex-end;
    margin-top: 80px;
    margin-bottom: 16px;
    align-self: flex-start;
    flex-direction: column-reverse;
    gap: 16px;

    @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
      margin-top: 16px;
      flex-direction: row;
      align-items: center;
      justify-content: ${getContentJustify(step)};
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
      margin-top: 100px;
      align-self: flex-end;
    }
  `
);

export const StyledLottie = styled(Lottie)`
  width: 160px;
  height: 160px;
  align-self: flex-start;
`;

export const StyledUmyImg = styled.img`
  position: absolute;
  bottom: 0;
  right: -350px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.lg}) {
    right: -280px;
  }
`;

export const StepperWrapper = styled.div`
  align-self: center;
`;
