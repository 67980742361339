import { AnalyticsTrackEvents } from '@constants';
import { IPost } from '@sdk/contracts';
import { Empty } from 'antd';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CreatePost } from './CreatePost';
import { PostItem } from './PostItem';
import { Wrapper } from './PostWidget.styles';

import { LoadingCircleSpinner } from '@/components/Loaders';
import { PostForm } from '@/components/PostForm';
import { WidgetBase } from '@/components/WidgetBase';
import { useSegment } from '@/hooks';
import { usePostsList, useProfile } from '@/store';
import { WidgetProps } from '@/types';

type Props = {
  editable?: boolean;
} & WidgetProps;

const LOADER_SIZE = 40;

export const PostWidget: React.FC<Props> = props => {
  const { t } = useTranslation('CommonDashboardTranslations');
  const { posts = [], isPending, loadMorePosts } = usePostsList();
  const { fireEvent } = useSegment();
  const listRef = useRef<HTMLDivElement>(null);
  const { editable } = props;
  const navigateTo = useNavigate();
  const {
    roleCheck: { isStudent }
  } = useProfile();

  const onClick = (post: IPost) => {
    navigateTo(`/post/${post.slug}`);
    fireEvent(AnalyticsTrackEvents.HOME_WIDGET, {
      widgetName: 'NEWS'
    });
  };

  const onScroll = useCallback(() => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10 && !isPending) {
        loadMorePosts();
      }
    }
  }, [isPending, loadMorePosts]);

  return (
    <>
      <WidgetBase title={t('NEWS')} addon={<CreatePost editable={editable} />} {...props}>
        <Wrapper ref={listRef} onScroll={onScroll}>
          {posts.length === 0 && <Empty description={t('NO_NEWS_TO_SHOW')} />}
          {posts.map(post => (
            <PostItem key={post._id} onClick={() => onClick(post)} post={post} />
          ))}
          {isPending && <LoadingCircleSpinner size={LOADER_SIZE} />}
        </Wrapper>
        {!isStudent && <PostForm editable />}
      </WidgetBase>
    </>
  );
};
