import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';

import { isPdf } from './mimeTypeCheck';

const PDF_WORKER_URL = '//cdn.jsdelivr.net/npm/pdfjs-dist@2.16.105/build/pdf.worker.js';
GlobalWorkerOptions.workerSrc = PDF_WORKER_URL;

const getBase64Pdf = async (pdf: Blob): Promise<string> => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(pdf);
    reader.onload = () => {
      const base64String = reader.result as string;
      const base64Data = base64String.split(',')[1];
      resolve(base64Data);
    };
  });
};

export const getFilePagesNumber = async (file: File): Promise<number> => {
  const isPdfFile = isPdf(file.type);

  if (!isPdfFile) return 0;

  return new Promise(resolve => {
    getBase64Pdf(file).then(base64String => {
      const binaryString = atob(base64String);
      const byteArray = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
      }
      getDocument(byteArray).promise.then(pdf => {
        resolve(pdf.numPages);
      });
    });
  });
};
