import styled from 'styled-components';

export const Wrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  color: ${({ isActive, theme }) => isActive && theme.colors.meadreg};
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  & > :first-child {
    padding-right: 5px;
  }
`;
