import { AnalyticsTrackEvents } from '@constants';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { TestModeCard } from './TestModeCard';
import { AddonTextWrapper, AddonWrapper, StyledIcon, Wrapper } from './TestModeWidget.styles';

import UMY_FREE_MODE from '@/assets/umy-free-mode.svg';
import UMY_REVIEW_MODE from '@/assets/umy-review-mode.svg';
import UMY_SIMULATION_MODE from '@/assets/umy-simulation-mode.svg';
import { WidgetBase } from '@/components/WidgetBase';
import { useIsMobile, useSegment } from '@/hooks';
import { WidgetProps } from '@/types';

export const TestModeWidget: React.FC<WidgetProps> = props => {
  const { t } = useTranslation('CommonDashboardTranslations');
  const navigateTo = useNavigate();
  const { colors } = useTheme();
  const isMobile = useIsMobile('xs');
  const { fireEvent } = useSegment();

  const handleNavigateToTests = () => {
    navigateTo('/tests');
    fireEvent(AnalyticsTrackEvents.HOME_WIDGET, {
      widgetName: 'QUIZZES'
    });
  };

  const items = [
    {
      title: t('FREE_MODE_TITLE'),
      description: t('FREE_MODE_DESCRIPTION'),
      image: UMY_FREE_MODE,
      path: '/tests/free-mode',
      backgroundColor: colors.neoghost,
      borderColor: colors.neoreg
    },
    {
      title: t('REVIEW_MODE_TITLE'),
      description: t('REVIEW_MODE_DESCRIPTION'),
      image: UMY_REVIEW_MODE,
      path: '/tests/review/preview',
      backgroundColor: `${colors.warning}1A`,
      borderColor: colors.warning
    },
    {
      title: t('PRACTICE_MODE_TITLE'),
      description: t('PRACTICE_TESTS_DESCRIPTION'),
      image: UMY_SIMULATION_MODE,
      path: '/tests/practice-mode',
      backgroundColor: `${colors.error}1A`,
      borderColor: colors.error
    }
  ];

  return (
    <WidgetBase
      title={t('MULTIPLE_CHOICE_TEST')}
      {...props}
      onHeaderClick={handleNavigateToTests}
      addon={
        <AddonWrapper>
          {!isMobile && (
            <AddonTextWrapper className="addon-text">{t('ALL_TESTS')}</AddonTextWrapper>
          )}
          <StyledIcon className="addon-icon" size={24} i="ArrowRight" />
        </AddonWrapper>
      }>
      <Wrapper>
        {items.map(item => (
          <TestModeCard key={item.title} data={item} />
        ))}
      </Wrapper>
    </WidgetBase>
  );
};
