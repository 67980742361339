import {
  IAddExamGroupMembers,
  ICreateExamGroup,
  IRemoveExamGroupMember,
  IUpdateExamGroup
} from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { sdk } from '@/services';

const ExamGroupService = sdk.getService('ExamGroupService');

export const useMutateExamGroup = () => {
  const queryClient = useQueryClient();
  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['exam-groups'] });

  const { t } = useTranslation('ExamGroupsTranslations');

  const { mutate: createExamGroup, isPending: isCreating } = useMutation({
    mutationFn: (create: ICreateExamGroup) => ExamGroupService.createExamGroup(create),
    onSuccess: () => {
      onSuccess();
      message.success(t('EXAM_GROUP_CREATED_SUCCESSFULLY'));
    }
  });

  const { mutate: updateExamGroup, isPending: isUpdating } = useMutation({
    mutationFn: (update: IUpdateExamGroup) => ExamGroupService.updateExamGroup(update),
    onSuccess: () => {
      onSuccess();
      message.success(t('EXAM_GROUP_UPDATED_SUCCESSFULLY'));
    }
  });

  const { mutate: removeExamGroup, isPending: isRemoving } = useMutation({
    mutationFn: (groupId: string) => ExamGroupService.removeExamGroup(groupId),
    onSuccess: () => {
      onSuccess();
      message.success(t('EXAM_GROUP_DELETED_SUCCESSFULLY'));
    }
  });

  const { mutate: removeManyExamGroups, isPending: isRemovingMany } = useMutation({
    mutationFn: (groupsIds: string[]) => ExamGroupService.removeManyExamGroups(groupsIds),
    onSuccess: () => {
      onSuccess();
      message.success(t('EXAM_GROUPS_DELETED_SUCCESSFULLY'));
    }
  });

  const { mutateAsync: addMembers, isPending: isAddingMember } = useMutation({
    mutationFn: (members: IAddExamGroupMembers) => ExamGroupService.addMembers(members),
    onSuccess: () => {
      onSuccess();
      message.success(t('MEMBERS_ADDED_SUCCESSFULLY'));
    }
  });

  const { mutateAsync: removeMember, isPending: isRemovingMember } = useMutation({
    mutationFn: (member: IRemoveExamGroupMember) => ExamGroupService.removeMember(member),
    onSuccess: () => {
      onSuccess();
      message.success(t('MEMBER_REMOVED_SUCCESSFULLY'));
    }
  });

  return {
    createExamGroup,
    updateExamGroup,
    removeExamGroup,
    removeManyExamGroups,
    addMembers,
    removeMember,
    isWorking:
      isCreating || isUpdating || isRemoving || isRemovingMany || isAddingMember || isRemovingMember
  };
};
