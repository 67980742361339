import { EActivityAuditStatus, EActivityType } from '@constants';

import { defaultTheme } from '../styles/theme';

import audio_bg from '@/assets/audio_bg.svg';
import interactive_video_bg from '@/assets/interactive_video_bg.svg';
import pdf_bg from '@/assets/pdf_bg.svg';
import quiz_bg from '@/assets/quiz_bg.svg';
import video_bg from '@/assets/video_bg.svg';
import { EIconType } from '@/components/Icon';

export const ACTIVITY_ICONS: { [key in EActivityType]: EIconType } = {
  [EActivityType.VIDEO]: 'Video',
  [EActivityType.AUDIO]: 'Headphones',
  [EActivityType.PDF]: 'Document',
  [EActivityType.INTERACTIVE_VIDEO]: 'TouchInteraction',
  [EActivityType.DELIVERABLE]: 'DocumentExport',
  [EActivityType.QUIZ]: 'TestTool'
};

export const ACTIVITY_COVER_BACKGROUND: { [key in EActivityType]: string } = {
  [EActivityType.VIDEO]: video_bg,
  [EActivityType.AUDIO]: audio_bg,
  [EActivityType.PDF]: pdf_bg,
  [EActivityType.QUIZ]: quiz_bg,
  [EActivityType.INTERACTIVE_VIDEO]: interactive_video_bg,
  [EActivityType.DELIVERABLE]: pdf_bg
};

export const ACTIVITY_AUDIT_STATUS_TAG_INFO: Record<
  EActivityAuditStatus,
  { color: string; icon: EIconType }
> = {
  [EActivityAuditStatus.CORRECT]: {
    color: defaultTheme.colors.meadreg,
    icon: 'Checkmark'
  },
  [EActivityAuditStatus.OBSOLETE]: {
    color: defaultTheme.colors.charcoal,
    icon: 'PortOutput'
  },
  [EActivityAuditStatus.OLD_FORMAT]: {
    color: defaultTheme.colors.neoreg,
    icon: 'ResultOld'
  },
  [EActivityAuditStatus.NON_COMPLIANT_STANDARDS]: {
    color: defaultTheme.colors.error,
    icon: 'ErrorFilled'
  }
};
