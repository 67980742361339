import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const ProblemReportService = sdk.getService('ProblemReportService');

export const useProblemReportsDetail = (problemReportId?: string) => {
  const queryKey = ['problem-reports', problemReportId];
  const { isLoading, data, error } = useQuery({
    queryKey,
    queryFn: () => ProblemReportService.getProblemReportDetail(problemReportId!),
    enabled: !!problemReportId
  });

  return {
    problemReport: data,
    isLoading,
    error
  };
};
