import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

import { Icon } from '../Icon';

import { Wrapper } from './PageTitle.styles';

type Props = {
  title?: string | React.ReactNode;
  children?: React.ReactNode;
  showBackButton?: boolean;
  icon?: React.ReactNode;
  onBack?: () => void;
};

export const PageTitle: React.FC<Props> = ({
  title,
  children,
  icon,
  showBackButton = false,
  onBack
}) => {
  const navigate = useNavigate();
  return (
    <Wrapper showtitlestyles={typeof title === 'string'}>
      <div>
        {showBackButton && (
          <Button
            type="link"
            size="large"
            data-testid="back-button"
            onClick={() => {
              onBack && onBack();
              navigate(-1);
            }}
            icon={<Icon i="ChevronLeft" size="24" />}
          />
        )}
        {title}
        {icon}
      </div>
      {children}
    </Wrapper>
  );
};
