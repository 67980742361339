export enum ECountry {
  ES = 'ES',
  AR = 'AR',
  AD = 'AD',
  BO = 'BO',
  BR = 'BR',
  CL = 'CL',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  DO = 'DO',
  EC = 'EC',
  FR = 'FR',
  GF = 'GF',
  GT = 'GT',
  HT = 'HT',
  HN = 'HN',
  JM = 'JM',
  MX = 'MX',
  NI = 'NI',
  PA = 'PA',
  PY = 'PY',
  PE = 'PE',
  PR = 'PR',
  PT = 'PT',
  SR = 'SR',
  SV = 'SV',
  UY = 'UY',
  VE = 'VE',
  MA = 'MA'
}

export const countriesList = Object.entries(ECountry).map(([_label, value]) => ({
  label: value,
  value
}));
