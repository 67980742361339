export enum StudentPreviewToolbarTab {
  INFO = 'INFO',
  PROGRESS = 'PROGRESS',
  HISTORY = 'HISTORY'
}

export enum EUserStatus {
  NEW = 'NEW',
  NOT_ACTIVATED = 'NOT_ACTIVATED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SLEEP = 'SLEEP'
}
