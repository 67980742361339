import { IQuizFilters } from '@sdk/contracts';
import { useQuery } from '@tanstack/react-query';
import { create as createStore } from 'zustand';

import { sdk } from '@/services';

const INITIAL_PAGE = 1;
const INITIAL_LIMIT = 50;

const QuizService = sdk.getService('QuizService');
interface IStore {
  page: number;
  limit: number;
  search: string;
  filter?: IQuizFilters;
  setSearch: (search: string) => void;
  setPageAndLimit: (page: number, limit: number) => void;
  setFilter: (filter: IQuizFilters) => void;
}

const store = createStore<IStore>(set => ({
  page: INITIAL_PAGE,
  limit: INITIAL_LIMIT,
  search: '',
  setSearch: (search: string) => set(state => ({ ...state, search, page: INITIAL_PAGE })),
  setPageAndLimit: (page: number, limit: number) => set(state => ({ ...state, page, limit })),
  setFilter: (filter: IQuizFilters) => set(state => ({ ...state, page: INITIAL_PAGE, filter }))
}));

export const useMyQuizzesList = () => {
  const { page, limit, setPageAndLimit, search, setSearch, filter, setFilter } = store();
  const queryKey = ['quizzes', `page-${page}`, `limit-${limit}`, search, filter];

  const { isLoading, data } = useQuery({
    queryKey,
    queryFn: () => QuizService.getQuizzes(search, { page, limit }, filter)
  });

  const pagination = data && {
    page: data.page,
    limit: data.limit,
    hasNextPage: data.hasNextPage,
    hasPrevPage: data.hasPrevPage,
    nextPage: data.nextPage,
    pagingCounter: data.pagingCounter,
    prevPage: data.prevPage,
    totalDocs: data.totalDocs,
    totalPages: data.totalPages
  };

  return {
    quizzes: data?.docs,
    isLoading,
    pagination,
    filter,
    setFilter,
    setPageAndLimit,
    setSearch
  };
};
