import styled from 'styled-components';

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const ResultContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  justify-content: flex-start;

  & > div.ant-form-item {
    margin-bottom: 0;
    margin-right: 25px;
  }
`;

export const Label = styled.p`
  font-weight: 500;
`;

export const Wrapper = styled.div`
  width: 100%;
`;
