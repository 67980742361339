import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.beau};
  background: ${({ theme }) => theme.colors.white};

  .ant-divider {
    margin: 0;
  }
`;

export const TitleWrapper = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;

  & :first-child {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    flex: none;
    color: ${({ theme }) => theme.colors.marrow};
  }

  & :last-child {
    display: flex;
    align-items: center;
    gap: 8px;
    overflow: hidden;
    flex: none;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }
`;

export const TaskHistoryWrapper = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.link};
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;
