import { IReportFilters } from '@sdk/contracts';
import { useMutation } from '@tanstack/react-query';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

import { sdk } from '@/services';

const UserService = sdk.getService('UserService');

export const useAllUsersReport = () => {
  const { t } = useTranslation('CoursesStatsTranslations');

  const { mutate: requestAllReports, isPending } = useMutation({
    mutationFn: (filter?: IReportFilters) => UserService.requestAllUsersReport(filter),
    onSuccess: () => notification.success({ message: t('REPORT_REQUESTED_CHECK_EMAIL') })
  });

  return {
    isRequestingUsersReport: isPending,
    requestAllReports
  };
};
