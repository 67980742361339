const ManagerRoutesTranslations = {
  MY_ACADEMY: {
    es: 'Mi Academia',
    en: 'My Academy',
    cat: 'La meva Acadèmia',
    eus: 'Nire Akademia'
  },
  STUDENTS: {
    es: 'Alumnos',
    en: 'Students',
    cat: 'Alumnes',
    eus: 'Ikasleak'
  },
  INTERNAL_USERS: {
    es: 'Usuarios internos',
    en: 'Internal users',
    cat: 'Usuaris interns',
    eus: 'Barne erabiltzaileak'
  },
  CATEGORIES: {
    es: 'Categorías',
    en: 'Categories',
    cat: 'Categories',
    eus: 'Kategoriak'
  },
  TESTS: {
    es: 'Tests',
    en: 'Tests',
    cat: 'Tests',
    eus: 'Testak'
  },
  CREATE_TEST: {
    es: 'Crear test',
    en: 'Create test',
    cat: 'Crear test',
    eus: 'Testa sortu'
  },
  TUTORING_GROUPS: {
    es: 'Tutorías',
    en: 'Tutoring groups',
    cat: 'Tutories',
    eus: 'Tutoretzak'
  },
  EXAM_GROUPS: {
    es: 'Convocatorias',
    en: 'Exam groups',
    cat: 'Convocatòries',
    eus: 'Deialdiak'
  },
  SCRAPPED_EXAM_GROUPS: {
    es: 'Convocatorias descargadas',
    en: 'Scrapped exam groups',
    cat: 'Convocatòries descarregades',
    eus: 'Convocatorias descargadas'
  },
  QUESTIONS_BANK: {
    es: 'Banco de preguntas',
    en: 'Questions bank',
    cat: 'Banc de preguntes',
    eus: 'Galderen bankua'
  },
  STATS: {
    es: 'Estadísticas',
    en: 'Stats',
    cat: 'Estadístiques',
    eus: 'Estatistikak'
  },
  COURSES_STATS: {
    es: 'Cursos',
    en: 'Courses',
    cat: 'Cursos',
    eus: 'Ikastaroak'
  },
  ACTIVITY_STATS: {
    es: 'Actividad',
    en: 'Activity',
    cat: 'Activitat',
    eus: 'Ekintzak'
  },
  CONTENT: {
    es: 'Contenido',
    en: 'Content',
    cat: 'Contingut',
    eus: 'Edukiak'
  },
  HISTORIC: {
    es: 'Tabla de gestión',
    en: 'Management table',
    cat: 'Taula de gestió',
    eus: 'Kudeaketa taula'
  },
  TOTALS: {
    es: 'Totales',
    en: 'Totals',
    cat: 'Totals',
    eus: 'Guztiak'
  },
  PROBLEM_REPORTS: {
    es: 'Informes de problemas',
    en: 'Problem reports',
    cat: 'Informes de problemes',
    eus: 'Arazoen txostenak'
  },
  INDEXATION_LANDINGS: {
    es: 'Landings de indexación',
    en: 'Indexation landings',
    cat: "Landings d'indexació",
    eus: 'Indexazio landings'
  }
};

const AdminRoutesTranslations = {
  USERS: {
    es: 'Usuarios',
    en: 'Users',
    cat: 'Usuaris',
    eus: 'Erabiltzaileak'
  },
  AI_TESTING: {
    es: 'Pruebas AI',
    en: 'AI Testing',
    cat: 'Proves d`IA',
    eus: 'AI Froga'
  },
  ACADEMIES: {
    es: 'Academias',
    en: 'Academies',
    cat: 'Acadèmies',
    eus: 'Akademiak'
  },
  ALL_COURSES: {
    es: 'Todos los cursos',
    en: 'All courses',
    cat: 'Tots els cursos',
    eus: 'Ikastaro guztiak'
  },
  IA_TESTING: {
    es: 'IA Testing',
    en: 'IA Testing',
    cat: 'IA Testing',
    eus: 'IA Froga'
  },
  AI_TEACHERS: {
    es: 'Profesores IA',
    en: 'AI Teachers',
    cat: 'Professors IA',
    eus: 'IA Irakasleak'
  },
  PROMPTS: {
    es: 'Prompts',
    en: 'Prompts',
    cat: 'Prompts',
    eus: 'Prompts'
  }
};

const StudentRoutesTranslations = {
  USERS: {
    es: 'Usuarios',
    en: 'Users',
    cat: 'Usuaris',
    eus: 'Erabiltzaileak'
  },
  ACADEMIES: {
    es: 'Academias',
    en: 'Academies',
    cat: 'Acadèmies',
    eus: 'Akademiak'
  },
  ALL_COURSES: {
    es: 'Todos los cursos',
    en: 'All courses',
    cat: 'Tots els cursos',
    eus: 'Guztiak ikastaroak'
  },
  TEST: {
    es: 'Test',
    en: 'Test',
    cat: 'Prova',
    eus: 'Froga'
  },
  ALL_TEST: {
    es: 'Todos los test',
    en: 'All test',
    cat: 'Totes les proves',
    eus: 'Guztiak frogak'
  },
  DELIVERABLES: {
    es: 'Entregables',
    en: 'Deliverables',
    cat: 'Lliurables',
    eus: 'Entregagarriak'
  },
  ONBOARDING: {
    es: 'Onboarding',
    en: 'Onboarding',
    cat: 'Onboarding',
    eus: 'Onboarding'
  },
  PROFILE: {
    es: 'Perfil',
    en: 'Profile',
    cat: 'Perfil',
    eus: 'Profila'
  },
  STORE: {
    es: 'Tienda',
    en: 'Store',
    cat: 'Botiga',
    eus: 'Denda'
  },
  RANKING: {
    es: 'Ranking',
    en: 'Ranking',
    cat: 'Ranking',
    eus: 'Ranking'
  },
  STUDY: {
    es: 'Estudiar',
    en: 'Study',
    cat: 'Estudiar',
    eus: 'Ikasi'
  },
  PRACTICE_WITH_TESTS: {
    es: 'Practicar con tests',
    en: 'Practice with tests',
    cat: 'Practicar amb proves',
    eus: 'Praktikatu frogekin'
  }
};

const PublicRoutesTranslations = {
  LANDING: {
    es: 'Landing',
    en: 'Landing',
    cat: 'Pàgina d`inici',
    eus: 'Hasiera orria'
  },
  LOGIN: {
    es: 'Login',
    en: 'Login',
    cat: 'Iniciar sessió',
    eus: 'Sartu'
  },
  SIGN_UP: {
    es: 'Registro',
    en: 'Sign up',
    cat: 'Registre',
    eus: 'Izena eman'
  },
  RECOVER: {
    es: 'Recuperar',
    en: 'Recover',
    cat: 'Recuperar',
    eus: 'Berreskuratu'
  }
};

const CommonRoutesTranslations = {
  DASHBOARD: {
    es: 'Inicio',
    en: 'Dashboard',
    cat: 'Tauler de control',
    eus: 'Arbela panela'
  },
  COURSES: {
    es: 'Cursos',
    en: 'Courses',
    cat: 'Cursos',
    eus: 'Ikastaroak'
  },
  TEACHERS: {
    es: 'Profesores',
    en: 'Teachers',
    cat: 'Professors',
    eus: 'Irakasleak'
  },
  DOUBTS: {
    es: 'Dudas',
    en: 'Doubts',
    cat: 'Dubtes',
    eus: 'Zalantzak'
  },
  MY_DOUBTS: {
    es: 'Mis dudas',
    en: 'My doubts',
    cat: 'Els meus dubtes',
    eus: 'Nire zalantzak'
  },
  LIVE_EVENTS: {
    es: 'Clases en directo',
    en: 'Live classes',
    cat: 'Classes en directe',
    eus: 'Zuzeneko klaseak'
  },
  CALENDAR: {
    es: 'Calendario',
    en: 'Calendar',
    cat: 'Calendari',
    eus: 'Egutegia'
  },
  COMMUNITY: {
    es: 'Comunidad',
    en: 'Community',
    cat: 'Comunitat',
    eus: 'Komunitatea'
  },
  EVENT: {
    es: 'Evento',
    en: 'Event',
    cat: 'Esdeveniment',
    eus: 'Ekitaldi'
  },
  DEMODAY: {
    es: 'Demo Day',
    en: 'Demo Day',
    cat: 'Demo Day',
    eus: 'Demo Day'
  },
  MATERIAL: {
    es: 'Material',
    en: 'Material',
    cat: 'Material',
    eus: 'Materiala'
  },
  CHAT_TUTOR: {
    es: 'Chat tutor',
    en: 'Chat tutor',
    cat: 'Xat tutor',
    eus: 'Tutorearen txat'
  },
  POST: {
    es: 'Post',
    en: 'Post',
    cat: 'Post',
    eus: 'Post'
  },
  SECTION_REVIEWS: {
    es: 'Valoraciones de secciones',
    en: 'Section reviews',
    cat: 'Valoracions de seccions',
    eus: 'Atalak baloratu'
  }
};

export const RoutesTranslations = {
  ...CommonRoutesTranslations,
  ...ManagerRoutesTranslations,
  ...AdminRoutesTranslations,
  ...StudentRoutesTranslations,
  ...PublicRoutesTranslations
};
