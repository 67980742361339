import * as dateFns from 'date-fns';
import { es, enGB } from 'date-fns/locale';
import * as dateFnsTz from 'date-fns-tz';

type ITimeUnit = 'months' | 'days' | 'hours' | 'minutes' | 'seconds' | 'milliseconds';
type IZonedTime = {
  dateTime: string;
  timeZone: string;
};

export const DAY_FORMAT = 'DD/MM';

const LOCALE = es;

const parse = (d: Date | string, format?: string) => {
  if (!format) return typeof d === 'string' ? new Date(d) : d;
  return dateFns.parse(d as string, format, new Date());
};

export const dateProvider = {
  parse,
  format: (d: Date | string | number = new Date(), format: string) =>
    dateFns.format(typeof d === 'number' ? d : parse(d), format, { locale: LOCALE }),
  formatLocal: (d: Date | string) => parse(d).toLocaleString(),
  getDayOfMonth: (d: Date | string) => dateFns.getDate(parse(d)),
  setYear: (date: number | Date, year: number) => dateFns.setYear(date, year),
  setMonth: (date: number | Date, month: number) => dateFns.setMonth(date, month),
  startOfDay: (date: Date = new Date()) => dateFns.startOfDay(date),
  endOfDay: (date: Date = new Date()) => dateFns.endOfDay(date),
  startOfWeek: (date: Date = new Date()) => dateFns.startOfWeek(date),
  startOfMonth: (date: number | Date) => dateFns.startOfMonth(date),
  endOfMonth: (date: number | Date) => dateFns.endOfMonth(date),
  addMonths: (date: number | Date, amount: number) => dateFns.addMonths(date, amount),
  subMonths: (date: number | Date, amount: number) => dateFns.subMonths(date, amount),
  formatRemainingTime: (d: Date | string = new Date()) =>
    dateFns.formatDistance(parse(d), new Date(), { locale: LOCALE }),
  formatElapsedTime: (d: Date | string = new Date()) =>
    dateFns.formatDistance(new Date(), parse(d), { locale: LOCALE }),
  formatDuration: (startedAt: Date | string, endedAt: Date | string = new Date()) => {
    const ms = dateFns.differenceInMilliseconds(parse(endedAt), parse(startedAt));
    return `${ms}ms`;
  },
  firstMonthDate: (d: Date) => dateFns.startOfMonth(d),
  lastMonthDate: (d: Date) => dateFns.endOfMonth(d),
  firstPreviousMonthDate: (d: Date) => dateFns.addMonths(dateFns.startOfMonth(d), -1),
  lastPreviousMonthDate: (d: Date) => dateFns.addMonths(dateFns.endOfMonth(d), -1),

  getDaysInMonth: (year: number, month: number) =>
    dateFns.getDaysInMonth(new Date(year, month - 1)),
  now: () => new Date(),
  monthName: (month: number, locale = enGB) =>
    dateFns.format(new Date(0, month), 'MMMM', { locale }),
  add: (d: Date, amount: number, unit: ITimeUnit): Date => {
    switch (unit) {
      case 'months':
        return dateFns.addMonths(d, amount);
      case 'days':
        return dateFns.addDays(d, amount);
      case 'hours':
        return dateFns.addHours(d, amount);
      case 'minutes':
        return dateFns.addMinutes(d, amount);
      case 'seconds':
        return dateFns.addSeconds(d, amount);
      case 'milliseconds':
        return dateFns.addMilliseconds(d, amount);
      default:
        throw new Error(`Unknown time unit provided.`);
    }
  },
  addToNow: (amount: number, unit: ITimeUnit): Date => dateProvider.add(new Date(), amount, unit),
  subtract: (d: Date = new Date(), amount: number, unit: ITimeUnit): Date => {
    switch (unit) {
      case 'months':
        return dateFns.subMonths(d, amount);
      case 'days':
        return dateFns.subDays(d, amount);
      case 'hours':
        return dateFns.subHours(d, amount);
      case 'minutes':
        return dateFns.subMinutes(d, amount);
      case 'seconds':
        return dateFns.subSeconds(d, amount);
      case 'milliseconds':
        return dateFns.subMilliseconds(d, amount);
      default:
        throw new Error(`Unknown time unit provided.`);
    }
  },
  subtractToNow: (amount: number, unit: ITimeUnit) =>
    dateProvider.subtract(new Date(), amount, unit),
  diff: (_d1: Date | string, _d2: Date | string, unit: ITimeUnit): number => {
    const d1 = parse(_d1);
    const d2 = parse(_d2);
    switch (unit) {
      case 'months':
        return dateFns.differenceInMonths(d2, d1);
      case 'days':
        return dateFns.differenceInDays(d2, d1);
      case 'hours':
        return dateFns.differenceInHours(d2, d1);
      case 'minutes':
        return dateFns.differenceInMinutes(d2, d1);
      case 'seconds':
        return dateFns.differenceInSeconds(d2, d1);
      case 'milliseconds':
        return dateFns.differenceInMilliseconds(d2, d1);
      default:
        throw new Error(`Unknown time unit provided.`);
    }
  },
  isLive: (startDate: IZonedTime, endDate: IZonedTime) =>
    dateFns.isWithinInterval(dateProvider.now(), {
      start: dateProvider.utcToZonedTime(dateFns.parseISO(startDate.dateTime), startDate.timeZone),
      end: dateProvider.utcToZonedTime(dateFns.parseISO(endDate.dateTime), endDate.timeZone)
    }),
  isSameDay: (date1: Date | string, date2: Date | string) =>
    dateFns.isSameDay(parse(date1), parse(date2)),
  isAfter: (date1: Date | string, date2: Date | string) =>
    dateFns.isAfter(parse(date1), parse(date2)),
  isBefore: (date1: Date | string, date2: Date | string) =>
    dateFns.isBefore(parse(date1), parse(date2)),
  isBeforeToday: (date: Date | string) => dateFns.isBefore(parse(date), dateFns.startOfToday()),
  isBeforeTomorrow: (date: Date | string) =>
    dateFns.isBefore(parse(date), dateFns.startOfDay(dateFns.addDays(new Date(), 1))),
  isToday: (date: Date | string) => dateFns.isToday(parse(date)),
  diffFromNow: (d: Date, unit: ITimeUnit): number => dateProvider.diff(new Date(), d, unit),
  diffToNow: (d: Date, unit: ITimeUnit): number => dateProvider.diff(d, new Date(), unit),
  formatDistance: (d: Date | string) =>
    dateFns.formatDistance(new Date(d), new Date(), { addSuffix: true, locale: es }),
  zonedTimeToUtc: (date: IZonedTime) =>
    dateFnsTz.zonedTimeToUtc(dateFns.parseISO(date.dateTime), date.timeZone),
  zonedTimeToUtcFormat: (date: IZonedTime, format: string) =>
    dateProvider.format(
      dateFnsTz.zonedTimeToUtc(dateFns.parseISO(date.dateTime), date.timeZone),
      format
    ),
  utcToZonedTime: (date: Date | string, timeZone: string) =>
    dateFnsTz.utcToZonedTime(date, timeZone),
  monthToRange: (month: number, year: number) => {
    const from = dateProvider.startOfMonth(new Date(year, month));
    const to = dateProvider.endOfMonth(new Date(year, month));
    return { from, to };
  },
  decompose: (date: Date) => {
    const day = dateFns.getDate(date);
    const month = dateFns.getMonth(date);
    const year = dateFns.getYear(date);
    return { day, month, year };
  }
};
