import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PendingTasksWrapper, Wrapper } from './PendingTasksWidget.styles';
import { TaskCard } from './TaskCard';

import UMY_FIRST_STEPS_COMPLETED from '@/assets/umy_first_steps_completed.svg';
import { EmptyWidgetContent } from '@/components/EmptyWidgetContent';
import { WidgetBase } from '@/components/WidgetBase';
import { usePendingTasksList } from '@/store';
import { WidgetProps } from '@/types';

export const PendingTasksWidget: React.FC<WidgetProps> = props => {
  const { t } = useTranslation('CommonDashboardTranslations');
  const { tasks } = usePendingTasksList();
  const navigateTo = useNavigate();

  const showedTasks = useMemo(() => tasks?.slice(0, 10), [tasks]);
  const totalTasks = useMemo(() => tasks?.length, [tasks]);

  const handleNavigateToCourses = () => navigateTo('/courses');

  return (
    <WidgetBase
      title={t('PENDING_TASKS')}
      {...props}
      addon={
        <PendingTasksWrapper onClick={handleNavigateToCourses}>
          {t('PENDING_TASKS_COUNT', { totalTasks })}
        </PendingTasksWrapper>
      }>
      <Wrapper>
        {showedTasks?.length ? (
          showedTasks?.map(task => <TaskCard key={task._id} task={task} />)
        ) : (
          <EmptyWidgetContent
            title={t('NO_PENDING_TASKS_TITLE')}
            description={t('NO_PENDING_TASKS_DESCRIPTION')}
            umyImage={UMY_FIRST_STEPS_COMPLETED}
          />
        )}
      </Wrapper>
    </WidgetBase>
  );
};
