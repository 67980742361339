import { IActivity, IVideoCheckPoint } from '@sdk/contracts';
import { create as createStore } from 'zustand';

interface IStore {
  isOpen: boolean;
  hasVideoCheckPoint: boolean;
  generatedVideoCheckpoints?: IVideoCheckPoint[];
  data?: Partial<IActivity> | null;
  sectionId?: string;
  setData: (data: Partial<IActivity> | null) => void;
  setSectionId: (sectionId?: string) => void;
  setIsOpen: (isOpen: boolean) => void;
  setHasVideoCheckPoint: (hasVideoCheckPoint: boolean) => void;
  setGeneratedVideoCheckpoints: (generatedVideoCheckpoints?: IVideoCheckPoint[]) => void;
}

export const useActivityForm = createStore<IStore>(set => ({
  isOpen: false,
  data: null,
  hasVideoCheckPoint: false,
  setData: (data: Partial<IActivity> | null) => set({ data }),
  setSectionId: (sectionId?: string) => set({ sectionId }),
  setIsOpen: (isOpen: boolean) => set({ isOpen }),
  setHasVideoCheckPoint: (hasVideoCheckPoint: boolean) => set({ hasVideoCheckPoint }),
  setGeneratedVideoCheckpoints: (generatedVideoCheckpoints?: IVideoCheckPoint[]) =>
    set({ generatedVideoCheckpoints })
}));
