import styled from 'styled-components';

export const ImageWrapper = styled.img`
  position: absolute;
  bottom: 4px;
`;

export const NameWrapper = styled.span`
  position: relative;
  margin-bottom: 8px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-bottom: 40px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  font-size: 38px;
  line-height: 46px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.secondary};
`;

export const SubtitleWrapper = styled.div`
  text-align: center;
`;
