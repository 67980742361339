import { ETicketStatus } from '@constants';
import { AnalyticsTrackEvents } from '@constants';
import {
  ICreateAutomaticResponseTicket,
  ISendTicketMessage,
  ISetTicketReview,
  ITicket,
  IUpdateAssignee
} from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { useSegment } from '@/hooks';
import { sdk } from '@/services';

const TicketService = sdk.getService('TicketService');

export const useMutateMyTickets = () => {
  const { t } = useTranslation(['CommonTicketsTranslations', 'StudentTicketsTranslations']);
  const { fireEvent } = useSegment();

  const queryClient = useQueryClient();
  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['tickets'] });

  const { mutate: createAutomaticResponseTicket, isPending: isCreatingAutomaticTicket } =
    useMutation({
      mutationFn: (newTicket: ICreateAutomaticResponseTicket) =>
        TicketService.createAutomaticResponseTicket(newTicket),
      onSuccess: data => {
        onSuccess();
        message.success(t('StudentTicketsTranslations:TICKET_CREATED_SUCCESSFULLY'));
        fireEvent(AnalyticsTrackEvents.ACTIVITY_NEW_DOUBT, {
          ticketId: data._id,
          courseName: data.ticketRelatedContent.course.name
        });
        if (data.status === ETicketStatus.CLOSED_RESOLVED) {
          fireEvent(AnalyticsTrackEvents.DOUBTS_CLOSED, {
            ticketId: data._id,
            courseName: data.ticketRelatedContent.course.name,
            messagesAmount: 1,
            teacherName: 'IA',
            resolved: true
          });
        }
      }
    });

  const { mutate: updateTicket, isPending: isUpdating } = useMutation({
    mutationFn: (ticket: Partial<ITicket>) => TicketService.updateTicket(ticket),
    onSuccess
  });

  const { mutate: setTicketAsResolved, isPending: isResolving } = useMutation({
    mutationFn: (ticketId: string) => TicketService.setTicketAsResolved(ticketId),
    onSuccess: data => {
      onSuccess();
      fireEvent(AnalyticsTrackEvents.DOUBTS_CLOSED, {
        ticketId: data._id,
        courseName: data.ticketRelatedContent?.course.name,
        messagesAmount: data.messages?.length,
        teacherName: `${data.assignee?.name} ${data.assignee?.lastName}`,
        resolved: data.status === ETicketStatus.CLOSED_RESOLVED
      });
    }
  });

  const { mutate: removeTicket, isPending: isRemoving } = useMutation({
    mutationFn: (ticketId: string) => TicketService.removeTicket(ticketId),
    onSuccess: () => {
      onSuccess();
      message.success(t('CommonTicketsTranslations:TICKET_REMOVED_SUCCESSFULLY'));
    }
  });

  const { mutate: sendMessage, isPending: isSending } = useMutation({
    mutationFn: (sendTicketMessage: ISendTicketMessage) =>
      TicketService.sendMessage(sendTicketMessage),
    onSuccess
  });

  const { mutate: setTicketReview, isPending: isSettingReview } = useMutation({
    mutationFn: (ticketReview: ISetTicketReview) => TicketService.setTicketReview(ticketReview),
    onSuccess
  });

  const { mutate: updateTicketAssignee, isPending: isUpdatingAssignee } = useMutation({
    mutationFn: (updateAssignee: IUpdateAssignee) =>
      TicketService.updateTicketAssignee(updateAssignee),
    onSuccess
  });

  const { mutate: changeUsefulTicketVote, isPending: isChangingUsefulTicketVote } = useMutation({
    mutationFn: (ticketId: string) => TicketService.changeUsefulTicketVote(ticketId),
    onSuccess
  });

  return {
    updateTicket,
    removeTicket,
    sendMessage,
    setTicketReview,
    updateTicketAssignee,
    createAutomaticResponseTicket,
    changeUsefulTicketVote,
    setTicketAsResolved,
    isWorking:
      isUpdating ||
      isRemoving ||
      isSending ||
      isSettingReview ||
      isUpdatingAssignee ||
      isCreatingAutomaticTicket ||
      isChangingUsefulTicketVote ||
      isResolving
  };
};
