import { IReward } from '@sdk/contracts';
import { dateProvider, getGamificationPoints } from '@utils';

export const calculateTodayGamificationPoints = (rewards?: IReward[]): number => {
  if (!rewards) return 0;

  const today = dateProvider.now();
  return rewards.reduce((acc, reward) => {
    if (dateProvider.isSameDay(reward.createdAt, today)) {
      return acc + getGamificationPoints(reward.multiplier, reward.type);
    }
    return acc;
  }, 0);
};
