import { useTranslation } from 'react-i18next';

import { UpcomingEvents } from '@/components/UpcomingEvents';
import { WidgetBase } from '@/components/WidgetBase';
import { WidgetProps } from '@/types';

type Props = WidgetProps;

export const StudentLiveClassesWidget: React.FC<Props> = props => {
  const { t } = useTranslation('CommonDashboardTranslations');
  return (
    <WidgetBase title={t('MY_LIVE_CLASSES')} {...props}>
      <UpcomingEvents />
    </WidgetBase>
  );
};
