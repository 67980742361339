export enum EQuizMode {
  FREE_MODE = 'FREE_MODE',
  PRACTICE_MODE = 'PRACTICE_MODE',
  ACTIVITY_MODE = 'ACTIVITY_MODE'
}

export const QUIZ_MODES = Object.values(EQuizMode);

export enum EQuizStatus {
  IN_REVIEW = 'IN_REVIEW',
  REVIEWED = 'REVIEWED'
}

export const MINIMUM_QUIZ_SCORE_TO_CERTIFICATE = 9;
