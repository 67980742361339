import styled from 'styled-components';

export const FooterWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 32px 24px;
  justify-content: flex-end;
  gap: 8px;
  border-top: 1px solid ${({ theme }) => theme.colors.solitude};
  position: sticky;
  bottom: 0;
  background: ${({ theme }) => theme.colors.white};
`;
