import { AnalyticsTrackEvents } from '@constants';
import { IFile } from '@sdk/contracts';
import { Button } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Wrapper, FormulaWrapper, ButtonsWrapper } from './MessageSender.styles';

import { HtmlEditor } from '@/components/Form';
import { Icon } from '@/components/Icon';
import { InsertFormula } from '@/components/InsertFormula';
import { useInsertFormulaModal } from '@/components/InsertFormula/store';
import { Upload } from '@/components/Upload';
import { useSegment } from '@/hooks';
import { useMutateMyTickets } from '@/store';

type Props = {
  ticketId: string;
};

export const MessageSender: React.FC<Props> = ({ ticketId }) => {
  const { isWorking, sendMessage } = useMutateMyTickets();
  const { t } = useTranslation(['CommonTicketsTranslations', 'CommonQuizFormTranslations']);
  const { setIsFormulaEditorOpen } = useInsertFormulaModal();
  const [text, setText] = useState<string>();
  const [formula, setFormula] = useState<string>();
  const [file, setFile] = useState<IFile | null>(null);
  const { fireEvent } = useSegment();
  const hasFile = Boolean(file);
  const hasText = Boolean(text);
  const hasFormula = Boolean(formula);

  const handleSendMessage = () =>
    sendMessage(
      {
        ticketId,
        createMessage: {
          content: text,
          attached: file ?? undefined,
          formula: formula ?? undefined
        }
      },
      {
        onSuccess: data => {
          setFile(null);
          setFormula(undefined);
          setText(undefined);
          fireEvent(AnalyticsTrackEvents.DOUBTS_TEACHER_RESPONSE, {
            ticketId: data._id,
            courseName: data.ticketRelatedContent.course.name,
            teacherName:
              data.assignee?.name && data.assignee?.lastName
                ? `${data.assignee.name} ${data.assignee.lastName}`
                : undefined
          });
        }
      }
    );

  return (
    <Wrapper>
      {formula && <FormulaWrapper dangerouslySetInnerHTML={{ __html: formula }} />}
      <ButtonsWrapper>
        <Upload
          value={file}
          onChange={(f: IFile | IFile[] | null) => setFile(f as IFile)}
          maxCount={1}
          mode="upload"
          accept="application/pdf,image/*"
          disabled={hasText}
        />
        <Button
          icon={<Icon i="Function" />}
          onClick={() => setIsFormulaEditorOpen(true)}
          style={{ marginLeft: 8 }}>
          {t('CommonQuizFormTranslations:INSERT_FORMULA')}
        </Button>
        <InsertFormula onChange={(html?: string) => setFormula(html)} />
      </ButtonsWrapper>
      <HtmlEditor value={text} onChange={setText} disabled={hasFile || hasFormula} />
      <div>
        <Button
          size="large"
          type="primary"
          disabled={!hasText && !hasFile && !hasFormula}
          loading={isWorking}
          onClick={handleSendMessage}
          icon={<Icon i="Send" />}>
          {t('SEND')}
        </Button>
      </div>
    </Wrapper>
  );
};
