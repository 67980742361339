import { createQueryString } from '@utils';
import { Dayjs } from 'dayjs';

import { RequestService } from '../request';

import { IUserTimeResult, IUserStreak, ISessionLog } from './session-log.contract';

export class SessionLogService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  public getStudentTimeResults(id: string, dateFilter: [Dayjs, Dayjs]) {
    const [startDate, endDate] = dateFilter;
    const queryParams = createQueryString({
      filters: {
        endDate: endDate.toDate(),
        startDate: startDate.toDate()
      }
    });
    return this.fetcher<IUserTimeResult[]>(`/session-log/stats/users/${id}?${queryParams}`);
  }

  public getUserStreak = async (id: string) => {
    return this.fetcher<IUserStreak>(`/session-log/stats/users/${id}/streak`);
  };

  public getLastConnection = async (userId: string) => {
    return this.fetcher<ISessionLog>(`/session-log/stats/users/${userId}/last-connection`);
  };
}
