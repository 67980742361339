import { ERoles, EVerticalNames, ECountry, EAutonomousCommunity, EUserStatus } from '@constants';

import { IAcademy } from '../academy';
import { IBasicFile, IFile } from '../file';
import { ITag } from '../tag';
import { ITutoringGroup } from '../tutoring-group';

export interface IUser {
  _id: string;
  name: string;
  isActive: boolean;
  lastName: string;
  username: string;
  position?: string;
  avatar?: IBasicFile;
  email: string;
  role: ERoles;
  academy: Pick<IAcademy, '_id' | 'name' | 'media'>;
  tags: ITag[];
  createdAt: Date;
  updatedAt: Date;
  onBoarding?: IUserOnboarding;
  phone?: string;
  referralCode: string;
  referredBy?: string;
  birthday?: Date;
  examDate?: IExamDate;
  contract?: IUserContract;
  legalId?: string;
  country?: ECountry;
  notificationSettings: IUserNotificationSettings;
  statusInfo: IUserStatusInfo;
  paymentStatusInfo?: IUserPaymentStatusInfo;
  isUmyDisabled: boolean;
  hubspotId?: string;
}

export type IUserStatusInfo = {
  currentStatus: EUserStatus;
  logs: {
    status: EUserStatus;
    date: Date;
  }[];
};

export type IUserPaymentStatusInfo = {
  currentStatus: EStudentPaymentStatus;
  logs: IPaymentStatusLog[];
};

export type IPaymentStatusLog = {
  status: EStudentPaymentStatus;
  date: Date;
  modifiedBy: IUser;
};

export type IBasicUser = Pick<
  IUser,
  '_id' | 'name' | 'lastName' | 'email' | 'role' | 'avatar' | 'position'
>;

export interface IExamDate {
  month?: string;
  year?: string;
}

export interface IUserOnboarding {
  country?: string;
  career?: string;
  accessScore?: number | undefined;
  preferedTime: string;
  investTime: number;
  region?: string;
  optionalLessons?: string[];
  events: string;
  month?: number;
  year?: string;
  percentage?: number;
}

export interface IUserNotificationSettings {
  examGroupNotificationSettings: IExamGroupNotificationSettings;
}

export interface IExamGroupNotificationSettings {
  isActive: boolean;
  examGroupNotifications: IExamGroupNotification[];
}

export interface IExamGroupNotification {
  tag: string;
  autonomousCommunities: EAutonomousCommunity[];
}

export type IUserReport = IUser & {
  appTime: string;
  courseCompletion: number;
  lastLog: string;
  nLogs: number;
  nTickets: number;
  quizAvg: number;
  isChecked: boolean;
  certificationQuizResult: number;
};

export type IReportFilters = {
  tag?: string;
};

export type IUserStatsFilters = {
  tag?: string;
  date?: [Date, Date];
  paymentStatus?: EStudentPaymentStatus[];
  status?: EUserStatus[];
};

export type ITeacherFilters = {
  status?: EUserStatus;
};

export type ITeacherStudentsFilters = {
  courseId?: string;
};

export interface IUserContractSign {
  signUrl: string;
  signatureRequestId: string;
}

export interface IUserContract {
  signatureRequestId?: string;
  contractFile?: IFile;
}

export type ITeachersContentReport = IUser & {
  videoMinutes: number;
  pdfPages: number;
  questions: number;
};

export type ITeachersReportFilters = {
  tag?: string;
  date?: [Date, Date];
  vertical?: EVerticalNames;
};

export type ITutoringGroupStudentForSelector = Pick<IUser, '_id' | 'name' | 'lastName'> & {
  tutoringGroup: {
    _id?: ITutoringGroup['_id'];
    name?: ITutoringGroup['name'];
  };
};

export type IStudentStats = Pick<IUser, '_id' | 'name' | 'lastName' | 'email' | 'tags'> & {
  quizNumber: number;
  avgScore: number;
  ticketNumber: number;
  appTime: string;
  csat: string;
  paymentStatus: EStudentPaymentStatus;
};

export enum EStudentPaymentStatus {
  PAID = 'PAID',
  DOWNTURN = 'DOWNTURN',
  NON_PAYMENT = 'NON_PAYMENT',
  EXAMINED = 'EXAMINED',
  FORMER_STUDENT = 'FORMER_STUDENT'
}

export type IUpdateStudentPaymentStatus = Pick<IUser, '_id'> & {
  paymentStatus: EStudentPaymentStatus;
};

export interface IHubspotUser {
  _id: string;
  email: string;
  name: string;
  lastName: string;
}

export type IHubspotVisitorToken = {
  email: string;
  token: string;
};
