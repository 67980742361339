import { Tag, Card } from 'antd';
import styled from 'styled-components';

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const ListItemTitle = styled.span`
  font-size: ${({ theme }) => theme.fontSize.normal};
  font-weight: 700;
`;

export const ButtonWrapper = styled.div`
  text-align: right;
`;

export const TagItem = styled(Tag)`
  overflow: hidden;
  white-space: nowrap;
  width: 150px;
  text-overflow: ellipsis;
  vertical-align: middle;
`;

export const ResultCard = styled(Card)`
  margin-top: 20px;
`;

export const StatsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: space-evenly;
`;

export const TestRatingWrapper = styled.div`
  margin: 0 16px;
  display: flex;
  align-items: baseline;

  @media (max-width: ${({ theme }) => theme.mediaQueries.md}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const TestStatsWrapper = styled.div`
  display: grid;
  grid-template-columns: 30% 30% 60%;

  & > * {
    .anticon {
      margin-right: 4px;
    }
  }

  @media (max-width: ${({ theme }) => theme.mediaQueries.md}) {
    grid-template-columns: 1fr;
  }
`;

export const ResultTextWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 16px;
`;

export const ResultText = styled.span`
  color: ${({ theme }) => theme.colors.gunmetal};
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
`;

export const RatingText = styled.span`
  color: ${({ theme }) => theme.colors.gunmetal};
  font-family: Open Sans;
  font-size: 30px;
  font-weight: 600;
`;

export const IconWrapper = styled.div`
  margin-left: 16px;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  background-color: #ff4d4f1a;
  color: ${({ theme }) => theme.colors.error};
`;

export const RatingTextWrapper = styled.span`
  white-space: nowrap;
`;
