import styled from 'styled-components';

import TITLE_VECTOR from '@/assets/onboarding_student_info_vector.svg';

export const Wrapper = styled.div`
  display: flex;
`;

export const ImgWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  padding: 24px;
`;

export const FormWrapper = styled.div`
  width: 100%;
  .ant-picker {
    width: 100%;
  }
  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    width: 20vw;
  }
`;

export const StyledSpan = styled.span`
  background-image: url(${TITLE_VECTOR});
  background-size: auto;
  background-repeat: no-repeat;
  background-position: bottom;
  padding-bottom: 20px;
`;
