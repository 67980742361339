import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Icon } from '@/components/Icon';

export const CreateLiveClass: React.FC = () => {
  const navigateTo = useNavigate();
  const { t } = useTranslation('CommonDashboardTranslations');

  const onClick = () => navigateTo(`/calendar?create=true`);

  return (
    <Button size="middle" onClick={onClick} icon={<Icon i="CalendarAddAlt" />}>
      {t('CREATE_LIVE_CLASS')}
    </Button>
  );
};
