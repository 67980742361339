import styled, { css } from 'styled-components';

interface Props {
  selected?: boolean;
}

export const Wrapper = styled.button<Props>`
  border: 2px solid ${({ theme }) => theme.colors.beau};
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 30%;
  text-align: center;
  background: transparent;
  cursor: pointer;
  width: -webkit-fill-available;

  &:hover {
    background-color: rgb(242 242 242);
  }
  ${props =>
    props.selected
      ? css`
          background-color: rgb(242 242 242);
          border: 2px solid ${({ theme }) => theme.colors.meadreg};
        `
      : ''}

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    padding: 30px 30px;
  }
`;

export const Text = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-size: ${({ theme }) => theme.fontSize.normal};
  padding: 8px 0;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    margin-top: 10px;
  }
`;
