import { IDate } from '@sdk/contracts';
import { dateProvider } from '@utils';
import { ReactNode } from 'react';

import { DateWrapper, TitleWrapper, EventWrapper } from './EventItem.styles';

import { Icon } from '@/components/Icon';
import { getTimezone } from '@/utils';

interface Props {
  title: ReactNode;
  startDate: IDate;
  endDate: IDate;
  onClick: () => void;
}

export const EventItem: React.FC<Props> = ({ title, startDate, endDate, onClick }) => {
  const utcStartDate = dateProvider.zonedTimeToUtc(startDate);
  const utcEndDate = dateProvider.zonedTimeToUtc(endDate);

  return (
    <EventWrapper>
      <DateWrapper>
        <div>{dateProvider.format(utcStartDate, 'dd')}</div>
        <div>{dateProvider.format(utcEndDate, 'MMM')}</div>
      </DateWrapper>
      <TitleWrapper onClick={onClick}>
        {title}
        <div>
          <div>
            <Icon i="Time" />
            <span>
              {`${dateProvider.format(utcStartDate, 'HH:mm')} - ${dateProvider.format(
                utcEndDate,
                'HH:mm'
              )}`}
            </span>
          </div>
          <div>{getTimezone()}</div>
        </div>
      </TitleWrapper>
    </EventWrapper>
  );
};
