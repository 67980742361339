import { Form, Button, message } from 'antd';
import { isNumber } from 'lodash';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReviewedQuestion } from '../ReviewedQuestion/ReviewedQuestion';
import { UnReviewedQuestion } from '../UnReviewedQuestion/UnReviewedQuestion';

import {
  QuestionWrapper,
  FormulaWrapper,
  ImageWrapper,
  ImageLink,
  Image,
  NavigationButtons,
  QuestionCount,
  QuestionText,
  AnswerTitle,
  FormWrapper
} from './QuestionsReviewer.styles';

import 'swiper/css';
import 'swiper/css/virtual';
import { NavigationButton } from '@/components/Quiz';
import { useMutateReviewQuestions, useReviewQuestionsStore } from '@/store';

export const QuestionsReviewer = memo(() => {
  const { questionsToReview = [], updateQuestion } = useReviewQuestionsStore();
  const { t } = useTranslation('StudentQuizTranslations');
  const [questionIndex, setQuestionIndex] = useState(0);
  const { submitAnswer } = useMutateReviewQuestions();

  const [form] = Form.useForm<{ responses?: Record<string, number | undefined> }>();
  const responses = Form.useWatch(['responses'], form);

  const prevQuestion = () => {
    setQuestionIndex(questionIndex - 1);
  };

  const nextQuestion = () => {
    setQuestionIndex(questionIndex + 1);
  };

  const handleAnswer = () => {
    const question = questionsToReview[questionIndex];
    const givenAnswer = responses?.[question._id];
    if (!isNumber(givenAnswer)) {
      message.warning(t('SELECT_ANSWER'));
      return;
    }

    submitAnswer(
      { questionId: question._id, givenAnswer },
      { onSuccess: data => updateQuestion(data) }
    );
  };

  if (questionsToReview.length === 0) return null;

  const currentQuestionToReview = questionsToReview[questionIndex];
  const canSendAnswer = isNumber(responses?.[currentQuestionToReview._id]);
  const { _id, question, isReviewed } = currentQuestionToReview;
  const { image, answers } = question;

  const sliced = questionsToReview.slice(questionIndex, questionIndex + 1);

  return (
    <>
      <FormWrapper preserve form={form} id="question-form" onFinish={handleAnswer}>
        {sliced.map(questionData => (
          <QuestionWrapper
            key={_id}
            style={{ overflow: 'hidden' }}
            id={`question-to-review-${_id}`}>
            <QuestionCount>
              {questionIndex + 1} / {questionsToReview.length}
            </QuestionCount>
            <div>
              <QuestionText>
                {questionData?.question?.title}
                {questionData?.question?.formula && (
                  <FormulaWrapper
                    dangerouslySetInnerHTML={{ __html: questionData?.question?.formula }}
                  />
                )}
                {image && (
                  <ImageWrapper>
                    <ImageLink href={image.url} target="_blank">
                      <Image src={image.url} alt={image.name} />
                    </ImageLink>
                  </ImageWrapper>
                )}
              </QuestionText>
            </div>
            <AnswerTitle>{t('ANSWERS')}</AnswerTitle>
            {isReviewed ? (
              <ReviewedQuestion
                form={form}
                answers={answers}
                currentQuestionToReview={currentQuestionToReview}
              />
            ) : (
              <UnReviewedQuestion questionData={questionData} />
            )}
          </QuestionWrapper>
        ))}
      </FormWrapper>
      <NavigationButtons>
        <NavigationButton direction="prev" onClick={prevQuestion} disabled={questionIndex === 0} />
        <Button
          type="primary"
          htmlType="submit"
          form="question-form"
          disabled={isReviewed || !canSendAnswer}>
          {t('CORRECT_AND_SAVE')}
        </Button>
        <NavigationButton
          direction="next"
          onClick={nextQuestion}
          disabled={questionIndex === questionsToReview.length - 1}
        />
      </NavigationButtons>
    </>
  );
});
