import { lazy } from 'react';

import { IRoute } from '@/types';

const CoursePage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.CoursePage }))
);

const CoursesPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.CoursesPage }))
);

const DashboardPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.DashboardPage }))
);

const CourseTagsPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.CourseTagsPage }))
);

const EventsPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.EventsPage }))
);

const EventPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.EventPage }))
);

const TicketsPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.TicketsPage }))
);

const TeachersPage = lazy(() =>
  import('@/pages/Manager').then(module => ({ default: module.TeachersPage }))
);

const StudentsPage = lazy(() =>
  import('@/pages/Manager').then(module => ({ default: module.StudentsPage }))
);

const MyAcademyPage = lazy(() =>
  import('@/pages/Manager').then(module => ({ default: module.MyAcademyPage }))
);

const QuizzesPage = lazy(() =>
  import('@/pages/Manager').then(module => ({ default: module.QuizzesPage }))
);

const QuizFormPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.QuizFormPage }))
);

const TutoringGroupsPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.TutoringGroupsPage }))
);

const TutoringGroupPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.TutoringGroupPage }))
);

const CoursesStatsPage = lazy(() =>
  import('@/pages/Manager').then(module => ({ default: module.CoursesStatsPage }))
);

const SectionReviewsStatsPage = lazy(() =>
  import('@/pages/Manager').then(module => ({ default: module.SectionReviewsStatsPage }))
);

const ActivityStatsPage = lazy(() =>
  import('@/pages/Manager').then(module => ({ default: module.ActivityStatsPage }))
);

const ContentTotalsPage = lazy(() =>
  import('@/pages/Manager').then(module => ({ default: module.ContentTotalsPage }))
);

const ProblemReportsPage = lazy(() =>
  import('@/pages/Manager').then(module => ({ default: module.ProblemReportsPage }))
);

const ExamGroupsPage = lazy(() =>
  import('@/pages/Manager').then(module => ({ default: module.ExamGroupsPage }))
);

const ExamGroupDetailPage = lazy(() =>
  import('@/pages/Manager').then(module => ({ default: module.ExamGroupDetail }))
);

const ScrappedExamGroupsPage = lazy(() =>
  import('@/pages/Manager').then(module => ({ default: module.ScrappedExamGroupsPage }))
);

const InternalUsersPage = lazy(() =>
  import('@/pages/Manager').then(module => ({ default: module.InternalUsersPage }))
);

const IndexationLandingsPage = lazy(() =>
  import('@/pages/Manager').then(module => ({ default: module.IndexationLandingsPage }))
);

const ChatPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.ChatPage }))
);

const PostPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.PostPage }))
);

export const MANAGER: IRoute[] = [
  {
    title: 'DASHBOARD',
    path: '/dashboard',
    icon: 'Home',
    Component: DashboardPage,
    showInMenu: true
  },
  {
    title: 'MY_ACADEMY',
    path: '/academy',
    icon: 'Education',
    Component: MyAcademyPage,
    showInMenu: true,
    subroutes: [
      {
        title: 'STUDENTS',
        path: 'students',
        icon: 'Undefined',
        Component: StudentsPage,
        showInMenu: true
      },
      {
        title: 'TEACHERS',
        path: 'teachers',
        icon: 'Undefined',
        Component: TeachersPage,
        showInMenu: true
      },
      {
        title: 'INTERNAL_USERS',
        path: 'internal-users',
        icon: 'Undefined',
        Component: InternalUsersPage,
        showInMenu: true
      }
    ]
  },
  {
    title: 'COURSES',
    path: '/courses',
    icon: 'Notebook',
    Component: CoursesPage,
    showInMenu: true,
    subroutes: [
      {
        title: 'COURSES',
        path: ':_id',
        icon: 'Undefined',
        Component: CoursePage,
        showInMenu: false
      },
      {
        title: 'ALL_COURSES',
        path: '',
        icon: 'Undefined',
        Component: CoursePage,
        showInMenu: true
      },
      {
        title: 'INDEXATION_LANDINGS',
        path: 'indexation-landings',
        icon: 'Undefined',
        Component: IndexationLandingsPage,
        showInMenu: true
      },
      {
        title: 'CATEGORIES',
        path: 'settings',
        icon: 'Undefined',
        Component: CourseTagsPage,
        showInMenu: true
      }
    ]
  },
  {
    title: 'CONTENT',
    path: '/content',
    icon: 'Undefined',
    Component: ContentTotalsPage,
    showInMenu: true,
    subroutes: [
      {
        title: 'TOTALS',
        path: '',
        icon: 'Undefined',
        Component: ContentTotalsPage,
        showInMenu: true
      },
      {
        title: 'PROBLEM_REPORTS',
        path: 'problem-reports',
        icon: 'Undefined',
        Component: ProblemReportsPage,
        showInMenu: true
      }
    ]
  },
  {
    title: 'EXAM_GROUPS',
    path: '/exam-groups',
    icon: 'Star',
    Component: ExamGroupsPage,
    showInMenu: true,
    subroutes: [
      {
        title: 'EXAM_GROUPS',
        path: ':_id',
        icon: 'Undefined',
        Component: ExamGroupDetailPage,
        showInMenu: false
      },
      {
        title: 'SCRAPPED_EXAM_GROUPS',
        path: 'scrapped',
        icon: 'Undefined',
        Component: ScrappedExamGroupsPage,
        showInMenu: false
      }
    ]
  },
  {
    title: 'TESTS',
    path: '/tests',
    icon: 'TestTool',
    Component: QuizzesPage,
    showInMenu: true,
    subroutes: [
      {
        title: 'TESTS',
        path: ':_id/edit',
        icon: 'Undefined',
        Component: QuizFormPage,
        showInMenu: false
      },
      {
        title: 'CREATE_TEST',
        path: 'create',
        icon: 'Undefined',
        Component: QuizFormPage,
        showInMenu: false
      },
      {
        title: 'TESTS',
        path: 'import',
        icon: 'Undefined',
        Component: QuizFormPage,
        showInMenu: false
      },
      {
        title: 'ALL_TEST',
        path: '',
        icon: 'Undefined',
        Component: QuizzesPage,
        showInMenu: false
      }
    ]
  },
  {
    title: 'DOUBTS',
    path: '/tickets',
    icon: 'MailAll',
    Component: TicketsPage,
    showInMenu: true
  },
  {
    title: 'LIVE_EVENTS',
    path: '/calendar',
    icon: 'EventSchedule',
    Component: EventsPage,
    showInMenu: true,
    subroutes: [
      {
        title: 'LIVE_EVENTS',
        path: ':_id',
        icon: 'Undefined',
        Component: EventPage,
        showInMenu: false
      }
    ]
  },
  {
    title: 'STATS',
    path: '/stats',
    icon: 'ChartClusterBar',
    Component: CoursesStatsPage,
    showInMenu: true,
    subroutes: [
      {
        title: 'COURSES_STATS',
        path: 'by-course',
        icon: 'Undefined',
        Component: CoursesStatsPage,
        showInMenu: true
      },
      {
        title: 'SECTION_REVIEWS',
        path: 'section-reviews',
        icon: 'Undefined',
        Component: SectionReviewsStatsPage,
        showInMenu: true
      },
      {
        title: 'ACTIVITY_STATS',
        path: ':_id',
        icon: 'Undefined',
        Component: ActivityStatsPage,
        showInMenu: false
      }
    ]
  },
  {
    title: 'TUTORING_GROUPS',
    path: '/tutoring-groups',
    icon: 'UserMultiple',
    Component: TutoringGroupsPage,
    showInMenu: true,
    subroutes: [
      {
        title: 'TUTORING_GROUPS',
        path: ':tutoringGroupId',
        icon: 'Undefined',
        Component: TutoringGroupPage,
        showInMenu: false
      },
      {
        title: 'TUTORING_GROUPS',
        path: ':tutoringGroupId/chat/:tutorId/:studentId',
        icon: 'Undefined',
        Component: ChatPage,
        showInMenu: false
      }
    ]
  },
  {
    title: 'POST',
    path: '/post/:slug',
    icon: 'Undefined',
    Component: PostPage,
    showInMenu: false
  }
];
