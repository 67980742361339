import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  gap: 30px;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    flex-direction: row;
  }

  .ant-form-item {
    align-self: flex-end;
  }
`;

export const SelectorWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UnknownWrapper = styled.div`
  align-self: flex-start;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    align-self: flex-end;
  }
`;
