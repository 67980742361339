import { IUser } from '@sdk/contracts';
import { useMutation } from '@tanstack/react-query';
import { create } from 'zustand';

import { sdk } from '@/services';

const UserService = sdk.getService('UserService');
interface IStore {
  signatureReqId?: string;
  setSignatureReqId: (signatureReqId: string) => void;
}

const store = create<IStore>(set => ({
  setSignatureReqId: (signatureReqId: string) => set({ signatureReqId })
}));

export const useMutateHelloSignContract = () => {
  const { setSignatureReqId, signatureReqId } = store();

  const { mutate: generateContractSignUrl, isPending: isGenerating } = useMutation({
    mutationFn: (user: Partial<IUser>) => UserService.generateUserContract(user)
  });

  return {
    isWorking: isGenerating,
    setSignatureReqId,
    signatureReqId,
    generateContractSignUrl
  };
};
