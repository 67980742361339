export const StudentProfileTranslations = {
  PROFILE: {
    es: 'Mi perfil',
    en: 'My profile',
    cat: 'El meu perfil',
    eus: 'Nire profila'
  },
  EDIT_PROFILE: {
    es: 'Editar perfil',
    en: 'Edit profile',
    cat: 'Editar perfil',
    eus: 'Editatu profila'
  },
  SUMMARY: {
    es: 'Resumen',
    en: 'Summary',
    cat: 'Resum',
    eus: 'Laburpena'
  },
  CERTIFICATES: {
    es: 'Mis certificados',
    en: 'My certificates',
    cat: 'Els meus certificats',
    eus: 'Nire egiaztagiriak'
  },
  PERSONAL_INFO: {
    es: 'Datos personales',
    en: 'Personal info',
    cat: 'Dades personals',
    eus: 'Datu pertsonalak'
  },
  EMAIL: {
    es: 'Correo electrónico',
    en: 'Email',
    cat: 'Correu electrònic',
    eus: 'Posta elektronikoa'
  },
  LOCATION: {
    es: '¿Dónde vives?',
    en: 'Where do you live?',
    cat: 'On vius?',
    eus: 'Non bizi zara?'
  },
  SELECT_CA: {
    es: 'Selecciona tu comunidad autónoma',
    en: 'Select your region',
    cat: 'Selecciona la teva comunitat autònoma',
    eus: 'Hautatu zure autonomia erkidegoa'
  },
  STUDY_HABITS: {
    es: 'Sobre tus hábitos de estudio:',
    en: 'About your study habits:',
    cat: 'Sobre els teus hàbits d`estudi:',
    eus: 'Ikasketa ohiturei buruz:'
  },
  OBJECTIVES: {
    es: 'Tus objetivos:',
    en: 'Your objectives:',
    cat: 'Els teus objectius:',
    eus: 'Zure helburuak:'
  },
  EXPECTED_DATE: {
    es: '¿Cuándo tienes pensado presentarte?',
    en: 'When do you plan to apply?',
    cat: 'Quan tens pensat presentar-te?',
    eus: 'Noiz aurkeztuko zara?'
  },
  JANUARY: {
    es: 'Enero',
    en: 'January',
    cat: 'Gener',
    eus: 'Urtarrila'
  },
  FEBRUARY: {
    es: 'Febrero',
    en: 'February',
    cat: 'Febrer',
    eus: 'Otsaila'
  },
  MARCH: {
    es: 'Marzo',
    en: 'March',
    cat: 'Març',
    eus: 'Martxoa'
  },
  APRIL: {
    es: 'Abril',
    en: 'April',
    cat: 'Abril',
    eus: 'Apirila'
  },
  MAY: {
    es: 'Mayo',
    en: 'May',
    cat: 'Maig',
    eus: 'Maiatza'
  },
  JUNE: {
    es: 'Junio',
    en: 'June',
    cat: 'Juny',
    eus: 'Ekaina'
  },
  JULY: {
    es: 'Julio',
    en: 'July',
    cat: 'Juliol',
    eus: 'Uztaila'
  },
  AUGUST: {
    es: 'Agosto',
    en: 'August',
    cat: 'Agost',
    eus: 'Abuztua'
  },
  SEPTEMBER: {
    es: 'Septiembre',
    en: 'September',
    cat: 'Setembre',
    eus: 'Iraila'
  },
  OCTOBER: {
    es: 'Octubre',
    en: 'October',
    cat: 'Octubre',
    eus: 'Urria'
  },
  NOVEMBER: {
    es: 'Noviembre',
    en: 'November',
    cat: 'Novembre',
    eus: 'Azaroa'
  },
  DECEMBER: {
    es: 'Diciembre',
    en: 'December',
    cat: 'Desembre',
    eus: 'Abendua'
  },
  UNKNOWN_SCORE: {
    es: 'No lo sé aún',
    en: "I don't know yet",
    cat: 'Encara no ho sé',
    eus: 'Oraindik ez dakit'
  },
  GOAL_OBJECTIVE: {
    es: '¿Cuál es tu objetivo de aciertos en los test (en porcentaje)?',
    en: 'What is your target test score (as a percentage)?',
    cat: 'Quin és el teu objectiu d`encerts en els test (en percentatge)?',
    eus: 'Zein ehuneko duzu helburu test-ekiko (ehunekoa)?'
  },
  EVENTS_TITLE: {
    es: '¡Queremos acompañarte!',
    en: 'We want to be there for you!',
    cat: 'Volem acompanyar-te!',
    eus: 'Zurekin egon nahi dugu!'
  },
  CONTRACT: {
    es: 'Contrato',
    en: 'Contract',
    cat: 'Contracte',
    eus: 'Kontratua'
  },
  CONTRACT_DETAIL: {
    es: 'Accede desde aquí a las condiciones del servicio',
    en: 'Click here to access the terms of service.',
    cat: 'Accedeix des d`aquí a les condicions del servei',
    eus: 'Sartu hemen zerbitzuaren baldintzak'
  },
  VIEW_CONTRACT: {
    es: 'Ver contrato',
    en: 'View contract',
    cat: 'Veure contracte',
    eus: 'Ikusi kontratua'
  },
  CLOSE: {
    es: 'Cerrar',
    en: 'Close',
    cat: 'Tancar',
    eus: 'Itxi'
  },
  PROFILE_UPDATED_SUCCESSFULLY: {
    es: '¡Perfil actualizado con éxito!',
    en: 'Profile updated successfully!',
    cat: 'Perfil actualitzat amb èxit!',
    eus: 'Profila ongi eguneratua!'
  },
  LANGUAGE: {
    es: 'Idioma',
    en: 'Language',
    cat: 'Idioma',
    eus: 'Hizkuntza'
  },
  COURSES: {
    es: 'Cursos',
    en: 'Courses',
    cat: 'Cursos',
    eus: 'Ikastaroak'
  },
  COURSES_SELECT: {
    es: 'Elige los cursos que quieras que se vean en la página de inicio',
    en: 'Choose the courses you want to be displayed on the home page',
    cat: 'Tria els cursos que vulguis que es vegin a la pàgina d`inici',
    eus: 'Aukeratu hasiera orrian ikusi nahi dituzun ikastaroak'
  },
  EXAM_GROUPS: {
    es: 'Convocatorias',
    en: 'Exam groups',
    cat: 'Convocatòries',
    eus: 'Deialdiak'
  },
  EXAM_GROUPS_FINDER: {
    es: 'Buscador de convocatorias',
    en: 'Exam groups finder',
    cat: 'Cercador de convocatòries',
    eus: 'Deialdien bilatzailea'
  },
  TAG: {
    es: 'Formación',
    en: 'Tag',
    cat: 'Formació',
    eus: 'Formakuntza'
  },
  AUTONOMOUS_COMMUNITY: {
    es: 'Comunidad',
    en: 'Autonomous community',
    cat: 'Comunitat',
    eus: 'Autonomia erkidegoa'
  },
  PROVINCE: {
    es: 'Provincia',
    en: 'Province',
    cat: 'Província',
    eus: 'Probintzia'
  },
  TITLE: {
    es: 'Título',
    en: 'Title',
    cat: 'Títol',
    eus: 'Titulua'
  },
  TYPE: {
    es: 'Tipo',
    en: 'Type',
    cat: 'Tipus',
    eus: 'Mota'
  },
  PUBLICATION_DATE: {
    es: 'Fecha de publicación',
    en: 'Publication date',
    cat: 'Data de publicació',
    eus: 'Argitaratze data'
  },
  STUDENTS: {
    es: 'Alumnos',
    en: 'Students',
    cat: 'Alumnes',
    eus: 'Ikasleak'
  },
  ADD: {
    es: 'Añadir',
    en: 'Add',
    cat: 'Afegir',
    eus: 'Gehitu'
  },
  EXAM_GROUPS_EMPTY: {
    es: 'Aquí aparecerán las convocatorias a las que te apuntes. Todavía no has añadido ninguna convocatoria',
    en: 'Here you will see the exam groups you sign up for. You have not added any exam group yet',
    cat: 'Aquí apareixeran les convocatòries a les que t`apuntis. Encara no has afegit cap convocatòria',
    eus: 'Hemen agertuko dira deialdira apuntatzen zara. Oraindik ez duzu deialdirik gehitu'
  },
  LEAVE_EXAM_GROUP: {
    es: 'Abandonar convocatoria',
    en: 'Leave exam group',
    cat: 'Abandonar convocatòria',
    eus: 'Utzi deialdia'
  },
  ACTIONS: {
    es: 'Acciones',
    en: 'Actions',
    cat: 'Accions',
    eus: 'Ekintzak'
  },
  AT_LEAST_ONE_COURSE_MUST_BE_SELECTED: {
    es: 'Debes seleccionar al menos un curso',
    en: 'You must select at least one course',
    cat: 'Has de seleccionar almenys un curs',
    eus: 'Gutxienez ikastaro bat aukeratu behar duzu'
  },
  EXAM_GROUPS_TOTAL_COUNT: {
    es: '{{total}} convocatorias',
    en: '{{total}} exam groups',
    cat: '{{total}} convocatòries',
    eus: '{{total}} convocatorias'
  },
  LEAVE_GROUP_CONFIRMATION: {
    es: '¿Estás seguro de que quieres abandonar la convocatoria?',
    en: 'Are you sure you want to leave the exam group?',
    cat: 'Estàs segur que vols abandonar la convocatòria?',
    eus: 'Ziur zaude deialdia utzi nahi duzula?'
  },
  NOTIFICATION_SETTINGS: {
    es: 'Notificaciones',
    en: 'Notifications',
    cat: 'Notificacions',
    eus: 'Jakinarazpenak'
  },
  LATER: {
    es: 'Más adelante',
    en: 'Later',
    cat: 'Més endavant',
    eus: 'Geroago'
  },
  EXAM_GROUPS_NOTIFICATIONS_TOOLTIP: {
    es: 'Configura sobre qué Comunidades Autónomas quieres recibir notificaciones de Convocatorias',
    en: 'Configure which Autonomous Communities you want to receive notifications from',
    cat: 'Configura sobre quines Comunitats Autònomes vols rebre notificacions de Convocatòries',
    eus: 'Konfiguratu zein Autonomia Erkidegotan nahi duzun jakinarazpenak jaso'
  },
  EXAM_GROUPS_NOTIFICATIONS_NAME: {
    es: 'Elige sobre qué Comunidades Autónomas quieres ser notificado',
    en: 'Choose which Autonomous Communities you want to be notified about',
    cat: 'Tria sobre quines Comunitats Autònomes vols ser notificat',
    eus: 'Hautatu zein Autonomia Erkidegotan nahi duzun jakinarazpenak jaso'
  },
  TOTAL_PROGRESS: {
    es: 'Avance total',
    en: 'Total progress',
    cat: 'Progrés total',
    eus: 'Aurrerapen guztira'
  },
  AVERAGE_SCORE: {
    es: 'Nota media',
    en: 'Average score',
    cat: 'Nota mitjana',
    eus: 'Batazbesteko nota'
  },
  TOTAL_POINTS: {
    es: 'Puntuación total',
    en: 'Total points',
    cat: 'Puntuació total',
    eus: 'Puntuazio guztira'
  },
  BADGES: {
    es: 'Medallas',
    en: 'Badges',
    cat: 'Medalles',
    eus: 'Medalak'
  },
  DAILY_STREAK: {
    es: 'Racha diaria',
    en: 'Daily streak',
    cat: 'Ratxa diària',
    eus: 'Eguneroko erronka'
  },
  STREAK_DAYS: {
    es: '{{days}} días',
    en: '{{days}} days',
    cat: '{{days}} dies',
    eus: '{{days}} egun'
  },
  DAILY_COMPLETED_TASKS: {
    es: 'Tareas diarias completadas',
    en: 'Daily completed tasks',
    cat: 'Tasques diàries completades',
    eus: 'Eguneroko zereginak osatuak'
  },
  GAMIFICATION_INFO: {
    es: 'Consigue puntos de conocimiento para tener un extra en el ranking. Para saber cómo conseguir más, puedes preguntar por el chat.',
    en: 'Get knowledge points to have an extra in the ranking. To know how to get more, you can ask for the chat.',
    cat: 'Aconsegueix punts de coneixement per tenir un extra en el ranking. Per saber com aconseguir-ne més, pots preguntar pel xat.',
    eus: 'Lortu ezagutza puntuak rankingean gehigarri bat izateko. Gehiago lortzeko, txat bidez galdetu dezakezu.'
  },
  REFERRAL_PROMO_TITLE: {
    es: 'Gana 600€',
    en: 'Win 600€',
    cat: 'Guanya 600€',
    eus: '600€ irabazi'
  },
  REFERRAL_PROMO_SUBTITLE: {
    es: 'Invita a 4 amigos',
    en: 'Invite 4 friends',
    cat: 'Convida 4 amics',
    eus: 'Gonbidatu 4 lagun'
  },
  REFERRAL_PROMO_DESCRIPTION: {
    es: 'Gana {{amount}}€ por cada amigo que se venga a Ucademy',
    en: 'Win {{amount}}€ for each friend who comes to Ucademy',
    cat: 'Guanya {{amount}}€ per cada amic que vingui a Ucademy',
    eus: '{{amount}}€ irabazi lagun bakoitzeko Ucademyra etortzen denean'
  },
  REFERRAL_PROMO_BUTTON: {
    es: 'Invitar a un amigo',
    en: 'Invite a friend',
    cat: 'Convida un amic',
    eus: 'Gonbidatu lagun bat'
  },
  RANKING: {
    es: 'Ranking',
    en: 'Ranking',
    cat: 'Ranking',
    eus: 'Ranking'
  },
  GO_TO_RANKING: {
    es: 'Ir a los rankings',
    en: 'Go to rankings',
    cat: 'Anar als rankings',
    eus: 'Joan ranking-era'
  },
  NOT_JOINED_ANY_EXAM_GROUP: {
    es: '¡Ups!, todavía no te has unido a ninguna convocatoria',
    en: 'Oops!, you have not joined any exam group yet',
    cat: "Ups!, encara no t'has unit a cap convocatòria",
    eus: 'Ups!, oraindik ez zara deialdirik batera'
  },
  STATE: {
    es: 'Estatal',
    en: 'State',
    cat: 'Estat',
    eus: 'Estatu'
  },
  MY_EXAM_GROUPS: {
    es: 'Mis convocatorias',
    en: 'My exam groups',
    cat: 'Les meves convocatòries',
    eus: 'Nire deialdiak'
  },
  HOW_DO_EXAM_GROUPS_WORK: {
    es: '¿Cómo funcionan las convocatorias?',
    en: 'How do exam groups work?',
    cat: 'Com funcionen les convocatòries?',
    eus: 'Nola funtzionatzen dute deialdiak?'
  },
  EXAM_GROUPS_EXPLANATION_1: {
    es: 'En Ucademy publicamos todas las convocatorias oficiales del BOE en la plataforma todos los días. Cuando el gobierno autonómico, provincial o estatal pública una convocatoria, no salen siempre todos los datos al principio. Algunos se actualizan más adelante. Cuando el gobierno correspondiente los actualiza, nosotros también.',
    en: 'At Ucademy we publish all the official BOE calls on the platform every day. When the regional, provincial or state government publishes a call, not all the data comes out at the beginning. Some are updated later. When the corresponding government updates them, we do too.',
    cat: 'A Ucademy publiquem totes les convocatòries oficials del BOE a la plataforma cada dia. Quan el govern autonòmic, provincial o estatal publica una convocatòria, no surten sempre totes les dades al principi. Algunes s`actualitzen més endavant. Quan el govern corresponent les actualitza, nosaltres també.',
    eus: 'Ucademyk egunero BOEko deialdi ofizial guztiak argitaratzen ditu plataforma. Autonomia, probintzia edo estatuko gobernuak deialdia argitaratzen duenean, ez dira beti datu guztiak agertzen hasieran. Batzuk geroago eguneratzen dira. Gobernu horrek eguneratzen dituenean, guk ere eguneratzen dugu.'
  },
  EXAM_GROUPS_EXPLANATION_2: {
    es: 'Así que no te preocupes si hay algún dato en el que pone “No disponible”. Lo actualizaremos en cuanto se publique, y si estás apuntado a la convocatoria, te lo notificaremos.',
    en: "So don't worry if there is any data that says 'Not available'. We will update it as soon as it is published, and if you are signed up for the call, we will notify you.",
    cat: "Així que no et preocupis si hi ha alguna dada que posa 'No disponible'. L'actualitzarem en quant es publiqui, i si estàs apuntat a la convocatòria, t'ho notificarem.",
    eus: 'Beraz, ez kezkatu "Ez dagoela" esaten duen edozein datu. Eguneratuko dugu argitaratzen denean, eta deialdian apuntatuta badagozu, jakinaraziko dizugu.'
  },
  EXAM_GROUPS_EXPLANATION_3: {
    es: 'Lo mismo con las convocatorias disponibles: Si no la encuentras o ves vacío el listado, es porque no ha salido.',
    en: 'The same with the available calls: If you do not find it or see the list empty, it is because it has not come out.',
    cat: 'El mateix amb les convocatòries disponibles: Si no la trobes o veus buit el llistat, és perquè no ha sortit.',
    eus: 'Berdin deialdiak eskuragarriak direnean: Ez baduzu aurkitzen edo zerrenda hutsa ikusten baduzu, ez da agertu.'
  },
  JOINED_ON: {
    es: 'Se unió en {{month}} de {{year}}',
    en: 'Joined on {{month}} of {{year}}',
    cat: 'Es va unir en {{month}} de {{year}}',
    eus: '{{year}}ko {{month}}an batu zen'
  }
};
