import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 90%;
  padding-bottom: 30px;
  overflow: auto;
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;
