import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  height: 80%;
  height: 200px;

  .ant-divider-horizontal {
    margin: 0;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  cursor: pointer;
`;

export const DateWrapper = styled.div`
  width: 46px;
  height: 46px;
  flex-shrink: 0;
  border-radius: 7px;
  background: ${({ theme }) => theme.colors.solitude};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DateDayWrapper = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.2px;
`;

export const DateMonthWrapper = styled.div`
  font-weight: 600;
  font-size: 12px;
  font-style: normal;
  line-height: 20px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`;

export const TimeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  color: ${({ theme }) => theme.colors.marrow};
`;

export const EmptyWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  color: ${({ theme }) => theme.colors.marrow};
`;

export const EventTitleWrapper = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  text-overflow: ellipsis;
`;
