import { DefaultTheme } from 'styled-components';

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: {
      meadreg: string;
      meadlight: string;
      meadghost: string;
      meadwhite: string;
      meadstrong: string;
      meadberseker: string;

      neoreg: string;
      neolight: string;
      neoghost: string;
      neostrong: string;
      neowhite: string;
      neonblue: string;

      gunmetal: string;
      charcoal: string;
      marrow: string;
      cadet: string;
      beau: string;
      solitude: string;
      ghost: string;
      white: string;

      success: string;
      error: string;
      warning: string;
      link: string;

      sandstorm: string;
      koi: string;
      sail: string;
      piglet: string;
      royal: string;
      belair: string;

      coral: string;
    };
    fontSize: {
      small: string;
      normal: string;
      medium: string;
      large: string;
      extraLarge: string;
    };
    fontFamily: {
      primary: string;
      secondary: string;
    };
    boxShadow: {
      primary: string;
    };
    mediaQueries: {
      xs: string;
      sm: string;
      md: string;
      lg: string;
      xl: string;
    };
  }
}

export const defaultTheme: DefaultTheme = {
  colors: {
    meadreg: '#0ABB87',
    meadlight: '#53CFAB',
    meadghost: '#C6E9DF',
    meadwhite: '#E7F9F4',
    meadstrong: '#08966C',
    meadberseker: '#096B4E',

    neoreg: '#616EEB',
    neolight: '#9099F1',
    neoghost: '#E5E8FF',
    neowhite: '#EFF0FD',
    neostrong: '#4E58BC',
    neonblue: '#616EEBCC',

    gunmetal: '#262D34',
    charcoal: '#676C71',
    marrow: '#979A9E',
    cadet: '#CBCCCE',
    beau: '#E4E5E6',
    solitude: '#F1F1F2',
    ghost: '#F8F8F8',
    white: '#FFFFFF',

    success: '#52C41A',
    error: '#FF4D4F',
    warning: '#FEB816',
    link: '#1890FF',

    sandstorm: '#EAD339',
    koi: '#EAA44B',
    sail: '#63A7F7',
    piglet: '#EE9D9D',
    royal: '#852C3E',
    belair: '#7F99BF',

    coral: '#E3605833'
  },
  fontFamily: {
    primary: 'Open Sans',
    secondary: 'Poppins'
  },
  fontSize: {
    small: '10px',
    normal: '14px',
    medium: '18px',
    large: '20px',
    extraLarge: '28px'
  },
  boxShadow: {
    primary: '0px 0px 4px rgb(0 0 0 / 20%)'
  },
  mediaQueries: {
    xs: '576px',
    sm: '768px',
    md: '1024px',
    lg: '1200px',
    xl: '1400px'
  }
};

export const darkTheme: DefaultTheme = {
  ...defaultTheme
};
