import { AnalyticsTrackEvents, EActivityType } from '@constants';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import {
  ActivityDescriptionWrapper,
  ActivityNameWrapper,
  AddonTextWrapper,
  AddonWrapper,
  ColumnWrapper,
  InfoWrapper,
  LoadingWrapper,
  NextClassWrapper,
  StyledIcon,
  StyledImage,
  Wrapper
} from './StudentContentWidget.styles';

import UMY_DASHBOARD from '@/assets/umy_dashboard.svg';
import { EIconType, Icon } from '@/components/Icon';
import { LoadingCircleSpinner } from '@/components/Loaders';
import { WidgetBase } from '@/components/WidgetBase';
import { useIsMobile, useSegment } from '@/hooks';
import { useActivityTrack, useMySubscriptions, useProfile } from '@/store';
import { WidgetProps } from '@/types';
import { formatDuration } from '@/utils';

type Props = WidgetProps;

export const StudentContentWidget: React.FC<Props> = props => {
  const { t } = useTranslation('CommonDashboardTranslations');
  const { selectedSubscription, isLoading } = useMySubscriptions();
  const { getCurrentActivity } = useActivityTrack();
  const navigateTo = useNavigate();
  const { fireEvent } = useSegment();
  const isMobile = useIsMobile('xs');
  const { colors } = useTheme();
  const { profile } = useProfile();

  if (!selectedSubscription)
    return (
      <WidgetBase title={t('CLASSES_AND_SYLLABUS')} {...props}>
        <LoadingWrapper>
          <LoadingCircleSpinner />
        </LoadingWrapper>
      </WidgetBase>
    );

  const { activity, section } = getCurrentActivity(selectedSubscription);

  const {
    _id,
    course: { name }
  } = selectedSubscription;

  const getActivityIcon: () => EIconType = () => {
    if (!activity) return 'CaretRight';
    const { type } = activity;
    if (type === EActivityType.VIDEO) return 'CaretRight';
    if (type === EActivityType.PDF) return 'Bookmark';
    if (type === EActivityType.QUIZ) return 'RequestQuote';
    if (type === EActivityType.DELIVERABLE) return 'Upgrade';

    return 'CaretRight';
  };

  const getActivityDescription = () => {
    if (!activity) return undefined;
    const { type, resource } = activity;
    if (type === EActivityType.VIDEO) {
      const duration = formatDuration(resource?.file?.duration);
      return t('VIDEO_DESCRIPTION', { duration });
    }
    if (type === EActivityType.PDF) return t('PDF_DESCRIPTION');
    if (type === EActivityType.QUIZ)
      return t('QUIZ_DESCRIPTION', { questions: resource?.quiz?.questions.length });
    if (type === EActivityType.DELIVERABLE) return t('DELIVERABLE_DESCRIPTION');

    return undefined;
  };

  const handleNavigateToSummary = () => {
    navigateTo(`/subscription/summary`);
    fireEvent(AnalyticsTrackEvents.QUICK_ACCESS_DASHBOARD, {
      courseName: name
    });
  };

  const onClick = () => {
    if (!activity) return;
    navigateTo(`/subscription/${_id}?activity=${activity._id}`);
    fireEvent(AnalyticsTrackEvents.QUICK_ACCESS_DASHBOARD, {
      courseName: name,
      activityName: activity.name
    });
  };

  const icon = getActivityIcon();

  return (
    <WidgetBase
      title={t('CLASSES_AND_SYLLABUS')}
      {...props}
      backgroundColor={colors.meadwhite}
      onHeaderClick={handleNavigateToSummary}
      hoverable
      addon={
        <AddonWrapper>
          {!isMobile && (
            <AddonTextWrapper className="addon-text">{t('GO_TO_CONTENT')}</AddonTextWrapper>
          )}
          <StyledIcon className="addon-icon" size={24} i="ArrowRight" />
        </AddonWrapper>
      }>
      <Wrapper onClick={onClick}>
        {isLoading ? (
          <LoadingCircleSpinner />
        ) : (
          <>
            {!isMobile && <StyledImage src={UMY_DASHBOARD} alt="Umy Dashboard" />}
            <ColumnWrapper>
              <NextClassWrapper>{t('NEXT_CLASS', { name: profile?.name })}</NextClassWrapper>
              <InfoWrapper>
                <Button
                  className="activity-button"
                  type="primary"
                  shape="circle"
                  icon={<Icon i={icon} />}></Button>
                <ActivityNameWrapper>{`${section?.name} > ${activity?.name}`}</ActivityNameWrapper>
                <ActivityDescriptionWrapper>{getActivityDescription()}</ActivityDescriptionWrapper>
              </InfoWrapper>
            </ColumnWrapper>
          </>
        )}
      </Wrapper>
    </WidgetBase>
  );
};
