import { ECourseStatus } from '@constants';

export const CoursesTranslations = {
  COURSE: {
    es: 'Curso',
    en: 'Course',
    cat: 'Curs',
    eus: 'Ikastaroa'
  },
  PLEASE_SELECT_AT_LEAST_ONE_ACTIVITY: {
    es: 'Por favor, selecciona al menos una actividad',
    en: 'Please select at least one activity',
    cat: 'Per favor, selecciona almenys una activitat',
    eus: 'Mesedez, hautatu gutxienez ekintza bat'
  },
  DUPLICATE_COURSE: {
    es: 'Duplicar Curso',
    en: 'Duplicate Course',
    cat: 'Duplicar Curs',
    eus: 'Ikastaroa Bikoiztu'
  },
  DUPLICATE: {
    es: 'Duplicar',
    en: 'Duplicate',
    cat: 'Duplicar',
    eus: 'Bikoiztu'
  },
  COURSES: {
    es: 'Cursos',
    en: 'Courses',
    cat: 'Cursos',
    eus: 'Ikastaroak'
  },
  CANCEL: {
    es: 'Cancelar',
    en: 'Cancel',
    cat: 'Cancel·lar',
    eus: 'Ezeztatu'
  },
  ACADEMY: {
    es: 'Academia',
    en: 'Academy',
    cat: 'Acadèmia',
    eus: 'Akademia'
  },
  NAME: {
    es: 'Nombre',
    en: 'Name',
    cat: 'Nom',
    eus: 'Izena'
  },
  DESCRIPTION: {
    es: 'Descripción',
    en: 'Description',
    cat: 'Descripció',
    eus: 'Deskribapena'
  },
  NEW_COURSE: {
    es: 'Nuevo Curso',
    en: 'New Course',
    cat: 'Nou Curs',
    eus: 'Ikastaro Berria'
  },
  EDIT_COURSE: {
    es: 'Editar Curso',
    en: 'Edit Course',
    cat: 'Editar Curs',
    eus: 'Ikastaroa Aldatu'
  },
  SAVE_CHANGES: {
    es: 'Guardar Cambios',
    en: 'Save Changes',
    cat: 'Desa els canvis',
    eus: 'Aldaketak Gorde'
  },
  CREATE_COURSE: {
    es: 'Crear Curso',
    en: 'Create Course',
    cat: 'Crear Curs',
    eus: 'Ikastaroa Sortu'
  },
  DELETE_COURSE: {
    es: 'Eliminar Curso',
    en: 'Delete Course',
    cat: 'Eliminar Curs',
    eus: 'Ikastaroa Ezabatu'
  },
  DELETE_COURSE_CONFIRMATION: {
    es: '¿Estás seguro de eliminar este curso?',
    en: 'Are you sure you want to delete this course?',
    cat: 'Estàs segur de eliminar aquest curs?',
    eus: 'Zihur zaude ikastaro hau ezabatu nahi duzula?'
  },
  DELETE_COURSE_SUCCESS: {
    es: 'Curso eliminado con éxito',
    en: 'Course deleted successfully',
    cat: 'Curs eliminat amb èxit',
    eus: 'Ikastaroa ongi ezabatu da'
  },
  RECOVER_COURSE: {
    es: 'Recuperar Curso',
    en: 'Recover Course',
    cat: 'Recuperar Curs',
    eus: 'Ikastaroa Berreskuratu'
  },
  ARCHIVE_COURSE: {
    es: 'Archivar Curso',
    en: 'Archive Course',
    cat: 'Arxivar Curs',
    eus: 'Ikastaroa Artxibatu'
  },
  ARCHIVE_COURSE_CONFIRMATION: {
    es: '¿Estás seguro de archivar este curso?',
    en: 'Are you sure you want to archive this course?',
    cat: "Estàs segur d'arxivar aquest curs?",
    eus: 'Zihur zaude ikastaro hau artxibatu nahi duzula?'
  },
  UNARCHIVE_COURSE: {
    es: 'Desarchivar Curso',
    en: 'Unarchive Course',
    cat: 'Desarxivar Curs',
    eus: 'Ikastaroa Desartxibatu'
  },
  COURSE_PUBLISHED_SUCCESS: {
    es: 'Curso publicado con éxito',
    en: 'Course published successfully',
    cat: 'Curs publicat amb èxit',
    eus: 'Ikastaroa ongi argitaratu da'
  },
  COURSE_UNPUBLISHED_SUCCESS: {
    es: 'Curso despublicado',
    en: 'Course unpublished',
    cat: 'Curs despublicat',
    eus: 'Ikastaroa ezargitaratua'
  },
  STATUS: {
    es: 'Estado',
    en: 'Status',
    cat: 'Estat',
    eus: 'Egoera'
  },
  ACTIONS: {
    es: 'Acciones',
    en: 'Actions',
    cat: 'Accions',
    eus: 'Ekintzak'
  },
  [ECourseStatus.ARCHIVED]: {
    es: 'Archivado',
    en: 'Archived',
    cat: 'Arxivat',
    eus: 'Artxibatua'
  },
  [ECourseStatus.PUBLISHED]: {
    es: 'Publicado',
    en: 'Published',
    cat: 'Publicat',
    eus: 'Argitaratua'
  },
  [ECourseStatus.DRAFT]: {
    es: 'Borrador',
    en: 'Draft',
    cat: 'Esborrany',
    eus: 'Zirriborroa'
  },
  COURSES_TOTAL_COUNT: {
    es: '{{total}} Cursos',
    en: '{{total}} Courses',
    cat: '{{total}} Cursos',
    eus: '{{total}} Ikastaro'
  },
  SEARCH_PLACEHOLDER: {
    es: 'Buscar curso...',
    en: 'Search course...',
    cat: 'Cerca curs...',
    eus: 'Bilatu ikastaroa...'
  },
  SELECT_ALL: {
    es: 'Seleccionar todo',
    en: 'Select all',
    cat: 'Selecciona-ho tot',
    eus: 'Hautatu dena'
  },
  ASSIGNED_TEACHERS: {
    es: 'Profesores asignados',
    en: 'Assigned teachers',
    cat: 'Professors assignats',
    eus: 'Irakasle asignatuak'
  },
  TASK_PROGRESS: {
    es: 'Progreso',
    en: 'Progress',
    cat: 'Progrés',
    eus: 'Aurrerapen'
  },
  LIST: {
    es: 'Lista',
    en: 'List',
    cat: 'Llista',
    eus: 'Zerrenda'
  },
  TASKS: {
    es: 'Tareas',
    en: 'Tasks',
    cat: 'Tasques',
    eus: 'Lanak'
  },
  PANEL: {
    es: 'Panel',
    en: 'Panel',
    cat: 'Panell',
    eus: 'Panela'
  },
  GANTT: {
    es: 'Gantt',
    en: 'Gantt',
    cat: 'Gantt',
    eus: 'Gantt'
  },
  NOTHING_SELECTED: {
    es: 'Selecciona un curso para ver las tareas',
    en: 'Select a course to see the tasks',
    cat: 'Selecciona un curs per veure les tasques',
    eus: 'Kursua hautatu tareas ikusi'
  },
  EMPTY_TASKS: {
    es: 'No hay tareas en este curso',
    en: 'No tasks in this course',
    cat: 'No hi ha tasques en aquest curs',
    eus: 'Kursuan ez dago lanik'
  }
};
