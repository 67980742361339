import styled from 'styled-components';

import TITLE_VECTOR from '@/assets/onboarding_tools_vector.svg';

export const ListItem = styled.div`
  display: flex;
  gap: 16px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const BoldWrapper = styled.div`
  font-weight: 700;
`;

export const StyledSpan = styled.span`
  background-image: url(${TITLE_VECTOR});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 4px;
  padding-bottom: 20px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    padding-top: 8px;
  }
`;
