import { AnalyticsTrackEvents } from '@constants';
import { IUser } from '@sdk/contracts';
import { useState } from 'react';

const SEGMENT_DOM_ID = 'segment-definition';

export const useSegment = () => {
  const [initialized, setInitialized] = useState(Boolean(document.getElementById(SEGMENT_DOM_ID)));

  const fireEvent = (event: AnalyticsTrackEvents, properties?: unknown) => {
    if (!initialized) return;
    const trackProperties = properties || {};
    if (window.analytics) window.analytics.track(event, trackProperties);
  };

  const identify = (user: IUser, utm?: (string | null)[]) => {
    const instance = document.getElementById(SEGMENT_DOM_ID);
    if (!instance) return;
    const { _id, name, email, lastName, tags, createdAt, role } = user;
    const identifyUser = {
      name,
      email,
      lastName,
      role,
      utm,
      createdAt,
      tags: tags.map(tag => tag.name).join(', '),
      examDateExpected:
        user?.onBoarding?.month && user?.onBoarding?.year
          ? `${user.onBoarding.month}/${user.onBoarding.year}`
          : undefined,
      investTime: user?.onBoarding?.investTime
    };
    window.analytics.identify(_id, identifyUser);
  };

  const init = (user: IUser, utm?: (string | null)[]) => {
    const instance = document.getElementById(SEGMENT_DOM_ID);
    if (instance) return;
    const script = document.createElement('script');
    script.setAttribute('id', 'segment-definition');
    const scriptText =
      document.createTextNode(`!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${
        import.meta.env['VITE_SEGMENT_WRITE_KEY']
      }";analytics.SNIPPET_VERSION="4.15.2";
        analytics.load("${import.meta.env['VITE_SEGMENT_WRITE_KEY']}");
      }}();`);

    script.appendChild(scriptText);
    script.async = true;
    document.head.appendChild(script);

    if (user) {
      identify(user, utm);
    }
    setInitialized(true);
  };

  return { init, fireEvent, identify };
};
