import { create as createStore } from 'zustand';

interface IStore {
  userId?: string;
  setUserId: (userId?: string) => void;
}

export const useStudentPreview = createStore<IStore>(set => ({
  isOpen: false,
  setUserId: (userId?: string) => set({ userId })
}));
