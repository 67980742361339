import styled from 'styled-components';

import { Icon } from '@/components/Icon';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  width: 100%;
  margin-top: 8px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
`;

export const CourseWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.2px;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.meadreg};
    .addon-icon {
      transform: translate(0, 2px);
    }
  }
`;

export const StyledIcon = styled(Icon)`
  transition: transform 0.3s ease;
  transform-origin: top;
`;
