import { createQueryString } from '@utils';

import { IPaginatedResponse, IPagination } from '../common';
import { RequestService } from '../request';
import { ITeachersReportFilters } from '../user';

import {
  ICoursesByTag,
  ICreateTag,
  ITag,
  ITagContentReport,
  IUpdateTag,
  ITagCourses
} from './tag.contract';

export class TagService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  public getTags() {
    return this.fetcher<ITag[]>(`/tags`);
  }

  public createTag(tag: ICreateTag) {
    return this.poster(`/tags`, tag);
  }

  public updateTag(updatedTag: IUpdateTag) {
    return this.putter(`/tags/${updatedTag._id}`, updatedTag);
  }

  public removeTag(tagId: string) {
    return this.deleter(`/tags/${tagId}`);
  }

  public getTagsByCourses(search?: string, pagination?: IPagination) {
    const query = createQueryString({
      search,
      limit: pagination?.limit,
      page: pagination?.page
    });
    return this.fetcher<IPaginatedResponse<ICoursesByTag>>(`/tags/by-courses?${query}`);
  }

  public getTagCourses(tagId?: string) {
    return this.fetcher<ITagCourses>(`/tags/${tagId}/courses`);
  }

  public getTagContentReport = async (filters?: ITeachersReportFilters) => {
    const query = createQueryString({ filters });
    return this.fetcher<ITagContentReport[]>(`/tags/stats?${query}`);
  };
}
