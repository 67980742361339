export enum EVerticalNames {
  ADM = 'ADM',
  EDU = 'EDU',
  SAN = 'SAN',
  UNI = 'UNI',
  WKC = 'WKC',
  FP = 'FP'
}

export const ALL_VERTICALS = Object.values(EVerticalNames);
