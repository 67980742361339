import { ITask } from '@sdk/contracts';
import { create } from 'zustand';

interface IStore {
  task?: ITask;
  setTask: (task?: ITask) => void;
}

export const useChangeStatusForm = create<IStore>(set => ({
  setTask: task => set({ task })
}));
