import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;

  & > :first-child {
    font-size: 30px;
    font-weight: 500;
  }

  .ant-tabs {
    height: 100%;
  }

  .ant-tabs .ant-tabs-content {
    height: 100%;
  }

  .ant-tabs .ant-tabs-tabpane {
    height: 100%;
  }
`;
