import { create as createStore } from 'zustand';

interface IStore {
  problemReportId?: string;
  setProblemReport: (problemReportId?: string) => void;
}

const store = createStore<IStore>(set => ({
  setProblemReport: (problemReportId?: string) => set({ problemReportId })
}));

export const useProblemReportsDrawer = () => {
  const { problemReportId, setProblemReport } = store();

  const openDrawer = (reportId: string) => setProblemReport(reportId);

  const closeDrawer = () => setProblemReport();

  return {
    problemReportId,
    openDrawer,
    closeDrawer
  };
};
