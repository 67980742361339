import { createQueryString } from '@utils';

import { IPaginatedResponse, IPagination } from '../common';
import { RequestService } from '../request';

import {
  IAddExamGroupMembers,
  ICreateExamGroup,
  IExamGroup,
  IExamGroupFilter,
  IRemoveExamGroupMember,
  IUpdateExamGroup
} from './exam-group.contract';

export class ExamGroupService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  public getExamGroups(
    search?: string,
    pagination?: IPagination,
    filters?: IExamGroupFilter,
    bypass?: boolean
  ) {
    const query = createQueryString({
      bypass,
      search,
      page: pagination?.page,
      limit: pagination?.limit,
      filters
    });
    return this.fetcher<IPaginatedResponse<IExamGroup>>(`/exam-groups?${query}`);
  }

  public getMyExamGroups() {
    return this.fetcher<IExamGroup[]>(`/exam-groups/me`);
  }

  public getExamGroup(groupId: string) {
    return this.fetcher<IExamGroup>(`/exam-groups/${groupId}`);
  }

  public createExamGroup(createExamGroup: ICreateExamGroup) {
    return this.poster(`/exam-groups`, createExamGroup);
  }

  public updateExamGroup(updatedGroup: IUpdateExamGroup) {
    const { _id, ...update } = updatedGroup;
    return this.putter(`/exam-groups/${_id}`, update);
  }

  public removeExamGroup(groupId: string) {
    return this.deleter(`/exam-groups/${groupId}`);
  }

  public removeManyExamGroups(groupsIds: string[]) {
    return this.deleter(`/exam-groups`, {
      data: { groupsIds }
    });
  }

  public addMembers(membersToAdd: IAddExamGroupMembers) {
    const { groupId } = membersToAdd;
    return this.poster(`/exam-groups/${groupId}/members`, membersToAdd);
  }

  public removeMember(removeExamGroupMember: IRemoveExamGroupMember) {
    const { groupId, memberId } = removeExamGroupMember;
    return this.deleter<IExamGroup>(`/exam-groups/${groupId}/members/${memberId}`);
  }
}
