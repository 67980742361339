import { lazy } from 'react';

import { IRoute } from '../../types/routes';

const AcademiesPage = lazy(() =>
  import('@/pages/Admin').then(module => ({ default: module.AcademiesPage }))
);
const UsersPage = lazy(() =>
  import('@/pages/Admin').then(module => ({ default: module.UsersPage }))
);

const DashboardPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.DashboardPage }))
);
const CoursesPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.CoursesPage }))
);
const CoursePage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.CoursePage }))
);
const AITeachersPage = lazy(() =>
  import('@/pages/Admin').then(module => ({ default: module.AITeachersPage }))
);

const PromptsPage = lazy(() =>
  import('@/pages/Admin').then(module => ({ default: module.PromptsPage }))
);

export const ADMIN: IRoute[] = [
  {
    title: 'DASHBOARD',
    path: '/dashboard',
    icon: 'Home',
    Component: DashboardPage,
    showInMenu: true
  },
  {
    title: 'USERS',
    path: '/users',
    icon: 'UserMultiple',
    Component: UsersPage,
    showInMenu: true
  },
  {
    title: 'ACADEMIES',
    path: '/academies',
    icon: 'Building',
    Component: AcademiesPage,
    showInMenu: true
  },
  {
    title: 'COURSES',
    path: '/courses',
    icon: 'Notebook',
    Component: CoursesPage,
    showInMenu: true,
    subroutes: [
      {
        title: 'COURSES',
        path: ':_id',
        icon: 'Undefined',
        Component: CoursePage,
        showInMenu: false
      },
      {
        title: 'ALL_COURSES',
        path: '',
        icon: 'Undefined',
        Component: CoursesPage,
        showInMenu: true
      }
    ]
  },
  {
    title: 'AI_TEACHERS',
    path: '/ai-teachers',
    icon: 'IbmZCloudModStack',
    Component: AITeachersPage,
    showInMenu: true,
    subroutes: [
      {
        title: 'PROMPTS',
        path: 'prompts',
        icon: 'Undefined',
        Component: PromptsPage,
        showInMenu: true
      }
    ]
  }
];
