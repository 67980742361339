import { createQueryString } from '@utils';

import { IPaginatedResponse, IPagination } from '../common';
import { ICreateMessage } from '../messages';
import { RequestService } from '../request';

import {
  ICreateDeliverable,
  IDeliverable,
  IDeliverableFilter,
  ISendDeliverableMessage,
  ISetDeliverableReview
} from './deliverable.contract';

export class DeliverableService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  public getDeliverables(
    search?: string,
    pagination?: IPagination,
    bypass?: boolean,
    filters?: IDeliverableFilter
  ) {
    const query = createQueryString({
      search,
      limit: pagination?.limit,
      page: pagination?.page,
      bypass,
      filters
    });
    return this.fetcher<IPaginatedResponse<IDeliverable>>(`/deliverable?${query}`);
  }

  public getDeliverable(_id: string) {
    return this.fetcher<IDeliverable>(`/deliverable/${_id}`);
  }

  public getDeliverableByActivity(activityId: string) {
    return this.fetcher<IDeliverable>(`/deliverable/activity/${activityId}`);
  }

  public createDeliverable(newDeliverable: ICreateDeliverable) {
    return this.poster<IDeliverable, ICreateDeliverable>(`/deliverable`, newDeliverable);
  }

  public updateDeliverable(updatedDeliverable: Partial<IDeliverable>) {
    return this.putter<IDeliverable, Partial<IDeliverable>>(
      `/deliverable/${updatedDeliverable._id}`,
      updatedDeliverable
    );
  }

  public sendMessage({ deliverableId, createMessage }: ISendDeliverableMessage) {
    return this.poster<IDeliverable, ICreateMessage>(
      `/deliverable/${deliverableId}/messages`,
      createMessage
    );
  }

  public removeDeliverable(deliverableId: string) {
    return this.deleter(`/deliverable/${deliverableId}`);
  }

  public setDeliverableReview({ deliverableId, review }: ISetDeliverableReview) {
    return this.poster(`/deliverable/${deliverableId}/review`, review);
  }
}
