import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TeacherSearchSelector } from '@/components/Form';
import { useTicketViewer } from '@/hooks';
import { useProfile, useMutateMyTickets } from '@/store';

interface Props {
  teacherId?: string;
  name?: string;
}

export const ReassignTeacher: React.FC<Props> = ({ teacherId, name }) => {
  const [value, setValue] = useState<string>();
  const { t } = useTranslation('CommonTicketsTranslations');
  const {
    roleCheck: { isManager }
  } = useProfile();
  const { ticketId } = useTicketViewer();
  const { updateTicketAssignee } = useMutateMyTickets();

  useEffect(() => {
    setValue(teacherId);
  }, [teacherId]);

  const handleChange = (newValue: string) => {
    setValue(newValue);
    if (ticketId) updateTicketAssignee({ ticketId, assignee: newValue });
  };

  if (!isManager) return null;

  return (
    <div>
      <hr />
      <h5>{t('ASSIGN_TEACHER')}</h5>
      <TeacherSearchSelector
        value={value}
        onChange={handleChange}
        style={{ width: '100%' }}
        filter={name}
      />
    </div>
  );
};
