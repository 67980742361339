import { EQuizMode } from '@constants';

export const StudentQuizTranslations = {
  TEST: {
    es: 'Tests',
    en: 'Tests',
    cat: 'Tests',
    eus: 'Tests'
  },
  ANSWERS: {
    es: 'Respuestas',
    en: 'Answers',
    cat: 'Respostes',
    eus: 'Erantzunak'
  },
  CORRECT_AND_SAVE: {
    es: 'Corregir y guardar',
    en: 'Correct and save',
    cat: 'Corregir i desar',
    eus: 'Zuzendu eta gorde'
  },
  QUESTIONS: {
    es: 'Preguntas',
    en: 'Questions',
    cat: 'Preguntes',
    eus: 'Galderak'
  },
  TOTAL: {
    es: 'Total:',
    en: 'Total:',
    cat: 'Total:',
    eus: 'Guztira:'
  },
  YOUR_RESPONSES_WILL_BE_SEND: {
    es: 'Se van a enviar tus respuestas.',
    en: 'Your responses will be send.',
    cat: 'Les teves respostes seran enviades.',
    eus: 'Zure erantzunak bidaliko dira.'
  },
  DO_YOU_WANT_TO_CONTINUE: {
    es: '¿Deseas continuar?',
    en: 'Do you want to continue?',
    cat: 'Vols continuar?',
    eus: 'Jarraitu nahi duzu?'
  },
  CANCEL: {
    es: 'Cancelar',
    en: 'Cancel',
    cat: 'Cancel·lar',
    eus: 'Ezeztatu'
  },
  CONTINUE: {
    es: 'Continuar',
    en: 'Continue',
    cat: 'Continuar',
    eus: 'Jarraitu'
  },
  [EQuizMode.FREE_MODE]: {
    es: 'Modo libre',
    en: 'Free mode',
    cat: 'Mode lliure',
    eus: 'Modo libre'
  },
  [EQuizMode.PRACTICE_MODE]: {
    es: 'Modo simulacro',
    en: 'Simulator mode',
    cat: 'Mode simulacre',
    eus: 'Mode simuladore'
  },
  [EQuizMode.ACTIVITY_MODE]: {
    es: 'Modo actividad',
    en: 'Activity mode',
    cat: 'Mode activitat',
    eus: 'Jarduera modua'
  },
  TIMER_PAUSED: {
    es: 'Has pausado el temporizador',
    en: 'You have paused the timer',
    cat: 'Has pausat el temporitzador',
    eus: 'Kronometroa pausatuta daukazu'
  },
  CORRECT_RESPONSES: {
    es: 'Respuestas correctas',
    en: 'Correct answers',
    cat: 'Respostes correctes',
    eus: 'Erantzun zuzenak'
  },
  WRONG_RESPONSES: {
    es: 'Respuestas incorrectas',
    en: 'Wrong answers',
    cat: 'Respostes incorrectes',
    eus: 'Erantzun okerrak'
  },
  BLANK_RESPONSES: {
    es: 'Respuestas en blanco',
    en: 'Blank answers',
    cat: 'Respostes en blanc',
    eus: 'Erantzun hutsak'
  },
  SCORE: {
    es: 'Nota',
    en: 'Score',
    cat: 'Puntuació',
    eus: 'Puntuazioa'
  },
  RETRY_QUIZ: {
    es: 'Repetir',
    en: 'Retry',
    cat: 'Repetir',
    eus: 'Errepikatu'
  },
  FINISH_QUIZ: {
    es: 'Finalizar',
    en: 'Finish',
    cat: 'Finalitzar',
    eus: 'Amaitu'
  },
  EXIT_QUIZ: {
    es: 'Salir',
    en: 'Exit',
    cat: 'Sortir',
    eus: 'Irten'
  },
  REPORT_QUESTION: {
    es: 'Reportar pregunta',
    en: 'Report question',
    cat: 'Informar de la pregunta',
    eus: 'Galdera jakinarazi'
  },
  REPORT_TYPE: {
    es: 'Tipo de problema',
    en: 'Problem type',
    cat: 'Tipus de problema',
    eus: 'Arazo mota'
  },
  REPORT_DESCRIPTION: {
    es: 'Descripción',
    en: 'Description',
    cat: 'Descripció',
    eus: 'Deskribapena'
  },
  DELETE_RESPONSE: {
    es: 'Borrar respuesta',
    en: 'Delete response',
    cat: 'Esborrar resposta',
    eus: 'Erantzuna ezabatu'
  },
  REVIEW_MODE_TITLE: {
    es: 'Modo repaso',
    en: 'Review mode',
    cat: 'Mode repàs',
    eus: 'Berrikusi modua'
  },
  REVIEW_MODE_DESCRIPTION: {
    es: 'Asienta tu conocimiento jugando al modo repaso. Hemos recopilado todos tus fallos en los tests que has realizado para que puedas volver a repasarlos.',
    en: 'Consolidate your knowledge by playing the review mode. We have collected all your failures in the tests you have taken so that you can review them again.',
    cat: 'Consolida el teu coneixement jugant al mode repàs. Hem recopilat tots els teus errors als tests que has fet perquè puguis repassar-los de nou.',
    eus: 'Zure jakintza finkatu berrikusi moduan jokatuz. Egindako testetako erroreak guztira biltzen ditugu berriro ere berrikusteko.'
  },
  REVIEW_MODE_CHECK_ALL: {
    es: 'Seleccionar todas',
    en: 'Check all',
    cat: 'Seleccionar totes',
    eus: 'Guztiak hautatu'
  },
  REVIEW_MODE_FAILED_QUESTIONS: {
    es: 'preguntas falladas',
    en: 'failed questions',
    cat: 'preguntes fallades',
    eus: 'galderek huts egin dira'
  },
  REVIEW_MODE_QUESTIONS: {
    es: 'Preguntas',
    en: 'Questions',
    cat: 'Preguntes',
    eus: 'Galderak'
  },
  REVIEW_MODE_START_BUTTON: {
    es: 'Comenzar',
    en: 'Start',
    cat: 'Començar',
    eus: 'Hasi'
  },
  SELECT_ANSWER: {
    es: 'Debes seleccionar una respuesta.',
    en: 'You must select an answer.',
    cat: 'Has de seleccionar una resposta.',
    eus: 'Erantzun bat hautatu behar duzu.'
  },
  EXIT: {
    es: 'Salir',
    en: 'Exit',
    cat: 'Sortir',
    eus: 'Irten'
  },
  EXIT_QUIZ_CONFIRMATION_TITLE: {
    es: '¿Seguro que quieres salir?',
    en: 'Are you sure you want to exit?',
    cat: 'Segur que vols sortir?',
    eus: 'Ziur zaude irtetzeko nahi duzula?'
  },
  EXIT_QUIZ_CONFIRMATION_SUBTITLE: {
    es: 'Perderás el avance de este simulacro.',
    en: 'You will lose the progress of this simulation.',
    cat: "Perdràs el progrés d'aquest simulacre.",
    eus: 'Galdera hau ezabatu nahi duzula ziur zaude?'
  },
  CONTINUE_WITH_TEST: {
    es: 'Seguir con el test',
    en: 'Continue with the test',
    cat: 'Continuar amb el test',
    eus: 'Jarraitu testarekin'
  }
};
