import {
  WatsonHealth3DCursor,
  WatsonHealth3DCursorAlt,
  WatsonHealth3DCurveAutoColon,
  WatsonHealth3DCurveAutoVessels,
  WatsonHealth3DCurveManual,
  WatsonHealth3DICa,
  WatsonHealth3DMprToggle,
  WatsonHealth3DPrintMesh,
  WatsonHealth3DSoftware,
  WatsonHealth3rdPartyConnected,
  _4K,
  _4KFilled,
  Accessibility,
  AccessibilityAlt,
  AccessibilityColor,
  AccessibilityColorFilled,
  Account,
  AccumulationIce,
  AccumulationPrecipitation,
  AccumulationRain,
  AccumulationSnow,
  Activity,
  Add,
  AddAlt,
  AddFilled,
  AddComment,
  AgricultureAnalytics,
  WatsonHealthAiResults,
  WatsonHealthAiResultsHigh,
  WatsonHealthAiResultsLow,
  WatsonHealthAiResultsMedium,
  WatsonHealthAiResultsUrgent,
  WatsonHealthAiResultsVeryHigh,
  WatsonHealthAiStatus,
  WatsonHealthAiStatusComplete,
  WatsonHealthAiStatusFailed,
  WatsonHealthAiStatusInProgress,
  WatsonHealthAiStatusQueued,
  WatsonHealthAiStatusRejected,
  AirlineDigitalGate,
  AirlineManageGates,
  AirlinePassengerCare,
  AirlineRapidBoard,
  Airplay,
  AirplayFilled,
  Airport_01,
  Airport_02,
  AirportLocation,
  Alarm,
  AlarmAdd,
  AlarmSubtract,
  AlignHorizontalCenter,
  AlignHorizontalLeft,
  AlignHorizontalRight,
  AlignVerticalBottom,
  AlignVerticalCenter,
  AlignVerticalTop,
  AlignBoxBottomCenter,
  AlignBoxBottomLeft,
  AlignBoxBottomRight,
  AlignBoxMiddleCenter,
  AlignBoxMiddleLeft,
  AlignBoxMiddleRight,
  AlignBoxTopCenter,
  AlignBoxTopLeft,
  AlignBoxTopRight,
  Analytics,
  AnalyticsCustom,
  AnalyticsReference,
  WatsonHealthAngle,
  WatsonHealthAnnotationVisibility,
  Aperture,
  Api,
  Api_1,
  App,
  AppConnectivity,
  Apple,
  AppleDash,
  Application,
  ApplicationMobile,
  ApplicationVirtual,
  ApplicationWeb,
  Apps,
  Archive,
  Area,
  AreaCustom,
  Arrival,
  WatsonHealthArrowAnnotation,
  ArrowDown,
  ArrowDownLeft,
  ArrowDownRight,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  ArrowUpLeft,
  ArrowUpRight,
  ArrowShiftDown,
  ArrowsHorizontal,
  ArrowsVertical,
  Asleep,
  AsleepFilled,
  Assembly,
  AssemblyCluster,
  AssemblyReference,
  Asset,
  AssetConfirm,
  AssetDigitalTwin,
  AssetView,
  Asterisk,
  At,
  Attachment,
  AudioConsole,
  AugmentedReality,
  WatsonHealthAutoScroll,
  Automatic,
  Autoscaling,
  Awake,
  Badge,
  BaggageClaim,
  Bar,
  Barcode,
  BareMetalServer,
  BareMetalServer_01,
  BareMetalServer_02,
  QBarrier,
  Basketball,
  BastionHost,
  Bat,
  BatchJob,
  BatchJobStep,
  BatteryCharging,
  BatteryEmpty,
  BatteryFull,
  BatteryHalf,
  BatteryLow,
  BatteryQuarter,
  Bee,
  BeeBat,
  Beta,
  Bicycle,
  Binoculars,
  QBlochSphere,
  BlockStorage,
  BlockStorageAlt,
  Blockchain,
  Blog,
  Bluetooth,
  BluetoothOff,
  Book,
  Bookmark,
  BookmarkAdd,
  BookmarkFilled,
  Boolean,
  Boot,
  BootVolume,
  BootVolumeAlt,
  BorderBottom,
  BorderFull,
  BorderLeft,
  BorderNone,
  BorderRight,
  BorderTop,
  Bot,
  Bottles_01,
  Bottles_01Dash,
  Bottles_02,
  Bottles_02Dash,
  BottlesContainer,
  Box,
  BoxExtraLarge,
  BoxLarge,
  BoxMedium,
  BoxSmall,
  BoxPlot,
  Branch,
  BreakingChange,
  BrightnessContrast,
  BringForward,
  BringToFront,
  WatsonHealthBrushFreehand,
  WatsonHealthBrushPolygon,
  BuildTool,
  Building,
  BuildingInsights_1,
  BuildingInsights_2,
  BuildingInsights_3,
  Bullhorn,
  Buoy,
  Bus,
  ButtonCentered,
  ButtonFlushLeft,
  CabinCare,
  CabinCareAlert,
  CabinCareAlt,
  Cad,
  Cafe,
  Calculation,
  CalculationAlt,
  Calculator,
  CalculatorCheck,
  Calendar,
  CalendarAdd,
  CalendarAddAlt,
  CalendarHeatMap,
  CalendarSettings,
  CalendarTools,
  Calibrate,
  Camera,
  CameraAction,
  Campsite,
  Car,
  CarFront,
  Carbon,
  CarbonUiBuilder,
  CarbonAccounting,
  CarbonForIbmDotcom,
  CarbonForIbmProduct,
  CaretDown,
  CaretLeft,
  CaretRight,
  CaretSort,
  CaretSortDown,
  CaretSortUp,
  CaretUp,
  CarouselHorizontal,
  CarouselVertical,
  Catalog,
  CatalogPublish,
  Categories,
  Category,
  CategoryAdd,
  CategoryAnd,
  CategoryNew,
  CategoryNewEach,
  QCcX,
  WatsonHealthCdArchive,
  WatsonHealthCdCreateArchive,
  WatsonHealthCdCreateExchange,
  Cda,
  CellTower,
  CenterCircle,
  CenterSquare,
  CenterToFit,
  Certificate,
  CertificateCheck,
  ChangeCatalog,
  CharacterDecimal,
  CharacterFraction,
  CharacterInteger,
  CharacterLowerCase,
  CharacterNegativeNumber,
  CharacterSentenceCase,
  CharacterUpperCase,
  CharacterWholeNumber,
  CharacterPatterns,
  ChargingStation,
  ChargingStationFilled,
  Chart_3D,
  ChartArea,
  ChartAreaSmooth,
  ChartAreaStepper,
  ChartAverage,
  ChartBar,
  ChartBarFloating,
  ChartBarOverlay,
  ChartBarStacked,
  ChartBarTarget,
  ChartBubble,
  ChartBubblePacked,
  ChartBullet,
  ChartCandlestick,
  ChartClusterBar,
  ChartColumn,
  ChartColumnFloating,
  ChartColumnTarget,
  ChartCombo,
  ChartComboStacked,
  ChartCustom,
  ChartErrorBar,
  ChartErrorBarAlt,
  ChartEvaluation,
  ChartHighLow,
  ChartHistogram,
  ChartLine,
  ChartLineData,
  ChartLineSmooth,
  ChartLogisticRegression,
  ChartMarimekko,
  ChartMaximum,
  ChartMedian,
  ChartMinimum,
  ChartMultiLine,
  ChartMultitype,
  ChartNetwork,
  ChartParallel,
  ChartPie,
  ChartPoint,
  ChartPopulation,
  ChartRadar,
  ChartRadial,
  ChartRelationship,
  ChartRing,
  ChartRiver,
  ChartRose,
  ChartScatter,
  ChartSpiral,
  ChartStacked,
  ChartStepper,
  ChartSunburst,
  ChartTSne,
  ChartTreemap,
  ChartVennDiagram,
  ChartViolinPlot,
  ChartWaterfall,
  ChartWinLoss,
  Chat,
  ChatLaunch,
  ChatOff,
  ChatOperational,
  ChatBot,
  Checkbox,
  CheckboxChecked,
  CheckboxCheckedFilled,
  CheckboxIndeterminate,
  CheckboxIndeterminateFilled,
  Checkmark,
  CheckmarkFilled,
  CheckmarkFilledError,
  CheckmarkFilledWarning,
  CheckmarkOutline,
  CheckmarkOutlineError,
  CheckmarkOutlineWarning,
  Chemistry,
  ChemistryReference,
  ChevronDown,
  ChevronLeft,
  ChevronMini,
  ChevronRight,
  ChevronSort,
  ChevronSortDown,
  ChevronSortUp,
  ChevronUp,
  Chip,
  Choices,
  ChooseItem,
  ChoroplethMap,
  CicsCmas,
  CicsDb2Connection,
  CicsExplorer,
  CicsProgram,
  CicsSit,
  CicsSitOverrides,
  CicsSystemGroup,
  CicsTransactionServerZos,
  CicsWuiRegion,
  CicsRegion,
  CicsRegionAlt,
  CicsRegionRouting,
  CicsRegionTarget,
  Cicsplex,
  CircleFilled,
  CircleSolid,
  CircleDash,
  WatsonHealthCircleMeasurement,
  CirclePacking,
  QCircuitComposer,
  Classification,
  ClassifierLanguage,
  Clean,
  Close,
  CloseFilled,
  CloseOutline,
  ClosedCaption,
  ClosedCaptionAlt,
  ClosedCaptionFilled,
  Cloud,
  CloudAlerting,
  CloudAuditing,
  CloudDataOps,
  CloudDownload,
  CloudLogging,
  CloudMonitoring,
  CloudOffline,
  CloudServiceManagement,
  CloudUpload,
  CloudApp,
  CloudCeiling,
  CloudFoundry_1,
  CloudFoundry_2,
  CloudRegistry,
  CloudSatellite,
  CloudSatelliteConfig,
  CloudSatelliteLink,
  CloudSatelliteServices,
  CloudServices,
  Cloudy,
  WatsonHealthCobbAngle,
  Code,
  CodeHide,
  CodeReference,
  CodeSigningService,
  Cognitive,
  Collaborate,
  CollapseAll,
  CollapseCategories,
  ColorPalette,
  ColorSwitch,
  Column,
  ColumnDelete,
  ColumnInsert,
  ColumnDependency,
  Commit,
  CommunicationUnified,
  Compare,
  Compass,
  QComposerEdit,
  Concept,
  ConditionPoint,
  ConditionWaitPoint,
  Connect,
  ConnectRecursive,
  ConnectReference,
  ConnectSource,
  ConnectTarget,
  ConnectionReceive,
  ConnectionSend,
  ConnectionTwoWay,
  ConnectionSignal,
  ConnectionSignalOff,
  Construction,
  ContainerRegistry,
  ContainerServices,
  ContainerSoftware,
  ContentDeliveryNetwork,
  ContentView,
  Continue,
  ContinueFilled,
  ContinuousDeployment,
  ContinuousIntegration,
  WatsonHealthContourDraw,
  WatsonHealthContourEdit,
  WatsonHealthContourFinding,
  Contrast,
  ConvertToCloud,
  Cookie,
  Copy,
  CopyFile,
  CopyLink,
  Corn,
  Corner,
  Coronavirus,
  Cost,
  CostTotal,
  Cough,
  Course,
  Covariate,
  Credentials,
  Crop,
  CropGrowth,
  CropHealth,
  WatsonHealthCrossReference,
  CrossTab,
  Crossroads,
  CrowdReport,
  CrowdReportFilled,
  Csv,
  QCU1,
  QCU3,
  Cube,
  CubeView,
  Currency,
  CurrencyBaht,
  CurrencyDollar,
  CurrencyEuro,
  CurrencyLira,
  CurrencyPound,
  CurrencyRuble,
  CurrencyRupee,
  CurrencyShekel,
  CurrencyWon,
  CurrencyYen,
  Cursor_1,
  Cursor_2,
  CustomerService,
  Cut,
  WatsonHealthCutInHalf,
  CutOut,
  QCY,
  Cyclist,
  QCZ,
  Dashboard,
  DashboardReference,
  Data_1,
  Data_2,
  DataBase,
  DataBaseAlt,
  DataCategorical,
  DataCenter,
  DataCheck,
  DataConnected,
  DataError,
  DataFormat,
  DataReference,
  DataRegular,
  DataSet,
  DataStructured,
  DataUnstructured,
  DataView,
  DataViewAlt,
  DataAccessor,
  DataBackup,
  DataBin,
  DataBlob,
  DataClass,
  DataCollection,
  DataDefinition,
  DataDiode,
  DataEnrichment,
  DataEnrichmentAdd,
  DataPlayer,
  DataQualityDefinition,
  DataRefinery,
  DataRefineryReference,
  DataShare,
  DataTable,
  DataTableReference,
  DataVis_1,
  DataVis_2,
  DataVis_3,
  DataVis_4,
  DataVolume,
  DataVolumeAlt,
  DatabaseDatastax,
  DatabaseElastic,
  DatabaseEnterpriseDb2,
  DatabaseEtcd,
  DatabaseMongodb,
  DatabasePostgreSql,
  DatabaseRabbit,
  DatabaseRedis,
  Datastore,
  Db2BufferPool,
  Db2DataSharingGroup,
  Db2Database,
  Debug,
  DecisionTree,
  Delivery,
  DeliveryAdd,
  DeliveryParcel,
  DeliveryTruck,
  WatsonHealthDenominate,
  Departure,
  Deploy,
  DeployRules,
  DeploymentPattern,
  DeploymentPolicy,
  DeploymentUnitData,
  DeploymentUnitExecution,
  DeploymentUnitInstallation,
  DeploymentUnitPresentation,
  DeploymentUnitTechnicalData,
  DeploymentUnitTechnicalExecution,
  DeploymentUnitTechnicalInstallation,
  DeploymentUnitTechnicalPresentation,
  DeskAdjustable,
  Development,
  Devices,
  DewPoint,
  DewPointFilled,
  Diagram,
  DiagramReference,
  WatsonHealthDicom_6000,
  WatsonHealthDicomOverlay,
  DirectLink,
  DirectionBearRight_01,
  DirectionBearRight_01Filled,
  DirectionBearRight_02,
  DirectionBearRight_02Filled,
  DirectionCurve,
  DirectionCurveFilled,
  DirectionFork,
  DirectionForkFilled,
  DirectionLoopLeft,
  DirectionLoopLeftFilled,
  DirectionLoopRight,
  DirectionLoopRightFilled,
  DirectionMerge,
  DirectionMergeFilled,
  DirectionRight_01,
  DirectionRight_01Filled,
  DirectionRight_02,
  DirectionRight_02Filled,
  DirectionRotaryFirstRight,
  DirectionRotaryFirstRightFilled,
  DirectionRotaryRight,
  DirectionRotaryRightFilled,
  DirectionRotaryStraight,
  DirectionRotaryStraightFilled,
  DirectionSharpTurn,
  DirectionSharpTurnFilled,
  DirectionStraight,
  DirectionStraightFilled,
  DirectionStraightRight,
  DirectionStraightRightFilled,
  DirectionUTurn,
  DirectionUTurnFilled,
  DirectoryDomain,
  DistributeHorizontalCenter,
  DistributeHorizontalLeft,
  DistributeHorizontalRight,
  DistributeVerticalBottom,
  DistributeVerticalCenter,
  DistributeVerticalTop,
  WatsonHealthDna,
  DnsServices,
  Doc,
  Document,
  DocumentAdd,
  DocumentAttachment,
  DocumentAudio,
  DocumentBlank,
  DocumentDownload,
  DocumentEpdf,
  DocumentExport,
  DocumentHorizontal,
  DocumentImport,
  DocumentMultiple_01,
  DocumentMultiple_02,
  DocumentPdf,
  DocumentPreliminary,
  DocumentProtected,
  DocumentSecurity,
  DocumentSigned,
  DocumentSketch,
  DocumentSubtract,
  DocumentTasks,
  DocumentUnknown,
  DocumentUnprotected,
  DocumentVertical,
  DocumentVideo,
  DocumentView,
  DocumentWordProcessor,
  DocumentWordProcessorReference,
  DocumentSentiment,
  DogWalker,
  DotMark,
  DoubleInteger,
  DownToBottom,
  Download,
  WatsonHealthDownloadStudy,
  DragHorizontal,
  DragVertical,
  Draggable,
  Draw,
  DrillBack,
  DrillDown,
  DrillThrough,
  Drink_01,
  Drink_02,
  DriverAnalysis,
  Drone,
  DroneDelivery,
  DroneFront,
  DroneVideo,
  DropPhoto,
  DropPhotoFilled,
  Drought,
  Dvr,
  Earth,
  EarthAmericas,
  EarthAmericasFilled,
  EarthEuropeAfrica,
  EarthEuropeAfricaFilled,
  EarthFilled,
  EarthSoutheastAsia,
  EarthSoutheastAsiaFilled,
  Earthquake,
  EdgeCluster,
  EdgeDevice,
  WatsonHealthEdgeEnhancement,
  WatsonHealthEdgeEnhancement_01,
  WatsonHealthEdgeEnhancement_02,
  WatsonHealthEdgeEnhancement_03,
  EdgeNode,
  EdgeNodeAlt,
  EdgeService,
  Edit,
  EditOff,
  EdtLoop,
  Education,
  Email,
  EmailNew,
  Encryption,
  EnergyRenewable,
  Enterprise,
  Equalizer,
  Erase,
  WatsonHealthErase_3D,
  Error,
  ErrorFilled,
  ErrorOutline,
  Event,
  EventSchedule,
  Events,
  EventsAlt,
  ExamMode,
  ExecutableProgram,
  Exit,
  ExpandAll,
  ExpandCategories,
  Explore,
  Export,
  Eyedropper,
  FaceActivated,
  FaceActivatedAdd,
  FaceActivatedFilled,
  FaceAdd,
  FaceCool,
  FaceDissatisfied,
  FaceDissatisfiedFilled,
  FaceDizzy,
  FaceDizzyFilled,
  FaceMask,
  FaceNeutral,
  FaceNeutralFilled,
  FacePending,
  FacePendingFilled,
  FaceSatisfied,
  FaceSatisfiedFilled,
  FaceWink,
  FaceWinkFilled,
  Factor,
  Fade,
  Favorite,
  FavoriteFilled,
  FavoriteHalf,
  FetchUpload,
  FetchUploadCloud,
  FileStorage,
  Filter,
  FilterEdit,
  FilterRemove,
  FilterReset,
  Finance,
  FingerprintRecognition,
  Fire,
  Firewall,
  FirewallClassic,
  Fish,
  FishMultiple,
  FitToHeight,
  FitToScreen,
  FitToWidth,
  Flag,
  FlagFilled,
  FlaggingTaxi,
  Flash,
  FlashFilled,
  FlashOff,
  FlashOffFilled,
  FlightInternational,
  FlightRoster,
  FlightSchedule,
  FloatingIp,
  Flood,
  FloodWarning,
  Floorplan,
  Flow,
  FlowConnection,
  FlowData,
  FlowModeler,
  FlowModelerReference,
  FlowStream,
  FlowStreamReference,
  FlowLogsVpc,
  Fog,
  Folder,
  FolderAdd,
  FolderDetails,
  FolderDetailsReference,
  FolderMoveTo,
  FolderOff,
  FolderOpen,
  FolderParent,
  FolderShared,
  Folders,
  ForecastHail,
  ForecastHail_30,
  ForecastLightning,
  ForecastLightning_30,
  Fork,
  Forum,
  Forward_10,
  Forward_30,
  Forward_5,
  Fragile,
  Friendship,
  FruitBowl,
  Function,
  FunctionMath,
  WatsonHealthFusionBlender,
  GameConsole,
  GameWireless,
  Gamification,
  GasStation,
  GasStationFilled,
  Gateway,
  GatewayApi,
  GatewayMail,
  GatewayPublic,
  GatewaySecurity,
  GatewayUserAccess,
  GatewayVpn,
  GenderFemale,
  GenderMale,
  GeneratePdf,
  Gif,
  Gift,
  Globe,
  Gradient,
  GraphicalDataFlow,
  Grid,
  Group,
  GroupAccess,
  GroupAccount,
  GroupPresentation,
  GroupResource,
  GroupSecurity,
  GroupObjects,
  GroupObjectsNew,
  GroupObjectsSave,
  Growth,
  Gui,
  GuiManagement,
  QH,
  Hail,
  WatsonHealthHangingProtocol,
  Harbor,
  HardwareSecurityModule,
  Hashtag,
  Haze,
  HazeNight,
  Hd,
  HdFilled,
  Hdr,
  Headphones,
  Headset,
  HealthCross,
  Hearing,
  HeatMap,
  HeatMap_02,
  HeatMap_03,
  HeatMapStocks,
  Helicopter,
  Help,
  HelpFilled,
  HelpDesk,
  QHintonPlot,
  WatsonHealthHl7Attributes,
  WatsonHealthHoleFilling,
  WatsonHealthHoleFillingCursor,
  Home,
  HorizontalView,
  Hospital,
  HospitalBed,
  Hotel,
  Hourglass,
  Html,
  HtmlReference,
  Http,
  Humidity,
  HumidityAlt,
  Hurricane,
  HybridNetworking,
  HybridNetworkingAlt,
  IbmBluepay,
  IbmContentServices,
  IbmDataReplication,
  IbmDatastage,
  IbmDb2,
  IbmDb2Alt,
  IbmMatch_360,
  IbmMq,
  IbmOpenEnterpriseLanguages,
  IbmPowerVs,
  IbmPowerWithVpc,
  IbmPrivatePathServices,
  IbmProcessMining,
  IbmSapOnPower,
  IbmTelehealth,
  IbmTenet,
  IbmToolchain,
  IbmVsiOnVpcForRegulatedIndustries,
  IbmCloud,
  IbmCloudAppId,
  IbmCloudCitrixDaas,
  IbmCloudContinuousDelivery,
  IbmCloudDedicatedHost,
  IbmCloudDirectLink_1Connect,
  IbmCloudDirectLink_1Dedicated,
  IbmCloudDirectLink_1DedicatedHosting,
  IbmCloudDirectLink_1Exchange,
  IbmCloudDirectLink_2Connect,
  IbmCloudDirectLink_2Dedicated,
  IbmCloudDirectLink_2DedicatedHosting,
  IbmCloudEventNotification,
  IbmCloudEventStreams,
  IbmCloudForEducation,
  IbmCloudHsm,
  IbmCloudHyperProtectCryptoServices,
  IbmCloudHyperProtectDbaas,
  IbmCloudHyperProtectVs,
  IbmCloudInternetServices,
  IbmCloudIpsecVpn,
  IbmCloudKeyProtect,
  IbmCloudKubernetesService,
  IbmCloudLogging,
  IbmCloudMassDataMigration,
  IbmCloudPal,
  IbmCloudPrivilegedAccessGateway,
  IbmCloudProjects,
  IbmCloudResiliency,
  IbmCloudSecretsManager,
  IbmCloudSecurityComplianceCenter,
  IbmCloudSubnets,
  IbmCloudSysdigSecure,
  IbmCloudTransitGateway,
  IbmCloudVpcEndpoints,
  IbmCloudPakApplications,
  IbmCloudPakBusinessAutomation,
  IbmCloudPakData,
  IbmCloudPakIntegration,
  IbmCloudPakMantaAutomatedDataLineage,
  IbmCloudPakMulticloudMgmt,
  IbmCloudPakNetezza,
  IbmCloudPakNetworkAutomation,
  IbmCloudPakSecurity,
  IbmCloudPakSystem,
  IbmCloudPakWatsonAiops,
  IbmSecurity,
  IbmSecurityServices,
  IbmWatsonAssistant,
  IbmWatsonDiscovery,
  IbmWatsonKnowledgeCatalog,
  IbmWatsonKnowledgeStudio,
  IbmWatsonLanguageTranslator,
  IbmWatsonMachineLearning,
  IbmWatsonNaturalLanguageClassifier,
  IbmWatsonNaturalLanguageUnderstanding,
  IbmWatsonOpenscale,
  IbmWatsonOrders,
  IbmWatsonQuery,
  IbmWatsonSpeechToText,
  IbmWatsonStudio,
  IbmWatsonTextToSpeech,
  IbmWatsonToneAnalyzer,
  IbmZCloudModStack,
  IbmZEnvironmentsDevSecOps,
  IbmZCloudProvisioning,
  IbmZOsAiControlInterface,
  IbmZOsContainers,
  IbmZOsPackageManager,
  WatsonHealthICa_2D,
  IceAccretion,
  IceVision,
  QID,
  IdManagement,
  Idea,
  Identification,
  Image,
  ImageCopy,
  ImageMedical,
  ImageReference,
  ImageSearch,
  ImageSearchAlt,
  WatsonHealthImageAvailabilityLocal,
  WatsonHealthImageAvailabilityRetrieving,
  WatsonHealthImageAvailabilityUnavailable,
  ImageService,
  ImportExport,
  ImproveRelevance,
  InProgress,
  InProgressError,
  InProgressWarning,
  Incomplete,
  IncompleteCancel,
  IncompleteError,
  IncompleteWarning,
  IncreaseLevel,
  Industry,
  Information,
  InformationDisabled,
  InformationFilled,
  InformationSquare,
  InformationSquareFilled,
  InfrastructureClassic,
  Insert,
  InsertPage,
  InsertSyntax,
  Inspection,
  InstanceBx,
  InstanceClassic,
  InstanceCx,
  InstanceMx,
  InstanceVirtual,
  Integration,
  IntentRequestActive,
  IntentRequestCreate,
  IntentRequestHeal,
  IntentRequestInactive,
  IntentRequestScaleIn,
  IntentRequestScaleOut,
  IntentRequestUninstall,
  IntentRequestUpgrade,
  Interactions,
  WatsonHealthInteractiveSegmentationCursor,
  Intersect,
  IntrusionPrevention,
  InventoryManagement,
  IotConnect,
  IotPlatform,
  Iso,
  IsoFilled,
  IsoOutline,
  JoinFull,
  JoinInner,
  JoinLeft,
  JoinOuter,
  JoinRight,
  Jpg,
  Json,
  JsonReference,
  JumpLink,
  KeepDry,
  Keyboard,
  KeyboardOff,
  Kubernetes,
  Label,
  Language,
  Laptop,
  Lasso,
  LassoPolygon,
  Launch,
  WatsonHealthLaunchStudy_1,
  WatsonHealthLaunchStudy_2,
  WatsonHealthLaunchStudy_3,
  Layers,
  Legend,
  LetterAa,
  LetterBb,
  LetterCc,
  LetterDd,
  LetterEe,
  LetterFf,
  LetterGg,
  LetterHh,
  LetterIi,
  LetterJj,
  LetterKk,
  LetterLl,
  LetterMm,
  LetterNn,
  LetterOo,
  LetterPp,
  LetterQq,
  LetterRr,
  LetterSs,
  LetterTt,
  LetterUu,
  LetterVv,
  LetterWw,
  LetterXx,
  LetterYy,
  LetterZz,
  License,
  LicenseDraft,
  LicenseGlobal,
  LicenseMaintenance,
  LicenseMaintenanceDraft,
  LicenseThirdParty,
  LicenseThirdPartyDraft,
  Lifesaver,
  Light,
  LightFilled,
  Lightning,
  Link,
  Linux,
  LinuxAlt,
  List,
  ListBoxes,
  ListBulleted,
  ListChecked,
  ListCheckedMirror,
  ListDropdown,
  ListNumbered,
  ListNumberedMirror,
  LoadBalancerApplication,
  LoadBalancerClassic,
  LoadBalancerGlobal,
  LoadBalancerListener,
  LoadBalancerLocal,
  LoadBalancerNetwork,
  LoadBalancerPool,
  LoadBalancerVpc,
  Location,
  LocationCompany,
  LocationCompanyFilled,
  LocationCurrent,
  LocationFilled,
  LocationHazard,
  LocationHazardFilled,
  LocationHeart,
  LocationHeartFilled,
  LocationPerson,
  LocationPersonFilled,
  LocationSave,
  LocationStar,
  LocationStarFilled,
  Locked,
  LogicalPartition,
  Login,
  LogoAngular,
  LogoAnsibleCommunity,
  LogoDigg,
  LogoDiscord,
  LogoFacebook,
  LogoFigma,
  LogoFlickr,
  LogoGithub,
  LogoGlassdoor,
  LogoInstagram,
  LogoInvision,
  LogoJupyter,
  LogoKeybase,
  LogoKubernetes,
  LogoLinkedin,
  LogoLivestream,
  LogoMastodon,
  LogoMedium,
  LogoNpm,
  LogoOpenshift,
  LogoPinterest,
  LogoPython,
  LogoQuora,
  LogoRScript,
  LogoReact,
  LogoRedHatAnsible,
  LogoSketch,
  LogoSkype,
  LogoSlack,
  LogoSnapchat,
  LogoSvelte,
  LogoTumblr,
  LogoTwitter,
  LogoVmware,
  LogoVmwareAlt,
  LogoVue,
  LogoWechat,
  LogoXing,
  LogoYelp,
  LogoYoutube,
  Logout,
  Loop,
  MacCommand,
  MacOption,
  MacShift,
  MachineLearning,
  MachineLearningModel,
  MagicWand,
  MagicWandFilled,
  WatsonHealthMagnify,
  MailAll,
  MailReply,
  WatsonHealthMammogram,
  ManageProtection,
  ManagedSolutions,
  Map,
  MapCenter,
  MapIdentify,
  MapBoundary,
  MapBoundaryVegetation,
  MarineWarning,
  MathCurve,
  QMatrix,
  Maximize,
  MediaLibrary,
  MediaLibraryFilled,
  MediaCast,
  Medication,
  MedicationAlert,
  MedicationReminder,
  Menu,
  MessageQueue,
  Meter,
  MeterAlt,
  Microphone,
  MicrophoneFilled,
  MicrophoneOff,
  MicrophoneOffFilled,
  Microscope,
  Microservices_1,
  Microservices_2,
  Migrate,
  MigrateAlt,
  Milestone,
  MilitaryCamp,
  Minimize,
  Misuse,
  MisuseOutline,
  MixedRainHail,
  Mobile,
  MobileAdd,
  MobileAudio,
  MobileCheck,
  MobileDownload,
  MobileLandscape,
  MobilityServices,
  Model,
  ModelAlt,
  ModelReference,
  ModelBuilder,
  ModelBuilderReference,
  Money,
  Monster,
  Monument,
  Moon,
  Moonrise,
  Moonset,
  MostlyCloudy,
  MostlyCloudyNight,
  Mountain,
  Mov,
  Move,
  Movement,
  Mp3,
  Mp4,
  Mpeg,
  Mpg2,
  Music,
  MusicAdd,
  MusicRemove,
  NameSpace,
  NavaidCivil,
  NavaidDme,
  NavaidHelipad,
  NavaidMilitary,
  NavaidMilitaryCivil,
  NavaidNdb,
  NavaidNdbDme,
  NavaidPrivate,
  NavaidSeaplane,
  NavaidTacan,
  NavaidVhfor,
  NavaidVor,
  NavaidVordme,
  NavaidVortac,
  Need,
  Network_1,
  Network_2,
  Network_3,
  Network_3Reference,
  Network_4,
  Network_4Reference,
  NetworkAdminControl,
  NetworkEnterprise,
  NetworkOverlay,
  NetworkPublic,
  NewTab,
  NextFilled,
  NextOutline,
  NoImage,
  NoTicket,
  Nominal,
  WatsonHealthNominate,
  NonCertified,
  NoodleBowl,
  NotAvailable,
  NotSent,
  NotSentFilled,
  Notebook,
  NotebookReference,
  Notification,
  NotificationFilled,
  NotificationNew,
  NotificationOff,
  NotificationOffFilled,
  Number_0,
  Number_1,
  Number_2,
  Number_3,
  Number_4,
  Number_5,
  Number_6,
  Number_7,
  Number_8,
  Number_9,
  NumberSmall_0,
  NumberSmall_1,
  NumberSmall_2,
  NumberSmall_3,
  NumberSmall_4,
  NumberSmall_5,
  NumberSmall_6,
  NumberSmall_7,
  NumberSmall_8,
  NumberSmall_9,
  ObjectStorage,
  ObjectStorageAlt,
  ObservedHail,
  ObservedLightning,
  Omega,
  Opacity,
  OpenPanelBottom,
  OpenPanelFilledBottom,
  OpenPanelFilledLeft,
  OpenPanelFilledRight,
  OpenPanelFilledTop,
  OpenPanelLeft,
  OpenPanelRight,
  OpenPanelTop,
  QOperation,
  QOperationGauge,
  QOperationIf,
  OperationsField,
  OperationsRecord,
  OrderDetails,
  Ordinal,
  Outage,
  OutlookSevere,
  OverflowMenuHorizontal,
  OverflowMenuVertical,
  Overlay,
  Package,
  PackageTextAnalysis,
  PageFirst,
  PageLast,
  PageBreak,
  PageNumber,
  WatsonHealthPageScroll,
  PaintBrush,
  PaintBrushAlt,
  PalmTree,
  PanHorizontal,
  PanVertical,
  PanelExpansion,
  Paragraph,
  Parameter,
  ParentChild,
  PartitionAuto,
  PartitionCollection,
  PartitionRepartition,
  PartitionSame,
  PartitionSpecific,
  PartlyCloudy,
  PartlyCloudyNight,
  Partnership,
  PassengerDrinks,
  PassengerPlus,
  Password,
  Paste,
  Pause,
  PauseFilled,
  PauseOutline,
  PauseOutlineFilled,
  PauseFuture,
  PausePast,
  PcnENode,
  PcnMilitary,
  PcnPNode,
  PcnZNode,
  Pdf,
  PdfReference,
  Pedestrian,
  PedestrianFamily,
  PedestrianChild,
  Pen,
  PenFountain,
  Pending,
  PendingFilled,
  Percentage,
  PercentageFilled,
  Person,
  PersonFavorite,
  Pest,
  WatsonHealthPetImageB,
  WatsonHealthPetImageO,
  Phone,
  PhoneApplication,
  PhoneBlock,
  PhoneBlockFilled,
  PhoneFilled,
  PhoneIncoming,
  PhoneIncomingFilled,
  PhoneIp,
  PhoneOff,
  PhoneOffFilled,
  PhoneOutgoing,
  PhoneOutgoingFilled,
  PhoneSettings,
  PhoneVoice,
  PhoneVoiceFilled,
  PhraseSentiment,
  PicnicArea,
  PiggyBank,
  PiggyBankSlot,
  Pills,
  PillsAdd,
  PillsSubtract,
  Pin,
  PinFilled,
  Plan,
  Plane,
  PlanePrivate,
  PlaneSea,
  Play,
  PlayFilled,
  PlayFilledAlt,
  PlayOutline,
  PlayOutlineFilled,
  Playlist,
  Plug,
  PlugFilled,
  Png,
  PointOfPresence,
  WatsonHealthPointerText,
  Police,
  Policy,
  Popup,
  PortInput,
  PortOutput,
  Portfolio,
  Power,
  Ppt,
  PresentationFile,
  Pressure,
  PressureFilled,
  PreviousFilled,
  PreviousOutline,
  Printer,
  Product,
  ProgressBar,
  ProgressBarRound,
  Promote,
  PropertyRelationship,
  Purchase,
  WatsonHealthQcLaunch,
  QqPlot,
  QrCode,
  QuadrantPlot,
  Query,
  QueryQueue,
  Queued,
  Quotes,
  Radar,
  RadarEnhanced,
  RadarWeather,
  Radio,
  RadioCombat,
  RadioPushToTalk,
  RadioButton,
  RadioButtonChecked,
  Rain,
  RainDrizzle,
  RainHeavy,
  RainScattered,
  RainScatteredNight,
  RainDrop,
  Raw,
  Receipt,
  RecentlyViewed,
  Recommend,
  Recording,
  RecordingFilled,
  RecordingFilledAlt,
  Recycle,
  Redo,
  RefEvapotranspiration,
  ReferenceArchitecture,
  ReflectHorizontal,
  ReflectVertical,
  WatsonHealthRegionAnalysisArea,
  WatsonHealthRegionAnalysisVolume,
  WatsonHealthRegistration,
  Reminder,
  ReminderMedical,
  Renew,
  Repeat,
  RepeatOne,
  Replicate,
  Reply,
  ReplyAll,
  RepoArtifact,
  RepoSourceCode,
  Report,
  ReportData,
  RequestQuote,
  Reset,
  ResetAlt,
  Restart,
  Restaurant,
  RestaurantFine,
  Result,
  ResultDraft,
  ResultNew,
  ResultOld,
  RetryFailed,
  Review,
  Rewind_10,
  Rewind_30,
  Rewind_5,
  Road,
  RoadWeather,
  Roadmap,
  Rocket,
  Rotate,
  WatsonHealthRotate_180,
  WatsonHealthRotate_360,
  RotateClockwise,
  RotateClockwiseAlt,
  RotateClockwiseAltFilled,
  RotateClockwiseFilled,
  RotateCounterclockwise,
  RotateCounterclockwiseAlt,
  RotateCounterclockwiseAltFilled,
  RotateCounterclockwiseFilled,
  Router,
  RouterVoice,
  RouterWifi,
  Row,
  RowCollapse,
  RowDelete,
  RowExpand,
  RowInsert,
  Rss,
  Rule,
  RuleCancelled,
  RuleDataQuality,
  RuleDraft,
  RuleFilled,
  RuleLocked,
  RulePartial,
  RuleTest,
  Ruler,
  RulerAlt,
  Run,
  RunMirror,
  QS,
  QSAlt,
  SailboatCoastal,
  SailboatOffshore,
  SankeyDiagram,
  SankeyDiagramAlt,
  Satellite,
  SatelliteRadar,
  SatelliteWeather,
  Save,
  WatsonHealthSaveAnnotation,
  WatsonHealthSaveImage,
  SaveModel,
  WatsonHealthSaveSeries,
  Scale,
  Scales,
  ScalesTipped,
  Scalpel,
  WatsonHealthScalpelCursor,
  WatsonHealthScalpelLasso,
  WatsonHealthScalpelSelect,
  Scan,
  ScanAlt,
  ScanDisabled,
  ScatterMatrix,
  Schematics,
  ScisControlTower,
  ScisTransparentSupply,
  Scooter,
  ScooterFront,
  Screen,
  ScreenOff,
  ScreenMap,
  ScreenMapSet,
  Script,
  ScriptReference,
  Sdk,
  Search,
  SearchAdvanced,
  SearchLocate,
  SearchLocateMirror,
  Security,
  SecurityServices,
  Select_01,
  Select_02,
  SelectWindow,
  Send,
  SendAlt,
  SendAltFilled,
  SendFilled,
  SendBackward,
  SendToBack,
  ServerDns,
  ServerProxy,
  ServerTime,
  ServiceDesk,
  ServiceId,
  SessionBorderControl,
  Settings,
  SettingsAdjust,
  SettingsCheck,
  SettingsServices,
  SettingsView,
  ShapeExcept,
  ShapeExclude,
  ShapeIntersect,
  ShapeJoin,
  ShapeUnite,
  Share,
  ShareKnowledge,
  ShoppingBag,
  ShoppingCart,
  ShoppingCartArrowDown,
  ShoppingCartArrowUp,
  ShoppingCartClear,
  ShoppingCartError,
  ShoppingCartMinus,
  ShoppingCartPlus,
  ShoppingCatalog,
  ShowDataCards,
  ShrinkScreen,
  ShrinkScreenFilled,
  Shuffle,
  Shuttle,
  SidePanelClose,
  SidePanelCloseFilled,
  SidePanelOpen,
  SidePanelOpenFilled,
  Sight,
  Sigma,
  SignalStrength,
  SimCard,
  SkillLevel,
  SkillLevelAdvanced,
  SkillLevelBasic,
  SkillLevelIntermediate,
  SkipBack,
  SkipBackFilled,
  SkipBackOutline,
  SkipBackOutlineFilled,
  SkipBackOutlineSolid,
  SkipBackSolidFilled,
  SkipForward,
  SkipForwardFilled,
  SkipForwardOutline,
  SkipForwardOutlineFilled,
  SkipForwardOutlineSolid,
  SkipForwardSolidFilled,
  Sleet,
  Slisor,
  Slm,
  Smell,
  Smoke,
  WatsonHealthSmoothing,
  WatsonHealthSmoothingCursor,
  Snooze,
  Snow,
  SnowBlizzard,
  SnowHeavy,
  SnowScattered,
  SnowScatteredNight,
  SnowDensity,
  Snowflake,
  Soccer,
  SoftwareResource,
  SoftwareResourceCluster,
  SoftwareResourceResource,
  SoilMoisture,
  SoilMoistureField,
  SoilMoistureGlobal,
  SoilTemperature,
  SoilTemperatureField,
  SoilTemperatureGlobal,
  SolarPanel,
  SortAscending,
  SortDescending,
  SortRemove,
  SpellCheck,
  WatsonHealthSpineLabel,
  Split,
  WatsonHealthSplitDiscard,
  SplitScreen,
  SprayPaint,
  Sprout,
  Sql,
  StackLimitation,
  WatsonHealthStackedMove,
  WatsonHealthStackedScrolling_1,
  WatsonHealthStackedScrolling_2,
  Stamp,
  Star,
  StarFilled,
  StarHalf,
  StarReview,
  WatsonHealthStatusAcknowledge,
  WatsonHealthStatusChange,
  WatsonHealthStatusPartialFail,
  WatsonHealthStatusResolved,
  StayInside,
  StemLeafPlot,
  Stethoscope,
  Stop,
  StopFilled,
  StopFilledAlt,
  StopOutline,
  StopOutlineFilled,
  StopSign,
  StopSignFilled,
  StoragePool,
  StorageRequest,
  Store,
  StormTracker,
  Strawberry,
  WatsonHealthStressBreathEditor,
  StringInteger,
  StringText,
  WatsonHealthStudyNext,
  WatsonHealthStudyPrevious,
  WatsonHealthStudyRead,
  WatsonHealthStudySkip,
  WatsonHealthStudyTransfer,
  WatsonHealthStudyUnread,
  WatsonHealthStudyView,
  WatsonHealthSubVolume,
  Subflow,
  SubflowLocal,
  SubnetAclRules,
  Subtract,
  SubtractAlt,
  SummaryKpi,
  SummaryKpiMirror,
  Sun,
  Sunrise,
  Sunset,
  SupportVectorMachine,
  Svg,
  Swim,
  SwitchLayer_2,
  SwitchLayer_3,
  Switcher,
  SysProvision,
  QT,
  QTAlt,
  Table,
  TableAlias,
  TableBuilt,
  TableShortcut,
  TableSplit,
  TableOfContents,
  Tablet,
  TabletLandscape,
  Tag,
  TagEdit,
  TagExport,
  TagGroup,
  TagImport,
  TagNone,
  Tank,
  Task,
  TaskAdd,
  TaskApproved,
  TaskAssetView,
  TaskComplete,
  TaskLocation,
  TaskRemove,
  TaskSettings,
  TaskStar,
  TaskTools,
  TaskView,
  Taste,
  Taxi,
  TcpIpService,
  Temperature,
  TemperatureCelsius,
  TemperatureCelsiusAlt,
  TemperatureFahrenheit,
  TemperatureFahrenheitAlt,
  TemperatureFeelsLike,
  TemperatureFrigid,
  TemperatureHot,
  TemperatureInversion,
  TemperatureMax,
  TemperatureMin,
  TemperatureWater,
  Template,
  Tennis,
  TennisBall,
  Term,
  Terminal,
  Terminal_3270,
  TestTool,
  TextAlignCenter,
  TextAlignJustify,
  TextAlignLeft,
  TextAlignMixed,
  TextAlignRight,
  TextAllCaps,
  TextBold,
  TextClearFormat,
  TextColor,
  TextCreation,
  TextFill,
  TextFont,
  TextFootnote,
  TextHighlight,
  TextIndent,
  TextIndentLess,
  TextIndentMore,
  TextItalic,
  TextKerning,
  TextLeading,
  TextLineSpacing,
  TextNewLine,
  TextScale,
  TextSelection,
  TextSmallCaps,
  TextStrikethrough,
  TextSubscript,
  TextSuperscript,
  TextTracking,
  TextUnderline,
  TextVerticalAlignment,
  TextWrap,
  WatsonHealthTextAnnotationToggle,
  TextLink,
  TextLinkAnalysis,
  TextMining,
  TextMiningApplier,
  Theater,
  ThisSideUp,
  WatsonHealthThreshold,
  Thumbnail_1,
  Thumbnail_2,
  WatsonHealthThumbnailPreview,
  ThumbsDown,
  ThumbsDownFilled,
  ThumbsUp,
  ThumbsUpFilled,
  Thunderstorm,
  ThunderstormScattered,
  ThunderstormScatteredNight,
  ThunderstormSevere,
  ThunderstormStrong,
  Ticket,
  Tides,
  Tif,
  Time,
  TimePlot,
  Timer,
  ToolBox,
  ToolKit,
  Tools,
  ToolsAlt,
  Tornado,
  TornadoWarning,
  Touch_1,
  Touch_1Filled,
  Touch_1Down,
  Touch_1DownFilled,
  Touch_2,
  Touch_2Filled,
  TouchInteraction,
  TrafficEvent,
  TrafficFlow,
  TrafficFlowIncident,
  TrafficIncident,
  TrafficWeatherIncident,
  TrafficCone,
  Train,
  TrainHeart,
  TrainProfile,
  TrainSpeed,
  TrainTicket,
  TrainTime,
  Tram,
  TransformBinary,
  TransformInstructions,
  TransformLanguage,
  Transgender,
  Translate,
  TransmissionLte,
  Transpose,
  TrashCan,
  Tree,
  TreeFallRisk,
  TreeView,
  TreeViewAlt,
  Trophy,
  TrophyFilled,
  TropicalStorm,
  TropicalStormModelTracks,
  TropicalStormTracks,
  TropicalWarning,
  Tsq,
  Tsunami,
  Tsv,
  TwoFactorAuthentication,
  TwoPersonLift,
  Txt,
  TxtReference,
  TypePattern,
  Types,
  QU1,
  QU2,
  QU3,
  Umbrella,
  Undefined,
  UndefinedFilled,
  Undo,
  UngroupObjects,
  Unknown,
  UnknownFilled,
  Unlink,
  Unlocked,
  Unsaved,
  UpToTop,
  UpdateNow,
  Upgrade,
  Upload,
  Usb,
  User,
  UserAccess,
  UserActivity,
  UserAdmin,
  UserAvatar,
  UserAvatarFilled,
  UserAvatarFilledAlt,
  UserCertification,
  UserData,
  UserFavorite,
  UserFavoriteAlt,
  UserFavoriteAltFilled,
  UserFilled,
  UserFollow,
  UserIdentification,
  UserMilitary,
  UserMultiple,
  UserOnline,
  UserProfile,
  UserRole,
  UserServiceDesk,
  UserSettings,
  UserSimulation,
  UserSpeaker,
  UserSponsor,
  UserXRay,
  UserProfileAlt,
  UvIndex,
  UvIndexAlt,
  UvIndexFilled,
  ValueVariable,
  Van,
  VegetationAsset,
  VehicleApi,
  VehicleConnected,
  VehicleInsights,
  VehicleServices,
  Version,
  VersionMajor,
  VersionMinor,
  VersionPatch,
  VerticalView,
  Video,
  VideoAdd,
  VideoChat,
  VideoFilled,
  VideoOff,
  VideoOffFilled,
  View,
  ViewFilled,
  ViewMode_1,
  ViewMode_2,
  ViewOff,
  ViewOffFilled,
  ViewNext,
  VirtualColumn,
  VirtualColumnKey,
  VirtualDesktop,
  VirtualMachine,
  VirtualPrivateCloud,
  VirtualPrivateCloudAlt,
  VisualRecognition,
  Vlan,
  VlanIbm,
  VmdkDisk,
  VoiceActivate,
  Voicemail,
  VolumeBlockStorage,
  VolumeDown,
  VolumeDownAlt,
  VolumeDownFilled,
  VolumeDownFilledAlt,
  VolumeFileStorage,
  VolumeMute,
  VolumeMuteFilled,
  VolumeObjectStorage,
  VolumeUp,
  VolumeUpAlt,
  VolumeUpFilled,
  VolumeUpFilledAlt,
  Vpn,
  VpnConnection,
  VpnPolicy,
  Wallet,
  Warning,
  WarningAlt,
  WarningAltFilled,
  WarningAltInverted,
  WarningAltInvertedFilled,
  WarningFilled,
  WarningHex,
  WarningHexFilled,
  WarningOther,
  WarningSquare,
  WarningSquareFilled,
  Watch,
  Watson,
  WatsonMachineLearning,
  WaveDirection,
  WaveHeight,
  WavePeriod,
  WeatherFrontCold,
  WeatherFrontStationary,
  WeatherFrontWarm,
  WeatherStation,
  Webhook,
  Websheet,
  Wheat,
  WhitePaper,
  Wifi,
  WifiController,
  WifiNotSecure,
  WifiOff,
  WifiSecure,
  WifiBridge,
  WifiBridgeAlt,
  Wikis,
  WindGusts,
  WindPower,
  WindStream,
  WatsonHealthWindowAuto,
  WatsonHealthWindowBase,
  WatsonHealthWindowBlackSaturation,
  WatsonHealthWindowOverlay,
  WatsonHealthWindowPreset,
  Windy,
  WindyDust,
  WindySnow,
  WindyStrong,
  WinterWarning,
  WintryMix,
  WirelessCheckout,
  Wmv,
  WordCloud,
  WorkflowAutomation,
  Workspace,
  WorkspaceImport,
  Worship,
  WorshipChristian,
  WorshipJewish,
  WorshipMuslim,
  QX,
  XAxis,
  Xls,
  Xml,
  QY,
  YAxis,
  QZ,
  ZLpar,
  ZSystems,
  ZAxis,
  Zip,
  ZipReference,
  ZoomArea,
  ZoomFit,
  ZoomIn,
  ZoomInArea,
  ZoomOut,
  ZoomOutArea,
  ZoomReset,
  WatsonHealthZoomPan,
  Zos,
  ZosSysplex
} from '@carbon/icons-react';

export const ICONS = {
  WatsonHealth3DCursor,
  WatsonHealth3DCursorAlt,
  WatsonHealth3DCurveAutoColon,
  WatsonHealth3DCurveAutoVessels,
  WatsonHealth3DCurveManual,
  WatsonHealth3DICa,
  WatsonHealth3DMprToggle,
  WatsonHealth3DPrintMesh,
  WatsonHealth3DSoftware,
  WatsonHealth3rdPartyConnected,
  _4K,
  _4KFilled,
  Accessibility,
  AccessibilityAlt,
  AccessibilityColor,
  AccessibilityColorFilled,
  Account,
  AccumulationIce,
  AccumulationPrecipitation,
  AccumulationRain,
  AccumulationSnow,
  Activity,
  Add,
  AddAlt,
  AddFilled,
  AddComment,
  AgricultureAnalytics,
  WatsonHealthAiResults,
  WatsonHealthAiResultsHigh,
  WatsonHealthAiResultsLow,
  WatsonHealthAiResultsMedium,
  WatsonHealthAiResultsUrgent,
  WatsonHealthAiResultsVeryHigh,
  WatsonHealthAiStatus,
  WatsonHealthAiStatusComplete,
  WatsonHealthAiStatusFailed,
  WatsonHealthAiStatusInProgress,
  WatsonHealthAiStatusQueued,
  WatsonHealthAiStatusRejected,
  AirlineDigitalGate,
  AirlineManageGates,
  AirlinePassengerCare,
  AirlineRapidBoard,
  Airplay,
  AirplayFilled,
  Airport_01,
  Airport_02,
  AirportLocation,
  Alarm,
  AlarmAdd,
  AlarmSubtract,
  AlignHorizontalCenter,
  AlignHorizontalLeft,
  AlignHorizontalRight,
  AlignVerticalBottom,
  AlignVerticalCenter,
  AlignVerticalTop,
  AlignBoxBottomCenter,
  AlignBoxBottomLeft,
  AlignBoxBottomRight,
  AlignBoxMiddleCenter,
  AlignBoxMiddleLeft,
  AlignBoxMiddleRight,
  AlignBoxTopCenter,
  AlignBoxTopLeft,
  AlignBoxTopRight,
  Analytics,
  AnalyticsCustom,
  AnalyticsReference,
  WatsonHealthAngle,
  WatsonHealthAnnotationVisibility,
  Aperture,
  Api,
  Api_1,
  App,
  AppConnectivity,
  Apple,
  AppleDash,
  Application,
  ApplicationMobile,
  ApplicationVirtual,
  ApplicationWeb,
  Apps,
  Archive,
  Area,
  AreaCustom,
  Arrival,
  WatsonHealthArrowAnnotation,
  ArrowDown,
  ArrowDownLeft,
  ArrowDownRight,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  ArrowUpLeft,
  ArrowUpRight,
  ArrowShiftDown,
  ArrowsHorizontal,
  ArrowsVertical,
  Asleep,
  AsleepFilled,
  Assembly,
  AssemblyCluster,
  AssemblyReference,
  Asset,
  AssetConfirm,
  AssetDigitalTwin,
  AssetView,
  Asterisk,
  At,
  Attachment,
  AudioConsole,
  AugmentedReality,
  WatsonHealthAutoScroll,
  Automatic,
  Autoscaling,
  Awake,
  Badge,
  BaggageClaim,
  Bar,
  Barcode,
  BareMetalServer,
  BareMetalServer_01,
  BareMetalServer_02,
  QBarrier,
  Basketball,
  BastionHost,
  Bat,
  BatchJob,
  BatchJobStep,
  BatteryCharging,
  BatteryEmpty,
  BatteryFull,
  BatteryHalf,
  BatteryLow,
  BatteryQuarter,
  Bee,
  BeeBat,
  Beta,
  Bicycle,
  Binoculars,
  QBlochSphere,
  BlockStorage,
  BlockStorageAlt,
  Blockchain,
  Blog,
  Bluetooth,
  BluetoothOff,
  Book,
  Bookmark,
  BookmarkAdd,
  BookmarkFilled,
  Boolean,
  Boot,
  BootVolume,
  BootVolumeAlt,
  BorderBottom,
  BorderFull,
  BorderLeft,
  BorderNone,
  BorderRight,
  BorderTop,
  Bot,
  Bottles_01,
  Bottles_01Dash,
  Bottles_02,
  Bottles_02Dash,
  BottlesContainer,
  Box,
  BoxExtraLarge,
  BoxLarge,
  BoxMedium,
  BoxSmall,
  BoxPlot,
  Branch,
  BreakingChange,
  BrightnessContrast,
  BringForward,
  BringToFront,
  WatsonHealthBrushFreehand,
  WatsonHealthBrushPolygon,
  BuildTool,
  Building,
  BuildingInsights_1,
  BuildingInsights_2,
  BuildingInsights_3,
  Bullhorn,
  Buoy,
  Bus,
  ButtonCentered,
  ButtonFlushLeft,
  CabinCare,
  CabinCareAlert,
  CabinCareAlt,
  Cad,
  Cafe,
  Calculation,
  CalculationAlt,
  Calculator,
  CalculatorCheck,
  Calendar,
  CalendarAdd,
  CalendarAddAlt,
  CalendarHeatMap,
  CalendarSettings,
  CalendarTools,
  Calibrate,
  Camera,
  CameraAction,
  Campsite,
  Car,
  CarFront,
  Carbon,
  CarbonUiBuilder,
  CarbonAccounting,
  CarbonForIbmDotcom,
  CarbonForIbmProduct,
  CaretDown,
  CaretLeft,
  CaretRight,
  CaretSort,
  CaretSortDown,
  CaretSortUp,
  CaretUp,
  CarouselHorizontal,
  CarouselVertical,
  Catalog,
  CatalogPublish,
  Categories,
  Category,
  CategoryAdd,
  CategoryAnd,
  CategoryNew,
  CategoryNewEach,
  QCcX,
  WatsonHealthCdArchive,
  WatsonHealthCdCreateArchive,
  WatsonHealthCdCreateExchange,
  Cda,
  CellTower,
  CenterCircle,
  CenterSquare,
  CenterToFit,
  Certificate,
  CertificateCheck,
  ChangeCatalog,
  CharacterDecimal,
  CharacterFraction,
  CharacterInteger,
  CharacterLowerCase,
  CharacterNegativeNumber,
  CharacterSentenceCase,
  CharacterUpperCase,
  CharacterWholeNumber,
  CharacterPatterns,
  ChargingStation,
  ChargingStationFilled,
  Chart_3D,
  ChartArea,
  ChartAreaSmooth,
  ChartAreaStepper,
  ChartAverage,
  ChartBar,
  ChartBarFloating,
  ChartBarOverlay,
  ChartBarStacked,
  ChartBarTarget,
  ChartBubble,
  ChartBubblePacked,
  ChartBullet,
  ChartCandlestick,
  ChartClusterBar,
  ChartColumn,
  ChartColumnFloating,
  ChartColumnTarget,
  ChartCombo,
  ChartComboStacked,
  ChartCustom,
  ChartErrorBar,
  ChartErrorBarAlt,
  ChartEvaluation,
  ChartHighLow,
  ChartHistogram,
  ChartLine,
  ChartLineData,
  ChartLineSmooth,
  ChartLogisticRegression,
  ChartMarimekko,
  ChartMaximum,
  ChartMedian,
  ChartMinimum,
  ChartMultiLine,
  ChartMultitype,
  ChartNetwork,
  ChartParallel,
  ChartPie,
  ChartPoint,
  ChartPopulation,
  ChartRadar,
  ChartRadial,
  ChartRelationship,
  ChartRing,
  ChartRiver,
  ChartRose,
  ChartScatter,
  ChartSpiral,
  ChartStacked,
  ChartStepper,
  ChartSunburst,
  ChartTSne,
  ChartTreemap,
  ChartVennDiagram,
  ChartViolinPlot,
  ChartWaterfall,
  ChartWinLoss,
  Chat,
  ChatLaunch,
  ChatOff,
  ChatOperational,
  ChatBot,
  Checkbox,
  CheckboxChecked,
  CheckboxCheckedFilled,
  CheckboxIndeterminate,
  CheckboxIndeterminateFilled,
  Checkmark,
  CheckmarkFilled,
  CheckmarkFilledError,
  CheckmarkFilledWarning,
  CheckmarkOutline,
  CheckmarkOutlineError,
  CheckmarkOutlineWarning,
  Chemistry,
  ChemistryReference,
  ChevronDown,
  ChevronLeft,
  ChevronMini,
  ChevronRight,
  ChevronSort,
  ChevronSortDown,
  ChevronSortUp,
  ChevronUp,
  Chip,
  Choices,
  ChooseItem,
  ChoroplethMap,
  CicsCmas,
  CicsDb2Connection,
  CicsExplorer,
  CicsProgram,
  CicsSit,
  CicsSitOverrides,
  CicsSystemGroup,
  CicsTransactionServerZos,
  CicsWuiRegion,
  CicsRegion,
  CicsRegionAlt,
  CicsRegionRouting,
  CicsRegionTarget,
  Cicsplex,
  CircleFilled,
  CircleSolid,
  CircleDash,
  WatsonHealthCircleMeasurement,
  CirclePacking,
  QCircuitComposer,
  Classification,
  ClassifierLanguage,
  Clean,
  Close,
  CloseFilled,
  CloseOutline,
  ClosedCaption,
  ClosedCaptionAlt,
  ClosedCaptionFilled,
  Cloud,
  CloudAlerting,
  CloudAuditing,
  CloudDataOps,
  CloudDownload,
  CloudLogging,
  CloudMonitoring,
  CloudOffline,
  CloudServiceManagement,
  CloudUpload,
  CloudApp,
  CloudCeiling,
  CloudFoundry_1,
  CloudFoundry_2,
  CloudRegistry,
  CloudSatellite,
  CloudSatelliteConfig,
  CloudSatelliteLink,
  CloudSatelliteServices,
  CloudServices,
  Cloudy,
  WatsonHealthCobbAngle,
  Code,
  CodeHide,
  CodeReference,
  CodeSigningService,
  Cognitive,
  Collaborate,
  CollapseAll,
  CollapseCategories,
  ColorPalette,
  ColorSwitch,
  Column,
  ColumnDelete,
  ColumnInsert,
  ColumnDependency,
  Commit,
  CommunicationUnified,
  Compare,
  Compass,
  QComposerEdit,
  Concept,
  ConditionPoint,
  ConditionWaitPoint,
  Connect,
  ConnectRecursive,
  ConnectReference,
  ConnectSource,
  ConnectTarget,
  ConnectionReceive,
  ConnectionSend,
  ConnectionTwoWay,
  ConnectionSignal,
  ConnectionSignalOff,
  Construction,
  ContainerRegistry,
  ContainerServices,
  ContainerSoftware,
  ContentDeliveryNetwork,
  ContentView,
  Continue,
  ContinueFilled,
  ContinuousDeployment,
  ContinuousIntegration,
  WatsonHealthContourDraw,
  WatsonHealthContourEdit,
  WatsonHealthContourFinding,
  Contrast,
  ConvertToCloud,
  Cookie,
  Copy,
  CopyFile,
  CopyLink,
  Corn,
  Corner,
  Coronavirus,
  Cost,
  CostTotal,
  Cough,
  Course,
  Covariate,
  Credentials,
  Crop,
  CropGrowth,
  CropHealth,
  WatsonHealthCrossReference,
  CrossTab,
  Crossroads,
  CrowdReport,
  CrowdReportFilled,
  Csv,
  QCU1,
  QCU3,
  Cube,
  CubeView,
  Currency,
  CurrencyBaht,
  CurrencyDollar,
  CurrencyEuro,
  CurrencyLira,
  CurrencyPound,
  CurrencyRuble,
  CurrencyRupee,
  CurrencyShekel,
  CurrencyWon,
  CurrencyYen,
  Cursor_1,
  Cursor_2,
  CustomerService,
  Cut,
  WatsonHealthCutInHalf,
  CutOut,
  QCY,
  Cyclist,
  QCZ,
  Dashboard,
  DashboardReference,
  Data_1,
  Data_2,
  DataBase,
  DataBaseAlt,
  DataCategorical,
  DataCenter,
  DataCheck,
  DataConnected,
  DataError,
  DataFormat,
  DataReference,
  DataRegular,
  DataSet,
  DataStructured,
  DataUnstructured,
  DataView,
  DataViewAlt,
  DataAccessor,
  DataBackup,
  DataBin,
  DataBlob,
  DataClass,
  DataCollection,
  DataDefinition,
  DataDiode,
  DataEnrichment,
  DataEnrichmentAdd,
  DataPlayer,
  DataQualityDefinition,
  DataRefinery,
  DataRefineryReference,
  DataShare,
  DataTable,
  DataTableReference,
  DataVis_1,
  DataVis_2,
  DataVis_3,
  DataVis_4,
  DataVolume,
  DataVolumeAlt,
  DatabaseDatastax,
  DatabaseElastic,
  DatabaseEnterpriseDb2,
  DatabaseEtcd,
  DatabaseMongodb,
  DatabasePostgreSql,
  DatabaseRabbit,
  DatabaseRedis,
  Datastore,
  Db2BufferPool,
  Db2DataSharingGroup,
  Db2Database,
  Debug,
  DecisionTree,
  Delivery,
  DeliveryAdd,
  DeliveryParcel,
  DeliveryTruck,
  WatsonHealthDenominate,
  Departure,
  Deploy,
  DeployRules,
  DeploymentPattern,
  DeploymentPolicy,
  DeploymentUnitData,
  DeploymentUnitExecution,
  DeploymentUnitInstallation,
  DeploymentUnitPresentation,
  DeploymentUnitTechnicalData,
  DeploymentUnitTechnicalExecution,
  DeploymentUnitTechnicalInstallation,
  DeploymentUnitTechnicalPresentation,
  DeskAdjustable,
  Development,
  Devices,
  DewPoint,
  DewPointFilled,
  Diagram,
  DiagramReference,
  WatsonHealthDicom_6000,
  WatsonHealthDicomOverlay,
  DirectLink,
  DirectionBearRight_01,
  DirectionBearRight_01Filled,
  DirectionBearRight_02,
  DirectionBearRight_02Filled,
  DirectionCurve,
  DirectionCurveFilled,
  DirectionFork,
  DirectionForkFilled,
  DirectionLoopLeft,
  DirectionLoopLeftFilled,
  DirectionLoopRight,
  DirectionLoopRightFilled,
  DirectionMerge,
  DirectionMergeFilled,
  DirectionRight_01,
  DirectionRight_01Filled,
  DirectionRight_02,
  DirectionRight_02Filled,
  DirectionRotaryFirstRight,
  DirectionRotaryFirstRightFilled,
  DirectionRotaryRight,
  DirectionRotaryRightFilled,
  DirectionRotaryStraight,
  DirectionRotaryStraightFilled,
  DirectionSharpTurn,
  DirectionSharpTurnFilled,
  DirectionStraight,
  DirectionStraightFilled,
  DirectionStraightRight,
  DirectionStraightRightFilled,
  DirectionUTurn,
  DirectionUTurnFilled,
  DirectoryDomain,
  DistributeHorizontalCenter,
  DistributeHorizontalLeft,
  DistributeHorizontalRight,
  DistributeVerticalBottom,
  DistributeVerticalCenter,
  DistributeVerticalTop,
  WatsonHealthDna,
  DnsServices,
  Doc,
  Document,
  DocumentAdd,
  DocumentAttachment,
  DocumentAudio,
  DocumentBlank,
  DocumentDownload,
  DocumentEpdf,
  DocumentExport,
  DocumentHorizontal,
  DocumentImport,
  DocumentMultiple_01,
  DocumentMultiple_02,
  DocumentPdf,
  DocumentPreliminary,
  DocumentProtected,
  DocumentSecurity,
  DocumentSigned,
  DocumentSketch,
  DocumentSubtract,
  DocumentTasks,
  DocumentUnknown,
  DocumentUnprotected,
  DocumentVertical,
  DocumentVideo,
  DocumentView,
  DocumentWordProcessor,
  DocumentWordProcessorReference,
  DocumentSentiment,
  DogWalker,
  DotMark,
  DoubleInteger,
  DownToBottom,
  Download,
  WatsonHealthDownloadStudy,
  DragHorizontal,
  DragVertical,
  Draggable,
  Draw,
  DrillBack,
  DrillDown,
  DrillThrough,
  Drink_01,
  Drink_02,
  DriverAnalysis,
  Drone,
  DroneDelivery,
  DroneFront,
  DroneVideo,
  DropPhoto,
  DropPhotoFilled,
  Drought,
  Dvr,
  Earth,
  EarthAmericas,
  EarthAmericasFilled,
  EarthEuropeAfrica,
  EarthEuropeAfricaFilled,
  EarthFilled,
  EarthSoutheastAsia,
  EarthSoutheastAsiaFilled,
  Earthquake,
  EdgeCluster,
  EdgeDevice,
  WatsonHealthEdgeEnhancement,
  WatsonHealthEdgeEnhancement_01,
  WatsonHealthEdgeEnhancement_02,
  WatsonHealthEdgeEnhancement_03,
  EdgeNode,
  EdgeNodeAlt,
  EdgeService,
  Edit,
  EditOff,
  EdtLoop,
  Education,
  Email,
  EmailNew,
  Encryption,
  EnergyRenewable,
  Enterprise,
  Equalizer,
  Erase,
  WatsonHealthErase_3D,
  Error,
  ErrorFilled,
  ErrorOutline,
  Event,
  EventSchedule,
  Events,
  EventsAlt,
  ExamMode,
  ExecutableProgram,
  Exit,
  ExpandAll,
  ExpandCategories,
  Explore,
  Export,
  Eyedropper,
  FaceActivated,
  FaceActivatedAdd,
  FaceActivatedFilled,
  FaceAdd,
  FaceCool,
  FaceDissatisfied,
  FaceDissatisfiedFilled,
  FaceDizzy,
  FaceDizzyFilled,
  FaceMask,
  FaceNeutral,
  FaceNeutralFilled,
  FacePending,
  FacePendingFilled,
  FaceSatisfied,
  FaceSatisfiedFilled,
  FaceWink,
  FaceWinkFilled,
  Factor,
  Fade,
  Favorite,
  FavoriteFilled,
  FavoriteHalf,
  FetchUpload,
  FetchUploadCloud,
  FileStorage,
  Filter,
  FilterEdit,
  FilterRemove,
  FilterReset,
  Finance,
  FingerprintRecognition,
  Fire,
  Firewall,
  FirewallClassic,
  Fish,
  FishMultiple,
  FitToHeight,
  FitToScreen,
  FitToWidth,
  Flag,
  FlagFilled,
  FlaggingTaxi,
  Flash,
  FlashFilled,
  FlashOff,
  FlashOffFilled,
  FlightInternational,
  FlightRoster,
  FlightSchedule,
  FloatingIp,
  Flood,
  FloodWarning,
  Floorplan,
  Flow,
  FlowConnection,
  FlowData,
  FlowModeler,
  FlowModelerReference,
  FlowStream,
  FlowStreamReference,
  FlowLogsVpc,
  Fog,
  Folder,
  FolderAdd,
  FolderDetails,
  FolderDetailsReference,
  FolderMoveTo,
  FolderOff,
  FolderOpen,
  FolderParent,
  FolderShared,
  Folders,
  ForecastHail,
  ForecastHail_30,
  ForecastLightning,
  ForecastLightning_30,
  Fork,
  Forum,
  Forward_10,
  Forward_30,
  Forward_5,
  Fragile,
  Friendship,
  FruitBowl,
  Function,
  FunctionMath,
  WatsonHealthFusionBlender,
  GameConsole,
  GameWireless,
  Gamification,
  GasStation,
  GasStationFilled,
  Gateway,
  GatewayApi,
  GatewayMail,
  GatewayPublic,
  GatewaySecurity,
  GatewayUserAccess,
  GatewayVpn,
  GenderFemale,
  GenderMale,
  GeneratePdf,
  Gif,
  Gift,
  Globe,
  Gradient,
  GraphicalDataFlow,
  Grid,
  Group,
  GroupAccess,
  GroupAccount,
  GroupPresentation,
  GroupResource,
  GroupSecurity,
  GroupObjects,
  GroupObjectsNew,
  GroupObjectsSave,
  Growth,
  Gui,
  GuiManagement,
  QH,
  Hail,
  WatsonHealthHangingProtocol,
  Harbor,
  HardwareSecurityModule,
  Hashtag,
  Haze,
  HazeNight,
  Hd,
  HdFilled,
  Hdr,
  Headphones,
  Headset,
  HealthCross,
  Hearing,
  HeatMap,
  HeatMap_02,
  HeatMap_03,
  HeatMapStocks,
  Helicopter,
  Help,
  HelpFilled,
  HelpDesk,
  QHintonPlot,
  WatsonHealthHl7Attributes,
  WatsonHealthHoleFilling,
  WatsonHealthHoleFillingCursor,
  Home,
  HorizontalView,
  Hospital,
  HospitalBed,
  Hotel,
  Hourglass,
  Html,
  HtmlReference,
  Http,
  Humidity,
  HumidityAlt,
  Hurricane,
  HybridNetworking,
  HybridNetworkingAlt,
  IbmBluepay,
  IbmContentServices,
  IbmDataReplication,
  IbmDatastage,
  IbmDb2,
  IbmDb2Alt,
  IbmMatch_360,
  IbmMq,
  IbmOpenEnterpriseLanguages,
  IbmPowerVs,
  IbmPowerWithVpc,
  IbmPrivatePathServices,
  IbmProcessMining,
  IbmSapOnPower,
  IbmTelehealth,
  IbmTenet,
  IbmToolchain,
  IbmVsiOnVpcForRegulatedIndustries,
  IbmCloud,
  IbmCloudAppId,
  IbmCloudCitrixDaas,
  IbmCloudContinuousDelivery,
  IbmCloudDedicatedHost,
  IbmCloudDirectLink_1Connect,
  IbmCloudDirectLink_1Dedicated,
  IbmCloudDirectLink_1DedicatedHosting,
  IbmCloudDirectLink_1Exchange,
  IbmCloudDirectLink_2Connect,
  IbmCloudDirectLink_2Dedicated,
  IbmCloudDirectLink_2DedicatedHosting,
  IbmCloudEventNotification,
  IbmCloudEventStreams,
  IbmCloudForEducation,
  IbmCloudHsm,
  IbmCloudHyperProtectCryptoServices,
  IbmCloudHyperProtectDbaas,
  IbmCloudHyperProtectVs,
  IbmCloudInternetServices,
  IbmCloudIpsecVpn,
  IbmCloudKeyProtect,
  IbmCloudKubernetesService,
  IbmCloudLogging,
  IbmCloudMassDataMigration,
  IbmCloudPal,
  IbmCloudPrivilegedAccessGateway,
  IbmCloudProjects,
  IbmCloudResiliency,
  IbmCloudSecretsManager,
  IbmCloudSecurityComplianceCenter,
  IbmCloudSubnets,
  IbmCloudSysdigSecure,
  IbmCloudTransitGateway,
  IbmCloudVpcEndpoints,
  IbmCloudPakApplications,
  IbmCloudPakBusinessAutomation,
  IbmCloudPakData,
  IbmCloudPakIntegration,
  IbmCloudPakMantaAutomatedDataLineage,
  IbmCloudPakMulticloudMgmt,
  IbmCloudPakNetezza,
  IbmCloudPakNetworkAutomation,
  IbmCloudPakSecurity,
  IbmCloudPakSystem,
  IbmCloudPakWatsonAiops,
  IbmSecurity,
  IbmSecurityServices,
  IbmWatsonAssistant,
  IbmWatsonDiscovery,
  IbmWatsonKnowledgeCatalog,
  IbmWatsonKnowledgeStudio,
  IbmWatsonLanguageTranslator,
  IbmWatsonMachineLearning,
  IbmWatsonNaturalLanguageClassifier,
  IbmWatsonNaturalLanguageUnderstanding,
  IbmWatsonOpenscale,
  IbmWatsonOrders,
  IbmWatsonQuery,
  IbmWatsonSpeechToText,
  IbmWatsonStudio,
  IbmWatsonTextToSpeech,
  IbmWatsonToneAnalyzer,
  IbmZCloudModStack,
  IbmZEnvironmentsDevSecOps,
  IbmZCloudProvisioning,
  IbmZOsAiControlInterface,
  IbmZOsContainers,
  IbmZOsPackageManager,
  WatsonHealthICa_2D,
  IceAccretion,
  IceVision,
  QID,
  IdManagement,
  Idea,
  Identification,
  Image,
  ImageCopy,
  ImageMedical,
  ImageReference,
  ImageSearch,
  ImageSearchAlt,
  WatsonHealthImageAvailabilityLocal,
  WatsonHealthImageAvailabilityRetrieving,
  WatsonHealthImageAvailabilityUnavailable,
  ImageService,
  ImportExport,
  ImproveRelevance,
  InProgress,
  InProgressError,
  InProgressWarning,
  Incomplete,
  IncompleteCancel,
  IncompleteError,
  IncompleteWarning,
  IncreaseLevel,
  Industry,
  Information,
  InformationDisabled,
  InformationFilled,
  InformationSquare,
  InformationSquareFilled,
  InfrastructureClassic,
  Insert,
  InsertPage,
  InsertSyntax,
  Inspection,
  InstanceBx,
  InstanceClassic,
  InstanceCx,
  InstanceMx,
  InstanceVirtual,
  Integration,
  IntentRequestActive,
  IntentRequestCreate,
  IntentRequestHeal,
  IntentRequestInactive,
  IntentRequestScaleIn,
  IntentRequestScaleOut,
  IntentRequestUninstall,
  IntentRequestUpgrade,
  Interactions,
  WatsonHealthInteractiveSegmentationCursor,
  Intersect,
  IntrusionPrevention,
  InventoryManagement,
  IotConnect,
  IotPlatform,
  Iso,
  IsoFilled,
  IsoOutline,
  JoinFull,
  JoinInner,
  JoinLeft,
  JoinOuter,
  JoinRight,
  Jpg,
  Json,
  JsonReference,
  JumpLink,
  KeepDry,
  Keyboard,
  KeyboardOff,
  Kubernetes,
  Label,
  Language,
  Laptop,
  Lasso,
  LassoPolygon,
  Launch,
  WatsonHealthLaunchStudy_1,
  WatsonHealthLaunchStudy_2,
  WatsonHealthLaunchStudy_3,
  Layers,
  Legend,
  LetterAa,
  LetterBb,
  LetterCc,
  LetterDd,
  LetterEe,
  LetterFf,
  LetterGg,
  LetterHh,
  LetterIi,
  LetterJj,
  LetterKk,
  LetterLl,
  LetterMm,
  LetterNn,
  LetterOo,
  LetterPp,
  LetterQq,
  LetterRr,
  LetterSs,
  LetterTt,
  LetterUu,
  LetterVv,
  LetterWw,
  LetterXx,
  LetterYy,
  LetterZz,
  License,
  LicenseDraft,
  LicenseGlobal,
  LicenseMaintenance,
  LicenseMaintenanceDraft,
  LicenseThirdParty,
  LicenseThirdPartyDraft,
  Lifesaver,
  Light,
  LightFilled,
  Lightning,
  Link,
  Linux,
  LinuxAlt,
  List,
  ListBoxes,
  ListBulleted,
  ListChecked,
  ListCheckedMirror,
  ListDropdown,
  ListNumbered,
  ListNumberedMirror,
  LoadBalancerApplication,
  LoadBalancerClassic,
  LoadBalancerGlobal,
  LoadBalancerListener,
  LoadBalancerLocal,
  LoadBalancerNetwork,
  LoadBalancerPool,
  LoadBalancerVpc,
  Location,
  LocationCompany,
  LocationCompanyFilled,
  LocationCurrent,
  LocationFilled,
  LocationHazard,
  LocationHazardFilled,
  LocationHeart,
  LocationHeartFilled,
  LocationPerson,
  LocationPersonFilled,
  LocationSave,
  LocationStar,
  LocationStarFilled,
  Locked,
  LogicalPartition,
  Login,
  LogoAngular,
  LogoAnsibleCommunity,
  LogoDigg,
  LogoDiscord,
  LogoFacebook,
  LogoFigma,
  LogoFlickr,
  LogoGithub,
  LogoGlassdoor,
  LogoInstagram,
  LogoInvision,
  LogoJupyter,
  LogoKeybase,
  LogoKubernetes,
  LogoLinkedin,
  LogoLivestream,
  LogoMastodon,
  LogoMedium,
  LogoNpm,
  LogoOpenshift,
  LogoPinterest,
  LogoPython,
  LogoQuora,
  LogoRScript,
  LogoReact,
  LogoRedHatAnsible,
  LogoSketch,
  LogoSkype,
  LogoSlack,
  LogoSnapchat,
  LogoSvelte,
  LogoTumblr,
  LogoTwitter,
  LogoVmware,
  LogoVmwareAlt,
  LogoVue,
  LogoWechat,
  LogoXing,
  LogoYelp,
  LogoYoutube,
  Logout,
  Loop,
  MacCommand,
  MacOption,
  MacShift,
  MachineLearning,
  MachineLearningModel,
  MagicWand,
  MagicWandFilled,
  WatsonHealthMagnify,
  MailAll,
  MailReply,
  WatsonHealthMammogram,
  ManageProtection,
  ManagedSolutions,
  Map,
  MapCenter,
  MapIdentify,
  MapBoundary,
  MapBoundaryVegetation,
  MarineWarning,
  MathCurve,
  QMatrix,
  Maximize,
  MediaLibrary,
  MediaLibraryFilled,
  MediaCast,
  Medication,
  MedicationAlert,
  MedicationReminder,
  Menu,
  MessageQueue,
  Meter,
  MeterAlt,
  Microphone,
  MicrophoneFilled,
  MicrophoneOff,
  MicrophoneOffFilled,
  Microscope,
  Microservices_1,
  Microservices_2,
  Migrate,
  MigrateAlt,
  Milestone,
  MilitaryCamp,
  Minimize,
  Misuse,
  MisuseOutline,
  MixedRainHail,
  Mobile,
  MobileAdd,
  MobileAudio,
  MobileCheck,
  MobileDownload,
  MobileLandscape,
  MobilityServices,
  Model,
  ModelAlt,
  ModelReference,
  ModelBuilder,
  ModelBuilderReference,
  Money,
  Monster,
  Monument,
  Moon,
  Moonrise,
  Moonset,
  MostlyCloudy,
  MostlyCloudyNight,
  Mountain,
  Mov,
  Move,
  Movement,
  Mp3,
  Mp4,
  Mpeg,
  Mpg2,
  Music,
  MusicAdd,
  MusicRemove,
  NameSpace,
  NavaidCivil,
  NavaidDme,
  NavaidHelipad,
  NavaidMilitary,
  NavaidMilitaryCivil,
  NavaidNdb,
  NavaidNdbDme,
  NavaidPrivate,
  NavaidSeaplane,
  NavaidTacan,
  NavaidVhfor,
  NavaidVor,
  NavaidVordme,
  NavaidVortac,
  Need,
  Network_1,
  Network_2,
  Network_3,
  Network_3Reference,
  Network_4,
  Network_4Reference,
  NetworkAdminControl,
  NetworkEnterprise,
  NetworkOverlay,
  NetworkPublic,
  NewTab,
  NextFilled,
  NextOutline,
  NoImage,
  NoTicket,
  Nominal,
  WatsonHealthNominate,
  NonCertified,
  NoodleBowl,
  NotAvailable,
  NotSent,
  NotSentFilled,
  Notebook,
  NotebookReference,
  Notification,
  NotificationFilled,
  NotificationNew,
  NotificationOff,
  NotificationOffFilled,
  Number_0,
  Number_1,
  Number_2,
  Number_3,
  Number_4,
  Number_5,
  Number_6,
  Number_7,
  Number_8,
  Number_9,
  NumberSmall_0,
  NumberSmall_1,
  NumberSmall_2,
  NumberSmall_3,
  NumberSmall_4,
  NumberSmall_5,
  NumberSmall_6,
  NumberSmall_7,
  NumberSmall_8,
  NumberSmall_9,
  ObjectStorage,
  ObjectStorageAlt,
  ObservedHail,
  ObservedLightning,
  Omega,
  Opacity,
  OpenPanelBottom,
  OpenPanelFilledBottom,
  OpenPanelFilledLeft,
  OpenPanelFilledRight,
  OpenPanelFilledTop,
  OpenPanelLeft,
  OpenPanelRight,
  OpenPanelTop,
  QOperation,
  QOperationGauge,
  QOperationIf,
  OperationsField,
  OperationsRecord,
  OrderDetails,
  Ordinal,
  Outage,
  OutlookSevere,
  OverflowMenuHorizontal,
  OverflowMenuVertical,
  Overlay,
  Package,
  PackageTextAnalysis,
  PageFirst,
  PageLast,
  PageBreak,
  PageNumber,
  WatsonHealthPageScroll,
  PaintBrush,
  PaintBrushAlt,
  PalmTree,
  PanHorizontal,
  PanVertical,
  PanelExpansion,
  Paragraph,
  Parameter,
  ParentChild,
  PartitionAuto,
  PartitionCollection,
  PartitionRepartition,
  PartitionSame,
  PartitionSpecific,
  PartlyCloudy,
  PartlyCloudyNight,
  Partnership,
  PassengerDrinks,
  PassengerPlus,
  Password,
  Paste,
  Pause,
  PauseFilled,
  PauseOutline,
  PauseOutlineFilled,
  PauseFuture,
  PausePast,
  PcnENode,
  PcnMilitary,
  PcnPNode,
  PcnZNode,
  Pdf,
  PdfReference,
  Pedestrian,
  PedestrianFamily,
  PedestrianChild,
  Pen,
  PenFountain,
  Pending,
  PendingFilled,
  Percentage,
  PercentageFilled,
  Person,
  PersonFavorite,
  Pest,
  WatsonHealthPetImageB,
  WatsonHealthPetImageO,
  Phone,
  PhoneApplication,
  PhoneBlock,
  PhoneBlockFilled,
  PhoneFilled,
  PhoneIncoming,
  PhoneIncomingFilled,
  PhoneIp,
  PhoneOff,
  PhoneOffFilled,
  PhoneOutgoing,
  PhoneOutgoingFilled,
  PhoneSettings,
  PhoneVoice,
  PhoneVoiceFilled,
  PhraseSentiment,
  PicnicArea,
  PiggyBank,
  PiggyBankSlot,
  Pills,
  PillsAdd,
  PillsSubtract,
  Pin,
  PinFilled,
  Plan,
  Plane,
  PlanePrivate,
  PlaneSea,
  Play,
  PlayFilled,
  PlayFilledAlt,
  PlayOutline,
  PlayOutlineFilled,
  Playlist,
  Plug,
  PlugFilled,
  Png,
  PointOfPresence,
  WatsonHealthPointerText,
  Police,
  Policy,
  Popup,
  PortInput,
  PortOutput,
  Portfolio,
  Power,
  Ppt,
  PresentationFile,
  Pressure,
  PressureFilled,
  PreviousFilled,
  PreviousOutline,
  Printer,
  Product,
  ProgressBar,
  ProgressBarRound,
  Promote,
  PropertyRelationship,
  Purchase,
  WatsonHealthQcLaunch,
  QqPlot,
  QrCode,
  QuadrantPlot,
  Query,
  QueryQueue,
  Queued,
  Quotes,
  Radar,
  RadarEnhanced,
  RadarWeather,
  Radio,
  RadioCombat,
  RadioPushToTalk,
  RadioButton,
  RadioButtonChecked,
  Rain,
  RainDrizzle,
  RainHeavy,
  RainScattered,
  RainScatteredNight,
  RainDrop,
  Raw,
  Receipt,
  RecentlyViewed,
  Recommend,
  Recording,
  RecordingFilled,
  RecordingFilledAlt,
  Recycle,
  Redo,
  RefEvapotranspiration,
  ReferenceArchitecture,
  ReflectHorizontal,
  ReflectVertical,
  WatsonHealthRegionAnalysisArea,
  WatsonHealthRegionAnalysisVolume,
  WatsonHealthRegistration,
  Reminder,
  ReminderMedical,
  Renew,
  Repeat,
  RepeatOne,
  Replicate,
  Reply,
  ReplyAll,
  RepoArtifact,
  RepoSourceCode,
  Report,
  ReportData,
  RequestQuote,
  Reset,
  ResetAlt,
  Restart,
  Restaurant,
  RestaurantFine,
  Result,
  ResultDraft,
  ResultNew,
  ResultOld,
  RetryFailed,
  Review,
  Rewind_10,
  Rewind_30,
  Rewind_5,
  Road,
  RoadWeather,
  Roadmap,
  Rocket,
  Rotate,
  WatsonHealthRotate_180,
  WatsonHealthRotate_360,
  RotateClockwise,
  RotateClockwiseAlt,
  RotateClockwiseAltFilled,
  RotateClockwiseFilled,
  RotateCounterclockwise,
  RotateCounterclockwiseAlt,
  RotateCounterclockwiseAltFilled,
  RotateCounterclockwiseFilled,
  Router,
  RouterVoice,
  RouterWifi,
  Row,
  RowCollapse,
  RowDelete,
  RowExpand,
  RowInsert,
  Rss,
  Rule,
  RuleCancelled,
  RuleDataQuality,
  RuleDraft,
  RuleFilled,
  RuleLocked,
  RulePartial,
  RuleTest,
  Ruler,
  RulerAlt,
  Run,
  RunMirror,
  QS,
  QSAlt,
  SailboatCoastal,
  SailboatOffshore,
  SankeyDiagram,
  SankeyDiagramAlt,
  Satellite,
  SatelliteRadar,
  SatelliteWeather,
  Save,
  WatsonHealthSaveAnnotation,
  WatsonHealthSaveImage,
  SaveModel,
  WatsonHealthSaveSeries,
  Scale,
  Scales,
  ScalesTipped,
  Scalpel,
  WatsonHealthScalpelCursor,
  WatsonHealthScalpelLasso,
  WatsonHealthScalpelSelect,
  Scan,
  ScanAlt,
  ScanDisabled,
  ScatterMatrix,
  Schematics,
  ScisControlTower,
  ScisTransparentSupply,
  Scooter,
  ScooterFront,
  Screen,
  ScreenOff,
  ScreenMap,
  ScreenMapSet,
  Script,
  ScriptReference,
  Sdk,
  Search,
  SearchAdvanced,
  SearchLocate,
  SearchLocateMirror,
  Security,
  SecurityServices,
  Select_01,
  Select_02,
  SelectWindow,
  Send,
  SendAlt,
  SendAltFilled,
  SendFilled,
  SendBackward,
  SendToBack,
  ServerDns,
  ServerProxy,
  ServerTime,
  ServiceDesk,
  ServiceId,
  SessionBorderControl,
  Settings,
  SettingsAdjust,
  SettingsCheck,
  SettingsServices,
  SettingsView,
  ShapeExcept,
  ShapeExclude,
  ShapeIntersect,
  ShapeJoin,
  ShapeUnite,
  Share,
  ShareKnowledge,
  ShoppingBag,
  ShoppingCart,
  ShoppingCartArrowDown,
  ShoppingCartArrowUp,
  ShoppingCartClear,
  ShoppingCartError,
  ShoppingCartMinus,
  ShoppingCartPlus,
  ShoppingCatalog,
  ShowDataCards,
  ShrinkScreen,
  ShrinkScreenFilled,
  Shuffle,
  Shuttle,
  SidePanelClose,
  SidePanelCloseFilled,
  SidePanelOpen,
  SidePanelOpenFilled,
  Sight,
  Sigma,
  SignalStrength,
  SimCard,
  SkillLevel,
  SkillLevelAdvanced,
  SkillLevelBasic,
  SkillLevelIntermediate,
  SkipBack,
  SkipBackFilled,
  SkipBackOutline,
  SkipBackOutlineFilled,
  SkipBackOutlineSolid,
  SkipBackSolidFilled,
  SkipForward,
  SkipForwardFilled,
  SkipForwardOutline,
  SkipForwardOutlineFilled,
  SkipForwardOutlineSolid,
  SkipForwardSolidFilled,
  Sleet,
  Slisor,
  Slm,
  Smell,
  Smoke,
  WatsonHealthSmoothing,
  WatsonHealthSmoothingCursor,
  Snooze,
  Snow,
  SnowBlizzard,
  SnowHeavy,
  SnowScattered,
  SnowScatteredNight,
  SnowDensity,
  Snowflake,
  Soccer,
  SoftwareResource,
  SoftwareResourceCluster,
  SoftwareResourceResource,
  SoilMoisture,
  SoilMoistureField,
  SoilMoistureGlobal,
  SoilTemperature,
  SoilTemperatureField,
  SoilTemperatureGlobal,
  SolarPanel,
  SortAscending,
  SortDescending,
  SortRemove,
  SpellCheck,
  WatsonHealthSpineLabel,
  Split,
  WatsonHealthSplitDiscard,
  SplitScreen,
  SprayPaint,
  Sprout,
  Sql,
  StackLimitation,
  WatsonHealthStackedMove,
  WatsonHealthStackedScrolling_1,
  WatsonHealthStackedScrolling_2,
  Stamp,
  Star,
  StarFilled,
  StarHalf,
  StarReview,
  WatsonHealthStatusAcknowledge,
  WatsonHealthStatusChange,
  WatsonHealthStatusPartialFail,
  WatsonHealthStatusResolved,
  StayInside,
  StemLeafPlot,
  Stethoscope,
  Stop,
  StopFilled,
  StopFilledAlt,
  StopOutline,
  StopOutlineFilled,
  StopSign,
  StopSignFilled,
  StoragePool,
  StorageRequest,
  Store,
  StormTracker,
  Strawberry,
  WatsonHealthStressBreathEditor,
  StringInteger,
  StringText,
  WatsonHealthStudyNext,
  WatsonHealthStudyPrevious,
  WatsonHealthStudyRead,
  WatsonHealthStudySkip,
  WatsonHealthStudyTransfer,
  WatsonHealthStudyUnread,
  WatsonHealthStudyView,
  WatsonHealthSubVolume,
  Subflow,
  SubflowLocal,
  SubnetAclRules,
  Subtract,
  SubtractAlt,
  SummaryKpi,
  SummaryKpiMirror,
  Sun,
  Sunrise,
  Sunset,
  SupportVectorMachine,
  Svg,
  Swim,
  SwitchLayer_2,
  SwitchLayer_3,
  Switcher,
  SysProvision,
  QT,
  QTAlt,
  Table,
  TableAlias,
  TableBuilt,
  TableShortcut,
  TableSplit,
  TableOfContents,
  Tablet,
  TabletLandscape,
  Tag,
  TagEdit,
  TagExport,
  TagGroup,
  TagImport,
  TagNone,
  Tank,
  Task,
  TaskAdd,
  TaskApproved,
  TaskAssetView,
  TaskComplete,
  TaskLocation,
  TaskRemove,
  TaskSettings,
  TaskStar,
  TaskTools,
  TaskView,
  Taste,
  Taxi,
  TcpIpService,
  Temperature,
  TemperatureCelsius,
  TemperatureCelsiusAlt,
  TemperatureFahrenheit,
  TemperatureFahrenheitAlt,
  TemperatureFeelsLike,
  TemperatureFrigid,
  TemperatureHot,
  TemperatureInversion,
  TemperatureMax,
  TemperatureMin,
  TemperatureWater,
  Template,
  Tennis,
  TennisBall,
  Term,
  Terminal,
  Terminal_3270,
  TestTool,
  TextAlignCenter,
  TextAlignJustify,
  TextAlignLeft,
  TextAlignMixed,
  TextAlignRight,
  TextAllCaps,
  TextBold,
  TextClearFormat,
  TextColor,
  TextCreation,
  TextFill,
  TextFont,
  TextFootnote,
  TextHighlight,
  TextIndent,
  TextIndentLess,
  TextIndentMore,
  TextItalic,
  TextKerning,
  TextLeading,
  TextLineSpacing,
  TextNewLine,
  TextScale,
  TextSelection,
  TextSmallCaps,
  TextStrikethrough,
  TextSubscript,
  TextSuperscript,
  TextTracking,
  TextUnderline,
  TextVerticalAlignment,
  TextWrap,
  WatsonHealthTextAnnotationToggle,
  TextLink,
  TextLinkAnalysis,
  TextMining,
  TextMiningApplier,
  Theater,
  ThisSideUp,
  WatsonHealthThreshold,
  Thumbnail_1,
  Thumbnail_2,
  WatsonHealthThumbnailPreview,
  ThumbsDown,
  ThumbsDownFilled,
  ThumbsUp,
  ThumbsUpFilled,
  Thunderstorm,
  ThunderstormScattered,
  ThunderstormScatteredNight,
  ThunderstormSevere,
  ThunderstormStrong,
  Ticket,
  Tides,
  Tif,
  Time,
  TimePlot,
  Timer,
  ToolBox,
  ToolKit,
  Tools,
  ToolsAlt,
  Tornado,
  TornadoWarning,
  Touch_1,
  Touch_1Filled,
  Touch_1Down,
  Touch_1DownFilled,
  Touch_2,
  Touch_2Filled,
  TouchInteraction,
  TrafficEvent,
  TrafficFlow,
  TrafficFlowIncident,
  TrafficIncident,
  TrafficWeatherIncident,
  TrafficCone,
  Train,
  TrainHeart,
  TrainProfile,
  TrainSpeed,
  TrainTicket,
  TrainTime,
  Tram,
  TransformBinary,
  TransformInstructions,
  TransformLanguage,
  Transgender,
  Translate,
  TransmissionLte,
  Transpose,
  TrashCan,
  Tree,
  TreeFallRisk,
  TreeView,
  TreeViewAlt,
  Trophy,
  TrophyFilled,
  TropicalStorm,
  TropicalStormModelTracks,
  TropicalStormTracks,
  TropicalWarning,
  Tsq,
  Tsunami,
  Tsv,
  TwoFactorAuthentication,
  TwoPersonLift,
  Txt,
  TxtReference,
  TypePattern,
  Types,
  QU1,
  QU2,
  QU3,
  Umbrella,
  Undefined,
  UndefinedFilled,
  Undo,
  UngroupObjects,
  Unknown,
  UnknownFilled,
  Unlink,
  Unlocked,
  Unsaved,
  UpToTop,
  UpdateNow,
  Upgrade,
  Upload,
  Usb,
  User,
  UserAccess,
  UserActivity,
  UserAdmin,
  UserAvatar,
  UserAvatarFilled,
  UserAvatarFilledAlt,
  UserCertification,
  UserData,
  UserFavorite,
  UserFavoriteAlt,
  UserFavoriteAltFilled,
  UserFilled,
  UserFollow,
  UserIdentification,
  UserMilitary,
  UserMultiple,
  UserOnline,
  UserProfile,
  UserRole,
  UserServiceDesk,
  UserSettings,
  UserSimulation,
  UserSpeaker,
  UserSponsor,
  UserXRay,
  UserProfileAlt,
  UvIndex,
  UvIndexAlt,
  UvIndexFilled,
  ValueVariable,
  Van,
  VegetationAsset,
  VehicleApi,
  VehicleConnected,
  VehicleInsights,
  VehicleServices,
  Version,
  VersionMajor,
  VersionMinor,
  VersionPatch,
  VerticalView,
  Video,
  VideoAdd,
  VideoChat,
  VideoFilled,
  VideoOff,
  VideoOffFilled,
  View,
  ViewFilled,
  ViewMode_1,
  ViewMode_2,
  ViewOff,
  ViewOffFilled,
  ViewNext,
  VirtualColumn,
  VirtualColumnKey,
  VirtualDesktop,
  VirtualMachine,
  VirtualPrivateCloud,
  VirtualPrivateCloudAlt,
  VisualRecognition,
  Vlan,
  VlanIbm,
  VmdkDisk,
  VoiceActivate,
  Voicemail,
  VolumeBlockStorage,
  VolumeDown,
  VolumeDownAlt,
  VolumeDownFilled,
  VolumeDownFilledAlt,
  VolumeFileStorage,
  VolumeMute,
  VolumeMuteFilled,
  VolumeObjectStorage,
  VolumeUp,
  VolumeUpAlt,
  VolumeUpFilled,
  VolumeUpFilledAlt,
  Vpn,
  VpnConnection,
  VpnPolicy,
  Wallet,
  Warning,
  WarningAlt,
  WarningAltFilled,
  WarningAltInverted,
  WarningAltInvertedFilled,
  WarningFilled,
  WarningHex,
  WarningHexFilled,
  WarningOther,
  WarningSquare,
  WarningSquareFilled,
  Watch,
  Watson,
  WatsonMachineLearning,
  WaveDirection,
  WaveHeight,
  WavePeriod,
  WeatherFrontCold,
  WeatherFrontStationary,
  WeatherFrontWarm,
  WeatherStation,
  Webhook,
  Websheet,
  Wheat,
  WhitePaper,
  Wifi,
  WifiController,
  WifiNotSecure,
  WifiOff,
  WifiSecure,
  WifiBridge,
  WifiBridgeAlt,
  Wikis,
  WindGusts,
  WindPower,
  WindStream,
  WatsonHealthWindowAuto,
  WatsonHealthWindowBase,
  WatsonHealthWindowBlackSaturation,
  WatsonHealthWindowOverlay,
  WatsonHealthWindowPreset,
  Windy,
  WindyDust,
  WindySnow,
  WindyStrong,
  WinterWarning,
  WintryMix,
  WirelessCheckout,
  Wmv,
  WordCloud,
  WorkflowAutomation,
  Workspace,
  WorkspaceImport,
  Worship,
  WorshipChristian,
  WorshipJewish,
  WorshipMuslim,
  QX,
  XAxis,
  Xls,
  Xml,
  QY,
  YAxis,
  QZ,
  ZLpar,
  ZSystems,
  ZAxis,
  Zip,
  ZipReference,
  ZoomArea,
  ZoomFit,
  ZoomIn,
  ZoomInArea,
  ZoomOut,
  ZoomOutArea,
  ZoomReset,
  WatsonHealthZoomPan,
  Zos,
  ZosSysplex
};

export type EIconType = keyof typeof ICONS;
