import { Tag } from 'antd';
import styled from 'styled-components';

type TagProps = {
  backgroundColor: string;
};

export const StyledTag = styled(Tag)<TagProps>`
  border: transparent;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ backgroundColor }) => backgroundColor};
  cursor: pointer;
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  padding: 4px 2px;
  align-items: center;
`;

export const TextWrapper = styled.div`
  font-weight: 600;
`;
