import { ISocketUser } from '@sdk/contracts';
import { create as createStore } from 'zustand';

interface IStore {
  onlineUsers: ISocketUser[];
  setOnlineUsers: (onlineUsers: ISocketUser[]) => void;
  isCurrentUserConnected: boolean;
  setIsCurrentUserConnected: (isConnected: boolean) => void;
}

export const useOnlineUsers = createStore<IStore>(set => ({
  onlineUsers: [],
  setOnlineUsers: (onlineUsers: ISocketUser[]) => set({ onlineUsers }),
  isCurrentUserConnected: false,
  setIsCurrentUserConnected: (isConnected: boolean) => set({ isCurrentUserConnected: isConnected })
}));
