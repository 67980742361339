import { useQuery } from '@tanstack/react-query';
import { create as createStore } from 'zustand';

import { sdk } from '@/services';

const INITIAL_PAGE = 1;
const INITIAL_LIMIT = 50;

const QuizService = sdk.getService('QuizService');

interface IStore {
  page: number;
  limit: number;
  search: string;
  setSearch: (search: string) => void;
  setPageAndLimit: (page: number, limit: number) => void;
}

const store = createStore<IStore>(set => ({
  page: INITIAL_PAGE,
  limit: INITIAL_LIMIT,
  search: '',
  setSearch: (search: string) => set(state => ({ ...state, search, page: INITIAL_PAGE })),
  setPageAndLimit: (page: number, limit: number) => set(state => ({ ...state, page, limit }))
}));

export const usePracticeQuizzesList = () => {
  const { page, limit, setPageAndLimit, search, setSearch } = store();
  const queryKey = ['quizzes', 'practice', `page-${page}`, `limit-${limit}`, search];

  const { isLoading, data } = useQuery({
    queryKey,
    queryFn: () => QuizService.getPracticeQuizzes(search, { page, limit })
  });

  const pagination = data && {
    page: data.page,
    limit: data.limit,
    hasNextPage: data.hasNextPage,
    hasPrevPage: data.hasPrevPage,
    nextPage: data.nextPage,
    pagingCounter: data.pagingCounter,
    prevPage: data.prevPage,
    totalDocs: data.totalDocs,
    totalPages: data.totalPages
  };

  return {
    practiceQuizzes: data?.docs,
    isLoading,
    pagination,
    setPageAndLimit,
    setSearch
  };
};
