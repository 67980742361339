import { ENotificationType, SOCKET_EVENTS } from '@constants';
import { INotification } from '@sdk/contracts';
import { notification } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Icon } from '@/components/Icon';
import { useSocket } from '@/hooks';
import { useMutateNotifications } from '@/store';
import { EProfileTabs } from '@/types';

export const NotificationHandler = () => {
  const { t } = useTranslation();
  const { socket } = useSocket();
  const navigateTo = useNavigate();
  const { markAsRead } = useMutateNotifications();
  const [notificationInstance, contextHolder] = notification.useNotification();

  const handleOpenNotification = (data: INotification) => () => {
    const { _id, type, metadata } = data;
    markAsRead(_id);
    switch (type) {
      case ENotificationType.NEW_EXAM_GROUP:
        navigateTo(`/profile?activeTab=${EProfileTabs.EXAM_GROUPS}`);
        break;
      case ENotificationType.NEW_EXAM_GROUP_EXAM:
        navigateTo(`/exam-groups?examGroupId=${metadata?.examGroup}`);
        break;
      default:
        break;
    }
    notificationInstance.destroy(_id);
  };

  const showNotification = (notificationSent: INotification) => {
    notificationInstance.open({
      key: notificationSent._id,
      message: t(ENotificationType[notificationSent.type]),
      description: notificationSent.title,
      placement: 'topRight',
      icon: <Icon i="Star" size="24" color="meadreg" />,
      duration: notificationSent.duration ?? 0,
      style: { cursor: 'pointer' },
      onClick: handleOpenNotification(notificationSent)
    });
  };

  useEffect(() => {
    if (!socket || socket.hasListeners(SOCKET_EVENTS.NOTIFICATION)) return;
    socket.on(SOCKET_EVENTS.NOTIFICATION, (notificationSent: INotification) =>
      showNotification(notificationSent)
    );

    return () => {
      socket?.off(SOCKET_EVENTS.NOTIFICATION);
    };
  }, [socket]);

  return <>{contextHolder}</>;
};
