import { AnalyticsTrackEvents } from '@constants';
import { useQuery } from '@tanstack/react-query';
import { create as createStore } from 'zustand';

import { useSegment, useDateFilter } from '@/hooks';
import { sdk } from '@/services';
import { useProfile } from '@/store';

type IStore = {
  tagId?: string;
  setTag: (tagId: string) => void;
};

const store = createStore<IStore>(set => ({
  setTag: (tagId: string) => set({ tagId })
}));

const QuizResultService = sdk.getService('QuizResultService');

export const useStudentQuizData = () => {
  const { dateFilter } = useDateFilter();
  const { profile } = useProfile();
  const { tagId, setTag: setStoreTag } = store();
  const { fireEvent } = useSegment();

  const profileId = profile?._id;

  const { isLoading, data } = useQuery({
    queryKey: ['students', 'quiz-stats', profileId, dateFilter, tagId],
    queryFn: () => QuizResultService.getStudentQuizResults(profileId!, dateFilter, tagId),
    enabled: !!profileId
  });

  const setTag = (tag: string) => {
    setStoreTag(tag);
    fireEvent(AnalyticsTrackEvents.STATISTICS_DETAIL_FILTER);
  };

  return {
    tagId,
    studentStats: data,
    isLoading,
    setTag
  };
};
