import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 200px;
  overflow-y: auto;

  .ant-divider-horizontal {
    margin: 16px 0;
  }
`;

export const EmptyWrapper = styled.div`
  color: ${({ theme }) => theme.colors.marrow};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
`;

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  width: 100%;
  cursor: pointer;
`;

export const TitleWrapper = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`;

export const DateWrapper = styled.div`
  color: ${({ theme }) => theme.colors.marrow};
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
`;
