import { ETaskStatus } from '@constants';
import { ITask } from '@sdk/contracts';
import { dateProvider } from '@utils';
import { Avatar, Button, Divider, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  ColumnWrapper,
  FooterWrapper,
  InfoWrapper,
  TaskHistoryWrapper,
  TitleWrapper,
  Wrapper
} from './TaskCard.styles';

import { Icon } from '@/components/Icon';
import { TaskStatusBadge } from '@/components/TaskStatusBadge';
import { useCoursesList } from '@/store';

type Props = {
  task: ITask;
};

export const TaskCard: React.FC<Props> = ({ task }) => {
  const { t } = useTranslation('CommonDashboardTranslations');
  const navigateTo = useNavigate();
  const { courses } = useCoursesList();

  const {
    title,
    deadline,
    statusInfo: { currentStatus },
    assignee: { avatar, name, lastName }
  } = task;

  const handleNavigateToCourse = () => {
    const course = courses?.find(c =>
      c.sections.some(s => s.activities.some(a => a.task?._id === task._id))
    );
    if (!course) return message.error(t('TASK_NOT_ON_YOUR_COURSES'));
    return navigateTo(`/courses/${course._id}?activeTab=TASKS_PANEL`);
  };

  return (
    <Wrapper onClick={handleNavigateToCourse}>
      <TitleWrapper>{title}</TitleWrapper>
      <Divider />
      <InfoWrapper>
        <ColumnWrapper>
          <div>{t('DEADLINE_DATE')}</div>
          <div>{dateProvider.format(deadline, 'dd/MM/yyyy')}</div>
        </ColumnWrapper>
        <ColumnWrapper>
          <div>{t('ASIGNED_TO')}</div>
          <div>
            <Avatar size={24} src={avatar?.url}>
              {!avatar?.url && `${name[0]}${lastName[0]}`}
            </Avatar>
            <div>{`${name} ${lastName}`}</div>
          </div>
        </ColumnWrapper>
      </InfoWrapper>
      <TaskHistoryWrapper>{t('TASK_HISTORY')}</TaskHistoryWrapper>
      <Divider />
      <FooterWrapper>
        <Button size="small" type="link" icon={<Icon i="Edit" />}>
          {t('EDIT_CONTENT')}
        </Button>
        <TaskStatusBadge
          icon={currentStatus === ETaskStatus.IN_REVIEW ? 'Edit' : undefined}
          status={currentStatus}
        />
      </FooterWrapper>
    </Wrapper>
  );
};
