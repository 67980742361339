import { EPostStatus } from '@constants';
import { IPost } from '@sdk/contracts';
import { Button, Form, Input, Modal, Popconfirm, Radio } from 'antd';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { usePostForm } from '../../pages/Common/Post/store';

import { CategorySelector } from './CategorySelector';
import { ColumnWrapper, TitleWrapper, Wrapper } from './PostForm.styles';
import { TopicSelector } from './TopicSelector';

import { HtmlEditor, TagSelector } from '@/components/Form';
import { Icon } from '@/components/Icon';
import { Upload } from '@/components/Upload';
import { RULES } from '@/constants';
import { useMutatePost } from '@/store';

type Props = {
  editable?: boolean;
};

const PREVIEW_SIZE = {
  width: '100%',
  height: 200
};

export const PostForm: React.FC<Props> = ({ editable }) => {
  const { isOpen, post, setPost, setIsOpen } = usePostForm();
  const { t } = useTranslation('CommonDashboardTranslations');
  const { createPost, updatePost, removePost, isPending } = useMutatePost();
  const [form] = Form.useForm();
  const navigateTo = useNavigate();

  const handleFinish = async (values: IPost) => {
    if (post?._id) {
      updatePost(
        {
          ...values,
          tags: values.tags || []
        },
        { onSuccess: handleCancel }
      );
    } else {
      createPost(
        {
          ...values,
          tags: values.tags || []
        },
        { onSuccess: handleCancel }
      );
    }
  };

  const handleCancel = () => {
    setPost();
    setIsOpen(false);
    form.resetFields();
  };

  const handleRemovePost = () => {
    if (!post) return;
    removePost(post._id, {
      onSuccess: () => {
        navigateTo(-1);
        handleCancel();
      }
    });
  };

  useEffect(() => {
    if (post)
      form.setFieldsValue({
        ...post,
        topic: post.topic?._id,
        categories: post.categories.map(c => c._id)
      });
  }, [post]);

  useEffect(() => {
    return () => {
      handleCancel();
    };
  }, []);

  const postStatusOptions = useMemo(
    () => Object.values(EPostStatus).map(status => ({ label: t(status), value: status })),
    []
  );

  if (!editable) return null;

  return (
    <Modal
      width={700}
      title={
        <TitleWrapper>
          <div>{t(post ? 'UPDATE' : 'CREATE')}</div>
          {post && (
            <Popconfirm onConfirm={handleRemovePost} title={t('DELETE_POST_CONFIRMATION')}>
              <Button type="link" size="small" danger icon={<Icon i="TrashCan" />}>
                {t('DELETE')}
              </Button>
            </Popconfirm>
          )}
        </TitleWrapper>
      }
      forceRender
      open={isOpen}
      onCancel={handleCancel}
      okButtonProps={{ loading: isPending }}
      cancelButtonProps={{ disabled: isPending }}
      onOk={form.submit}>
      <Form form={form} onFinish={handleFinish} layout="vertical">
        <Form.Item label={t('IMAGE')} name="featuredImage">
          <Upload accept="image/*" preview width={PREVIEW_SIZE.width} height={PREVIEW_SIZE.height}>
            <Icon i="ImageReference" />
          </Upload>
        </Form.Item>
        <Wrapper>
          <ColumnWrapper>
            <Form.Item name="_id" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="title" label={t('POST_TITLE')} rules={[RULES.REQUIRED]}>
              <Input />
            </Form.Item>
            <Form.Item label="Estado" name="status" rules={[RULES.REQUIRED]}>
              <Radio.Group options={postStatusOptions} optionType="button" />
            </Form.Item>
            <Form.Item label={t('TOPIC')} name="topic" rules={[RULES.REQUIRED]}>
              <TopicSelector />
            </Form.Item>
            <Form.Item label={t('CATEGORIES')} name="categories" rules={[RULES.REQUIRED]}>
              <CategorySelector />
            </Form.Item>
          </ColumnWrapper>
          <ColumnWrapper>
            <Form.Item name="tags" label={t('TAGS')}>
              <TagSelector mode="multiple" />
            </Form.Item>
            <Form.Item name="slug" label={t('SLUG')}>
              <Input />
            </Form.Item>
            <Form.Item name="lectureTime" label={t('LECTURE_TIME')}>
              <Input defaultValue={5} type="number" />
            </Form.Item>
            <Form.Item name="shortDescription" label={t('SHORT_DESCRIPTION')}>
              <Input.TextArea />
            </Form.Item>
          </ColumnWrapper>
        </Wrapper>
        <Form.Item name="content" label={t('POST_CONTENT')} rules={[RULES.REQUIRED]}>
          <HtmlEditor />
        </Form.Item>
      </Form>
    </Modal>
  );
};
