import styled from 'styled-components';

import { Icon } from '@/components/Icon';

export const UploadedFileInfo = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
`;

export const IconWrapper = styled(Icon)`
  background: ${({ theme }) => theme.colors.solitude};
  border-radius: 2px;
  padding: 4px;
  width: 24px;
  height: 24px;
`;

export const FileNameWrapper = styled.div<{ maxWidth?: string }>`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${({ maxWidth }) => (maxWidth ? maxWidth : '200px')};
  white-space: nowrap;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.colors.cadet};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  gap: 8px;
  flex-direction: column;
`;
