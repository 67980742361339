import { AnalyticsTrackEvents } from '@constants';
import {
  IAddEventAttachment,
  IAddEventLiveRecord,
  IAddRemoveAttendee,
  ICreateEvent,
  IRemoveEventAttachment,
  IRemoveEventLiveRecord,
  ISendEventMessage,
  IUpdateEvent
} from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { useSegment } from '@/hooks';
import { sdk } from '@/services';

const EventService = sdk.getService('EventService');

export const useMutateEvents = () => {
  const queryClient = useQueryClient();
  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['events'] });
  const { fireEvent } = useSegment();
  const { t } = useTranslation('CommonEventsTranslations');

  const { mutate: createEvent, isPending: isCreating } = useMutation({
    mutationFn: (event: ICreateEvent) => EventService.createEvent(event),
    onSuccess: () => {
      onSuccess();
      message.success(t('EVENT_CREATED_SUCCESSFULLY'));
    }
  });

  const { mutate: updateEvent, isPending: isUpdating } = useMutation({
    mutationFn: (update: IUpdateEvent) => EventService.updateEvent(update),
    onSuccess: () => {
      onSuccess();
      message.success(t('EVENT_UPDATED_SUCCESSFULLY'));
    }
  });

  const { mutate: removeEvent, isPending: isRemoving } = useMutation({
    mutationFn: (eventId: string) => EventService.removeEvent(eventId),
    onSuccess: () => {
      onSuccess();
      message.success(t('EVENT_REMOVED_SUCCESSFULLY'));
    }
  });

  const { mutate: addAttendee, isPending: isAddingAttendee } = useMutation({
    mutationFn: (add: IAddRemoveAttendee) => EventService.addAttendee(add),
    onSuccess: data => {
      onSuccess();
      fireEvent(AnalyticsTrackEvents.EVENTS_ASSIST, {
        eventName: data.title
      });
    }
  });

  const { mutate: removeAttendee, isPending: isRemovingAttendee } = useMutation({
    mutationFn: (remove: IAddRemoveAttendee) => EventService.removeAttendee(remove),
    onSuccess: data => {
      onSuccess();
      fireEvent(AnalyticsTrackEvents.EVENTS_NO_ASSIST, {
        eventName: data.title
      });
    }
  });

  const { mutate: sendMessage, isPending: isSending } = useMutation({
    mutationFn: (send: ISendEventMessage) => EventService.sendMessage(send),
    onSuccess
  });

  const { mutate: removeAttachment, isPending: isRemovingAttachment } = useMutation({
    mutationFn: (remove: IRemoveEventAttachment) => EventService.removeAttachment(remove),
    onSuccess: () => {
      onSuccess();
      message.success(t('ATTACHMENT_REMOVED_CORRECTLY'));
    }
  });

  const { mutate: addAttachment, isPending: isAddingAttachment } = useMutation({
    mutationFn: (add: IAddEventAttachment) => EventService.addAttachment(add),
    onSuccess: () => {
      onSuccess();
      message.success(t('ATTACHMENT_ADDED_CORRECTLY'));
    }
  });

  const { mutate: removeLiveRecord, isPending: isRemovingLiveRecord } = useMutation({
    mutationFn: (remove: IRemoveEventLiveRecord) => EventService.removeLiveRecord(remove),
    onSuccess: () => {
      onSuccess();
      message.success(t('ATTACHMENT_REMOVED_CORRECTLY'));
    }
  });

  const { mutate: addLiveRecord, isPending: isAddingLiveRecord } = useMutation({
    mutationFn: (add: IAddEventLiveRecord) => EventService.addLiveRecord(add),
    onSuccess: () => {
      onSuccess();
      message.success(t('ATTACHMENT_ADDED_CORRECTLY'));
    }
  });

  return {
    createEvent,
    updateEvent,
    removeEvent,
    addAttendee,
    removeAttendee,
    sendMessage,
    removeAttachment,
    addAttachment,
    removeLiveRecord,
    addLiveRecord,
    isWorking:
      isCreating ||
      isUpdating ||
      isRemoving ||
      isAddingAttendee ||
      isRemovingAttendee ||
      isSending ||
      isRemovingAttachment ||
      isAddingAttachment ||
      isRemovingLiveRecord ||
      isAddingLiveRecord
  };
};
