import styled from 'styled-components';

export const Wrapper = styled.div`
  p {
    max-width: 450px;
  }
  .ant-upload-drag-container > i {
    font-size: 74px;
    color: ${({ theme }) => theme.colors.gunmetal};
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  .ant-picker {
    width: 100%;
  }
  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    grid-template-columns: 336px 1fr;
    & > :nth-child(2) {
      max-width: 500px;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin-top: 20px;
`;
