import { IQuestion } from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { sdk } from '@/services';

const QuestionService = sdk.getService('QuestionService');

export const useMutateQuestions = () => {
  const queryClient = useQueryClient();
  const onSuccess = () => {
    queryClient.invalidateQueries({ queryKey: ['questions'] });
    queryClient.invalidateQueries({ queryKey: ['quizzes'] });
    queryClient.invalidateQueries({ queryKey: ['problem-reports'] });
  };
  const { t } = useTranslation('CommonQuizFormTranslations');

  const { mutate: createQuestion, isPending: isCreating } = useMutation({
    mutationFn: (create: IQuestion) => QuestionService.createQuestion(create),
    onSuccess: () => {
      onSuccess();
      message.success(t('QUESTION_CREATED'));
    }
  });

  const { mutate: createQuestions, isPending: isCreatingQuestions } = useMutation({
    mutationFn: (questionArray: Omit<IQuestion, '_id'>[]) =>
      QuestionService.createQuestions(questionArray),
    onSuccess
  });

  const { mutate: updateQuestion, isPending: isUpdating } = useMutation({
    mutationFn: (update: IQuestion) => QuestionService.updateQuestion(update),
    onSuccess: () => {
      onSuccess();
      message.success(t('QUESTION_UPDATED'));
    }
  });

  const { mutate: removeQuestion, isPending: isRemoving } = useMutation({
    mutationFn: (questionId: string) => QuestionService.removeQuestion(questionId),
    onSuccess: () => {
      onSuccess();
      message.warning(t('DELETE_QUESTION_SUCCESS'));
    }
  });

  const { mutate: removeQuestions, isPending: isRemovingBulk } = useMutation({
    mutationFn: (questionIds: string[]) => QuestionService.removeQuestions(questionIds),
    onSuccess: () => {
      onSuccess();
      message.warning(t('DELETE_QUESTIONS_SUCCESS'));
    }
  });

  return {
    createQuestion,
    createQuestions,
    updateQuestion,
    removeQuestion,
    removeQuestions,
    isWorking: isCreating || isCreatingQuestions || isUpdating || isRemoving || isRemovingBulk
  };
};
