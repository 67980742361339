import { AnalyticsTrackEvents } from '@constants';
import { ITrackingUpdate } from '@sdk/contracts';
import { useSearchParams } from 'react-router-dom';

import { useSegment } from '@/hooks';
import { useMutateMySubscriptions, useMySubscriptions } from '@/store';
import { getActivityTrace, getNextActivityTrack } from '@/utils';

export const useQuizActivity = () => {
  const { isWorking, updateActivityTrack } = useMutateMySubscriptions();
  const { subscriptions } = useMySubscriptions();
  const [searchParams] = useSearchParams();
  const { fireEvent } = useSegment();

  const subscriptionId = searchParams.get('subscription');
  const activityId = searchParams.get('activity');

  const updateTracking = (trackingData: ITrackingUpdate, onSuccess?: () => void) => {
    const subscription = subscriptions?.find(s => s._id === subscriptionId);
    if (!subscription) return;
    const metadata = getActivityTrace(subscription, trackingData.activity);

    updateActivityTrack(
      {
        subscriptionId: subscription._id,
        activityTrack: { ...metadata, ...trackingData }
      },
      {
        onSuccess
      }
    );
  };

  const setActivityAsCompleted = () => {
    const subscription = subscriptions?.find(s => s._id === subscriptionId);
    if (!activityId || !subscription) return;

    const { isCompleted } = getActivityTrackById(activityId);
    if (isCompleted) return;

    updateTracking(
      {
        activity: activityId,
        isCompleted: true
      },
      () => {
        const nextActivityTrack = getNextActivityTrack(subscription, activityId);

        const { course } = subscription;
        const { sections = [] } = course;
        const section = sections.find(s => s.activities.some(a => a._id === activityId));
        const activity = section?.activities.find(a => a._id === activityId);
        fireEvent(AnalyticsTrackEvents.ACTIVITY_COMPLETED, {
          sectionName: section?.name,
          activityName: activity?.name,
          courseName: course.name,
          activityType: activity?.type
        });
        if (!nextActivityTrack) return;
        updateTracking(nextActivityTrack);
      }
    );
  };

  const getActivityTrackById = (activity: string) => {
    const subscription = subscriptions?.find(s => s._id === subscriptionId);
    const { activityTracking = [] } = subscription || {};
    const currentActivityTrack = activityTracking.find(t => t.activity === activity);

    return {
      ...currentActivityTrack,
      activity
    };
  };

  return {
    isWorking,
    setActivityAsCompleted
  };
};
