export const CommonEventTranslations = {
  EVENTS: {
    es: 'Eventos',
    en: 'Events',
    cat: 'Esdeveniments',
    eus: 'Ekitaldis'
  },
  EVENT_LINK: {
    es: 'Enlace del evento',
    en: 'Event link',
    cat: 'Enllaç de l`esdeveniment',
    eus: 'Gertaeraren esteka'
  },
  SEND: {
    es: 'Enviar',
    en: 'Send',
    cat: 'Enviar',
    eus: 'Bidali'
  },
  ATTENDEES: {
    es: 'Asistentes',
    en: 'Attendees',
    cat: 'Assistents',
    eus: 'Asistentziak'
  },
  WHEN: {
    es: '¿Cuándo?',
    en: 'When?',
    cat: 'Quan?',
    eus: 'Noiz?'
  },
  ATTEND: {
    es: 'Asistir',
    en: 'Attend',
    cat: 'Assistir',
    eus: 'Asistitu'
  },
  SPEAKER: {
    es: 'Ponente',
    en: 'Speaker',
    cat: 'Ponent',
    eus: 'Hizlaria'
  },
  UPLOAD_ATTACHMENT: {
    es: 'Subir archivo',
    en: 'Upload file',
    cat: 'Pujar arxiu',
    eus: 'Igo fitxategia'
  },
  ARE_YOU_SURE_DELETE_THIS_ATTACHMENT: {
    es: '¿Estás seguro de eliminar este archivo?',
    en: 'Are you sure to delete this attachment?',
    cat: 'Estàs segur d`eliminar aquest arxiu?',
    eus: 'Ziur zaude fitxategi hau ezabatu nahi duzula?'
  },
  ATTACHMENT_REMOVED_CORRECTLY: {
    es: 'EL archivo se ha eliminado correctamente',
    en: 'The file has been removed correctly',
    cat: 'L`arxiu s`ha eliminat correctament',
    eus: 'Fitxategia zuzen ezabatu da'
  },
  ATTACHMENTS: {
    es: 'Archivos adjuntos',
    en: 'Attachments',
    cat: 'Adjunts',
    eus: 'Eranskinak'
  },
  LIVE_RECORDS: {
    es: 'Grabaciones en directo',
    en: 'Live recordings',
    cat: 'Enregistraments en directe',
    eus: 'Zuzeneko grabazioak'
  },
  NO_LIVE_RECORDS_YET: {
    es: 'Aún no hay grabaciones en directo',
    en: 'There are no live recordings yet',
    cat: 'Encara no hi ha enregistraments en directe',
    eus: 'Oraindik ez dago zuzeneko grabazioik'
  },
  EVENT_DELETE_CONFIRMATION: {
    es: '¿Estás seguro de que quieres eliminar este evento?',
    en: 'Are you sure you want to delete this event?',
    cat: 'Estàs segur que vols eliminar aquest esdeveniment?',
    eus: 'Ziur zaude ekitaldi hau ezabatu nahi duzula?'
  }
};
