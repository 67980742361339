import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import dayjs from 'dayjs';
import { ReactNode } from 'react';
import { Line } from 'react-chartjs-2';
import { useTheme } from 'styled-components';

import { WidgetBase } from '@/components/WidgetBase';
import { useDateFilter } from '@/hooks';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false
    }
  },
  scales: {
    y: {
      min: 0
    }
  }
};

interface Props {
  title: string;
  subTitle: string;
  addon?: ReactNode;
  data: number[];
  labels: string[];
}

export const PlatformTime: React.FC<Props> = ({ title, addon, data, labels, subTitle }) => {
  const { dateFilter } = useDateFilter();
  const dayDifference = dateFilter[1].diff(dateFilter[0], 'day');
  let format = 'DD/MM';
  if (dayDifference <= 1) {
    format = 'Ha';
  } else if (dayDifference > 200) {
    format = 'MMM';
  }
  const theme = useTheme();
  const closingAverageData = {
    labels: labels.map(label => dayjs(label).format(format)),
    datasets: [
      {
        data,
        borderColor: theme.colors.meadreg,
        backgroundColor: theme.colors.meadreg
      }
    ]
  };

  return (
    <WidgetBase title={title} subTitle={subTitle} addon={addon} headerSize="medium">
      <Line options={options} data={closingAverageData} />
    </WidgetBase>
  );
};
