import styled from 'styled-components';

import { ACTIVITY_COVER_BACKGROUND } from '../../constants/activity';

export const Wrapper = styled.div`
  height: 250px;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.white};
  background: ${`url(${ACTIVITY_COVER_BACKGROUND.AUDIO})`} no-repeat;
  background-size: 50%;
  background-position: 50% 50%;
  background-color: ${({ theme }) => theme.colors.gunmetal};
  border-radius: 10px;

  audio {
    display: none;
  }

  progress {
    width: 90%;
    margin: auto;
    height: 7px;
    cursor: pointer;
  }

  ::-webkit-progress-bar {
    background-color: ${({ theme }) => theme.colors.gunmetal};
  }
`;

export const Controls = styled.div`
  gap: 24px;
  display: flex;
  margin: auto auto 28px auto;
`;

export const Cover = styled.div`
  margin: 30px auto 0 auto;
  i {
    font-size: 120px;
    font-weight: 100;
  }
`;
