export enum ECourseStep {
  INFO = 'INFO',
  SECTIONS = 'SECTIONS',
  MATERIAL = 'MATERIAL',
  TASKS_PANEL = 'TASKS_PANEL',
  CONFIGURATION = 'CONFIGURATION',
  PUBLIC_INFO = 'PUBLIC_INFO',
  COMPLEMENTARY_MATERIAL = 'COMPLEMENTARY_MATERIAL',
  INTRODUCTION = 'INTRODUCTION'
}
