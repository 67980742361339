import { SOCKET_EVENTS } from '@constants';
import { IUser } from '@sdk/contracts';
import { Badge, Drawer } from 'antd';
import { useCallback, useEffect } from 'react';

import { Box } from '@/components/Utils';
import { useOnlineUsers, useSocket } from '@/hooks';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const OnlineUsers: React.FC<Props> = ({ isOpen, onClose }) => {
  const { onlineUsers = [], setOnlineUsers } = useOnlineUsers();
  const { socket } = useSocket();

  const handleSetOnlineUsers = useCallback(
    (users: IUser[]) => {
      setOnlineUsers(users);
    },
    [setOnlineUsers]
  );

  useEffect(() => {
    if (!socket) return;
    socket?.on(SOCKET_EVENTS.CONNECTED_USERS, (users: IUser[]) => {
      handleSetOnlineUsers(users);
    });
  }, [socket]);

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      title={
        <strong>
          Online users: <Badge count={onlineUsers.length} />
        </strong>
      }>
      <Box flexDirection="column">
        <div>
          {onlineUsers.map(u => (
            <div key={u._id}>{`${u.name} ${u.lastName} | ${u.role}`}</div>
          ))}
        </div>
      </Box>
    </Drawer>
  );
};
