export const UsersTranslations = {
  USERS: {
    es: 'Usuarios',
    en: 'Users',
    cat: 'Usuaris',
    eus: 'Erabiltzaileak'
  },
  NAME: {
    es: 'Nombre',
    en: 'Name',
    cat: 'Nom',
    eus: 'Izena'
  },
  LAST_NAME: {
    es: 'Apellido',
    en: 'Last Name',
    cat: 'Cognom',
    eus: 'Abizena'
  },
  EMAIL: {
    es: 'Email',
    en: 'Email',
    cat: 'Correu electrònic',
    eus: 'Posta elektronikoa'
  },
  USERNAME: {
    es: 'Nombre de usuario',
    en: 'Username',
    cat: 'Nom d`usuari',
    eus: 'Erabiltzaile izena'
  },
  STUDENTS: {
    es: 'Alumnos',
    en: 'Students',
    cat: 'Alumnes',
    eus: 'Ikasleak'
  },
  PASSWORD: {
    es: 'Contraseña',
    en: 'Password',
    cat: 'Contrasenya',
    eus: 'Pasahitza'
  },
  ROLE: {
    es: 'Rol',
    en: 'Role',
    cat: 'Rol',
    eus: 'Erantzukizuna'
  },
  AVATAR: {
    es: 'Avatar',
    en: 'Avatar',
    cat: 'Avatar',
    eus: 'Avatarra'
  },
  NEW_USER: {
    es: 'Nuevo Usuario',
    en: 'New User',
    cat: 'Nou Usuari',
    eus: 'Erabiltzaile Berria'
  },
  EDIT_USER: {
    es: 'Editar Usuario',
    en: 'Edit User',
    cat: 'Editar Usuari',
    eus: 'Erabiltzailea Aldatu'
  },
  SAVE_CHANGES: {
    es: 'Guardar Cambios',
    en: 'Save Changes',
    cat: 'Desa els canvis',
    eus: 'Aldaketak Gorde'
  },
  CREATE_USER: {
    es: 'Crear Usuario',
    en: 'Create User',
    cat: 'Crear Usuari',
    eus: 'Erabiltzailea Sortu'
  },
  STUDENT: {
    es: 'Estudiante',
    en: 'Student',
    cat: 'Estudiant',
    eus: 'Ikaslea'
  },
  TEACHER: {
    es: 'Profesor',
    en: 'Teacher',
    cat: 'Professor',
    eus: 'Irakaslea'
  },
  ADMIN: {
    es: 'Administrador',
    en: 'Admin',
    cat: 'Administrador',
    eus: 'Administrazioa'
  },
  MANAGER: {
    es: 'Gerente',
    en: 'Manager',
    cat: 'Gerent',
    eus: 'Kudeatzailea'
  },
  ACADEMY: {
    es: 'Academia',
    en: 'Academy',
    cat: 'Acadèmia',
    eus: 'Akademia'
  },
  USERS_TOTAL_COUNT: {
    es: '{{total}} usuarios',
    en: '{{total}} users',
    cat: '{{total}} usuaris',
    eus: '{{total}} erabiltzaile'
  },
  FILTERS: {
    es: 'Filtros',
    en: 'Filters',
    cat: 'Filtres',
    eus: 'Iragazkiak'
  },
  TAGS: {
    es: 'Formación',
    en: 'Category',
    cat: 'Formació',
    eus: 'Formakuntza'
  },
  DATE_RANGE: {
    es: 'Rango de fechas',
    en: 'Date range',
    cat: 'Rang de dates',
    eus: 'Dataren tartea'
  }
};
