import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Wrapper } from './OpenTicketsCount.styles';

type Props = { value?: number };

export const OpenTicketsCount: React.FC<Props> = ({ value = 0 }) => {
  const { t } = useTranslation('CommonDashboardTranslations');
  const navigateTo = useNavigate();

  const onClick = () => navigateTo('/tickets');

  return (
    <Wrapper onClick={onClick}>
      {value} {t('OPEN_DOUBTS').toLowerCase()}
    </Wrapper>
  );
};
