/**
 * It takes a number of seconds and returns a string in the format of minutes:seconds
 * @param {number} [duration] - The duration of the song in seconds.
 * @returns A string with the format of minutes:seconds
 */
export const formatDuration = (duration?: number) => {
  if (!duration) return '00:00';
  const minutes = Math.floor(duration / 60);
  const seconds = Math.round(duration % 60);
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};
