import { create as createStore } from 'zustand';

interface IStore {
  ticketId: string | null;
  setTicketId: (ticketId: string | null) => void;
}

export const useTicketViewer = createStore<IStore>(set => ({
  ticketId: null,
  setTicketId: (ticketId: string | null) => set({ ticketId })
}));
