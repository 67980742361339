import styled from 'styled-components';

export const Wrapper = styled.div`
  .ant-table-row:hover .shadow-background {
    background-image: linear-gradient(to right, rgb(250 250 250 / 7%), rgb(250 250 250 / 74%));
  }
`;

export const TooltipWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;
