import { createQueryString } from '@utils';
import { Dayjs } from 'dayjs';

import { IPaginatedResponse, IPagination } from '../common';
import { RequestService } from '../request';

import {
  ICreateQuizResult,
  IQuizResult,
  IUserQuizProgress,
  IUserQuizResults
} from './quiz-result.contract';

export class QuizResultService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  public getLastQuizResultByQuizId(quizId: string) {
    return this.fetcher<IQuizResult>(`/quiz-results/${quizId}`);
  }

  public getStudentQuizResults(id: string, dateFilter: [Dayjs, Dayjs], tag?: string) {
    const [startDate, endDate] = dateFilter;
    const queryParams = createQueryString({
      filters: {
        endDate,
        startDate,
        tag
      }
    });
    return this.fetcher<IUserQuizResults>(`/quiz-results/stats/users/${id}?${queryParams}`);
  }

  public calculateQuizResults(quizResults: ICreateQuizResult) {
    return this.poster<IQuizResult, ICreateQuizResult>(`/quiz-results/calculate`, quizResults);
  }

  public getUserProgress() {
    return this.fetcher<IUserQuizProgress>(`/quiz-results/stats/users/me/progress`);
  }

  public getQuizResults(pagination: IPagination) {
    const query = createQueryString({
      page: pagination.page,
      limit: pagination.limit
    });
    return this.fetcher<IPaginatedResponse<IQuizResult>>(`/quiz-results?${query}`);
  }
}
