export const StudentTicketsTranslations = {
  DOUBTS: {
    es: 'Dudas',
    en: 'Doubts',
    cat: 'Dubtes',
    eus: 'Zalantzak'
  },
  OPEN_DOUBT: {
    es: 'Abrir duda',
    en: 'Open doubt',
    cat: 'Obre dubte',
    eus: 'Ireki zalantza'
  },
  FILTER: {
    es: 'Filtrar',
    en: 'Filter',
    cat: 'Filtra',
    eus: 'Iragazi'
  },
  SEND: {
    es: 'Enviar',
    en: 'Send',
    cat: 'Envia',
    eus: 'Bidali'
  },
  SUBJECT: {
    es: 'Asunto',
    en: 'Subject',
    cat: 'Assumpte',
    eus: 'Gaia'
  },
  WRITE_HERE_YOUR_DOUBT: {
    es: 'Escribe aquí tu duda',
    en: 'Write here your doubt',
    cat: 'Escriu aquí el teu dubte',
    eus: 'Idatzi hemen zure zalantza'
  },
  COURSE: {
    es: 'Curso',
    en: 'Course',
    cat: 'Curs',
    eus: 'Ikastaroa'
  },
  TICKET_CREATED_SUCCESSFULLY: {
    es: 'Ticket creado correctamente',
    en: 'Ticket created successfully',
    cat: 'Ticket creat correctament',
    eus: 'Ticketa ongi sortu da'
  },
  SET_TICKET_AS_RESOLVED: {
    es: 'Marcar como resuelto',
    en: 'Set as resolved',
    cat: 'Marcar com a resolt',
    eus: 'Markatu ebaztuta'
  },
  TICKET_HAS_BEEN_SET_AS_RESOLVED: {
    es: 'El ticket ha sido marcado como resuelto',
    en: 'The ticket has been set as resolved',
    cat: 'El ticket ha estat marcat com a resolt',
    eus: 'Ticketa ebaztuta markatu da'
  },
  DESCRIPTION_REQUIRED: {
    es: 'La descripción es requerida',
    en: 'Description is required',
    cat: 'La descripció és requerida',
    eus: 'Deskribapena beharrezkoa da'
  },
  DELIVER: {
    es: 'Entregar',
    en: 'Deliver',
    cat: 'Lliurar',
    eus: 'Entregatu'
  },
  CSAT_RATING_TITLE: {
    es: '¿Estás satisfecho con la resolución de tu duda?',
    en: 'Are you satisfied with the resolution of your doubt?',
    cat: 'Estàs satisfet amb la resolució del teu dubte?',
    eus: 'Zalantzaren ebazpenarekin pozik zaude?'
  },
  THANKS_FOR_YOUR_FEEDBACK: {
    es: 'Gracias por tu feedback 😊',
    en: 'Thanks for your feedback 😊',
    cat: 'Gràcies pel teu feedback 😊',
    eus: 'Mila esker zure feedbackagatik 😊'
  },
  TICKETS_FORM_ADVICE: {
    es: 'Solo puedes subir archivos y texto por separado',
    en: 'You can only upload files and text separately',
    cat: 'Només pots pujar fitxers i text per separat',
    eus: 'Fitxategiak eta testua bakarrik igo ditzakezu bereizita'
  },
  COURSE_REQUIRED: {
    es: 'El curso es requerido',
    en: 'Course is required',
    cat: 'El curs és requerit',
    eus: 'Ikastaroa beharrezkoa da'
  },
  CREATE_TICKET_REDIRECTION: {
    es: 'Para abrir una duda, dirígete a una lección y pulsa en "Hacer una pregunta".',
    en: 'To open a doubt, go to a lesson and click on "Ask a question".',
    cat: 'Per obrir un dubte, dirigeix-te a una lliçó i prem "Fer una pregunta".',
    eus: 'Zalantza bat irekitzeko, joan lekziora eta sakatu "Galdera bat egiteko".'
  }
};
