import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  & > :first-child {
    margin-bottom: 1rem;
  }

  & > :nth-child(2) {
    & > * {
      margin-bottom: 1rem;
    }
  }

  & div.ant-collapse-header[role='tab'] {
    align-items: center;
  }
`;
