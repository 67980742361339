import { EPostStatus } from '@constants';

export const CommonDashboardTranslations = {
  HI: {
    es: 'Hola, {{name}}!',
    en: 'Hi, {{name}}!',
    cat: 'Hola, {{name}}!',
    eus: 'Kaixo, {{name}}!'
  },
  HI_STUDENT: {
    es: '¡Hola {{name}}! ¡A por todas con el estudio!',
    en: 'Hi {{name}}! Go for it with the study!',
    cat: 'Hola {{name}}! Endavant amb l’estudi!',
    eus: 'Kaixo {{name}}! Ikasketekin aurrera!'
  },
  NEWS: {
    es: 'Novedades',
    en: 'News',
    cat: 'Novetats',
    eus: 'Berriak'
  },
  COURSES: {
    es: 'cursos',
    en: 'courses',
    cat: 'cursos',
    eus: 'ikastaroak'
  },
  CATEGORIES: {
    es: 'Categorías',
    en: 'Categories',
    cat: 'Categories',
    eus: 'Kategoriak'
  },
  FILES: {
    es: 'Archivos',
    en: 'Files',
    cat: 'Fitxers',
    eus: 'Fitxategiak'
  },
  DOUBTS: {
    es: 'Dudas',
    en: 'Doubts',
    cat: 'Dubtes',
    eus: 'Zalantzak'
  },
  NEXT_EVENTS: {
    es: 'Próximos eventos',
    en: 'Next events',
    cat: 'Propers esdeveniments',
    eus: 'Hurrengo ekitaldiak'
  },
  REPORTS: {
    es: 'Reportes',
    en: 'Reports',
    cat: 'Informes',
    eus: 'Txostenak'
  },
  NO_EVENTS_IN_THIS_MONTH: {
    es: 'No hay eventos programados para {{month}}.',
    en: 'No events scheduled for {{month}}.',
    cat: 'No hi ha esdeveniments programats per {{month}}.',
    eus: '{{month}}rako ez dago ekitaldirik egiteko.'
  },
  POST_TITLE: {
    es: 'Título',
    en: 'Title',
    cat: 'Títol',
    eus: 'Izenburua'
  },
  POST_CONTENT: {
    es: 'Contenido',
    en: 'Content',
    cat: 'Contingut',
    eus: 'Edukia'
  },
  DOUBTS_TITLE: {
    es: 'Dudas',
    en: 'Doubts',
    cat: 'Dubtes',
    eus: 'Zalantzak'
  },
  INFO_OPTION_STUDENTS: {
    es: 'alumnos',
    en: 'students',
    cat: 'alumnes',
    eus: 'ikasleak'
  },
  INFO_OPTION_TEACHERS: {
    es: 'profesores',
    en: 'teachers',
    cat: 'professors',
    eus: 'irakasleak'
  },
  INFO_OPTION_COURSES: {
    es: 'cursos',
    en: 'courses',
    cat: 'cursos',
    eus: 'ikastaroak'
  },
  NO_NEWS_TO_SHOW: {
    es: 'No hay noticias para mostrar.',
    en: 'No news to show.',
    cat: 'No hi ha notícies per mostrar.',
    eus: 'Ez dago albisterik erakusteko.'
  },
  STATS: {
    es: 'Estadísticas',
    en: 'Stats',
    cat: 'Estadístiques',
    eus: 'Estatistikak'
  },
  DOWNLOAD_CSAT: {
    es: 'Descargar CSAT',
    en: 'Download CSAT',
    cat: 'Descarregar CSAT',
    eus: 'CSAT deskargatu'
  },
  DOWNLOAD_TICKETS: {
    es: 'Descargar dudas',
    en: 'Download tickets',
    cat: 'Descarregar tickets',
    eus: 'Dudak deskargatu'
  },
  DOWNLOAD_ACTIVITY_RATING: {
    es: 'Descargar valoraciones',
    en: 'Download activity rating',
    cat: 'Descarregar valoracions',
    eus: 'Balorazioak deskargatu'
  },
  DOWNLOAD_UPLOADED_FILES: {
    es: 'Descargar archivos subidos',
    en: 'Download uploaded files',
    cat: 'Descarregar fitxers pujats',
    eus: 'Igotako fitxategiak deskargatu'
  },
  NEXT_LIVE_CLASSES: {
    es: 'Próximas clases en directo',
    en: 'Next live classes',
    cat: 'Properes classes en viu',
    eus: 'Hurrengo klaseak zuzenean'
  },
  CREATE_LIVE_CLASS: {
    es: 'Crear clase',
    en: 'Create class',
    cat: 'Crear classe',
    eus: 'Sortu klase'
  },
  YOUR_CLASSES: {
    es: 'Das clase en estos cursos:',
    en: 'You teach in these courses:',
    cat: 'Donar classe en aquests cursos:',
    eus: 'Klaseak eman ikastaro hauek:'
  },
  PENDING_TASKS: {
    es: 'Actividades pendientes de realizar',
    en: 'Pending activities to perform',
    cat: 'Activitats pendents de realitzar',
    eus: 'Egin beharreko jarduera pendienteak'
  },
  PENDING_TASKS_COUNT: {
    es: '{{ totalTasks }} actividades pendientes',
    en: '{{ totalTasks }} pending activities',
    cat: '{{ totalTasks }} activitats pendents',
    eus: '{{ totalTasks }} jarduera pendiente'
  },
  TASK_NOT_ON_YOUR_COURSES: {
    es: 'Esta tarea está asociada a un curso que no impartes.',
    en: 'This task is associated with a course you do not teach.',
    cat: 'Aquesta tasca està associada a un curs que no impartiu.',
    eus: 'Jarduera hau irakas ezazun ikastarora lotuta dago.'
  },
  DEADLINE_DATE: {
    es: 'Fecha máxima de entrega:',
    en: 'Deadline date:',
    cat: 'Data màxima d’entrega:',
    eus: 'Entregako epea:'
  },
  ASIGNED_TO: {
    es: 'Asignada a:',
    en: 'Assigned to:',
    cat: 'Assignada a:',
    eus: 'Asignatua:'
  },
  EDIT_CONTENT: {
    es: 'Editar contenido',
    en: 'Edit content',
    cat: 'Editar contingut',
    eus: 'Editatu edukia'
  },
  TASK_HISTORY: {
    es: 'Historial de la tarea',
    en: 'Task history',
    cat: 'Historial de la tasca',
    eus: 'Lanaren historia'
  },
  OPEN_DOUBTS: {
    es: 'Dudas abiertas',
    en: 'Open doubts',
    cat: 'Dubtes oberts',
    eus: 'Zabalik dauden zalantzak'
  },
  NO_SCHEDULED_EVENTS_TITLE: {
    es: 'No hay eventos',
    en: 'There are no events',
    cat: 'No hi ha esdeveniments',
    eus: 'Ez dago ekitaldirik'
  },
  NO_SCHEDULED_EVENTS_DESCRIPTION: {
    es: 'No hay ningún evento de tu formación programado para los próximos 30 días.',
    en: 'There are no events from your formation scheduled for the next 30 days.',
    cat: 'No hi ha cap esdeveniment de la teva formació programat pels propers 30 dies.',
    eus: 'Ez dago zure formazioko ekitaldirik programatuta hurrengo 30 egunetarako.'
  },
  NO_PENDING_TASKS_TITLE: {
    es: 'Vaya, vaya, vaya...',
    en: 'Oops, oops, oops...',
    cat: 'Uy, uy, uy...',
    eus: 'Epa, epa, epa...'
  },
  NO_PENDING_TASKS_DESCRIPTION: {
    es: 'Parece que eres tan crack que no tienes ninguna tarea pendiente. Si quieres tareas puedes solicitarlas por el chat.',
    en: 'It seems that you are so crack that you do not have any pending tasks. If you want tasks you can request them through the chat.',
    cat: 'Sembla que ets tan crack que no tens cap tasca pendent. Si vols tasques pots sol·licitar-les pel xat.',
    eus: 'Zuretzat ez dago zeregin pendienteik, zuk zereginak eskatu ditzakezu txaten bidez.'
  },
  NO_OPEN_DOUBTS_TITLE: {
    es: 'Sin dudas abiertas',
    en: 'Without open doubts',
    cat: 'Sense dubtes oberts',
    eus: 'Zalantzarik gabe'
  },
  NO_OPEN_DOUBTS_DESCRIPTION: {
    es: 'O tus alumnos son genios y no tienen dudas o eres el más rápido del oeste respondiéndolas.',
    en: 'Either your students are geniuses and have no doubts or you are the fastest in the west answering them.',
    cat: 'O els teus alumnes són genis i no tenen dubtes o ets el més ràpid de l’oest responent-los.',
    eus: 'Zure ikasleak genioak dira eta ez dute zalantzarik edo zure erantzunak oso azkarak dira.'
  },
  IMAGE: {
    es: 'Imagen',
    en: 'Image',
    cat: 'Imatge',
    eus: 'Irudia'
  },
  [EPostStatus.PUBLISHED]: {
    es: 'Publicado',
    en: 'Published',
    cat: 'Publicat',
    eus: 'Argitaratua'
  },
  [EPostStatus.DRAFT]: {
    es: 'Borrador',
    en: 'Draft',
    cat: 'Esborrany',
    eus: 'Zirriborroa'
  },
  TOPIC: {
    es: 'Tema',
    en: 'Topic',
    cat: 'Tema',
    eus: 'Gaia'
  },
  CONTENT: {
    es: 'Contenido',
    en: 'Content',
    cat: 'Contingut',
    eus: 'Edukia'
  },
  TAGS: {
    es: 'Formaciones',
    en: 'Formations',
    cat: 'Formacions',
    eus: 'Formazioak'
  },
  SELECT_TOPIC: {
    es: 'Selecciona un tema',
    en: 'Select a topic',
    cat: 'Selecciona un tema',
    eus: 'Hautatu gaia'
  },
  CREATE: {
    es: 'Crear',
    en: 'Create',
    cat: 'Crear',
    eus: 'Sortu'
  },
  UPDATE: {
    es: 'Actualizar',
    en: 'Update',
    cat: 'Actualitzar',
    eus: 'Eguneratu'
  },
  SELECT_CATEGORY: {
    es: 'Selecciona una categoría',
    en: 'Select a category',
    cat: 'Selecciona una categoria',
    eus: 'Hautatu kategoria'
  },
  ADD: {
    es: 'Agregar',
    en: 'Add',
    cat: 'Afegir',
    eus: 'Gehitu'
  },
  NEW_CATEGORY: {
    es: 'Nueva categoría',
    en: 'New category',
    cat: 'Nova categoria',
    eus: 'Kategoria berria'
  },
  NEW_TOPIC: {
    es: 'Nuevo tema',
    en: 'New topic',
    cat: 'Nou tema',
    eus: 'Gaia berria'
  },
  SLUG: {
    es: 'Slug',
    en: 'Slug',
    cat: 'Slug',
    eus: 'Slug'
  },
  DELETE: {
    es: 'Eliminar',
    en: 'Delete',
    cat: 'Eliminar',
    eus: 'Ezabatu'
  },
  DELETE_POST_CONFIRMATION: {
    es: '¿Estás seguro de eliminar este post?',
    en: 'Are you sure to delete this post?',
    cat: 'Estàs segur d`eliminar aquest post?',
    eus: 'Ziur zaude post hau ezabatu nahi duzula?'
  },
  LECTURE_TIME: {
    es: 'Tiempo de lectura',
    en: 'Reading time',
    cat: 'Temps de lectura',
    eus: 'Irakurtzeko denbora'
  },
  SHORT_DESCRIPTION: {
    es: 'Descripción corta',
    en: 'Short description',
    cat: 'Descripció curta',
    eus: 'Deskribapen laburra'
  },
  CLASSES_AND_SYLLABUS: {
    es: 'Clases y temario',
    en: 'Classes and syllabus',
    cat: 'Classes i temari',
    eus: 'Klaseak eta syllabus'
  },
  GO_TO_CONTENT: {
    es: 'Ir al contenido',
    en: 'Go to content',
    cat: 'Anar al contingut',
    eus: 'Joan edukira'
  },
  VIDEO_DESCRIPTION: {
    es: '{{duration}} min',
    en: '{{duration}} min',
    cat: '{{duration}} min',
    eus: '{{duration}} min'
  },
  PDF_DESCRIPTION: {
    es: 'Documento',
    en: 'Document',
    cat: 'Document',
    eus: 'Dokumentua'
  },
  QUIZ_DESCRIPTION: {
    es: 'Test · {{questions}} preguntas',
    en: 'Test · {{questions}} questions',
    cat: 'Test · {{questions}} preguntes',
    eus: 'Test · {{questions}} galdera'
  },
  DELIVERABLE_DESCRIPTION: {
    es: 'Archivo a entregar para corrección',
    en: 'File to deliver for correction',
    cat: 'Fitxer a entregar per correcció',
    eus: 'Fitxategia bidaltzeko zuzentasunerako'
  },
  NEXT_CLASS: {
    es: '¡Hola {{name}}! Vas por esta actividad:',
    en: 'Hi {{name}}! You are going for this activity:',
    cat: 'Hola {{name}}! Vas per aquesta activitat:',
    eus: 'Kaixo {{name}}! Hau da zure jarduera:'
  },
  WHAT_TO_DO_TODAY: {
    es: 'Qué hacer hoy',
    en: 'What to do today',
    cat: 'Què fer avui',
    eus: 'Egun hau zer egin'
  },
  MY_LIVE_CLASSES: {
    es: 'Mis clases en directo',
    en: 'My live classes',
    cat: 'Les meves classes en viu',
    eus: 'Nire klaseak zuzenean'
  },
  MY_EXAM_GROUPS: {
    es: 'Mis convocatorias',
    en: 'My exam groups',
    cat: 'Les meves convocatòries',
    eus: 'Nire azterketa taldeak'
  },
  MULTIPLE_CHOICE_TEST: {
    es: 'Tipo test',
    en: 'Multiple choice test',
    cat: 'Tipus test',
    eus: 'Test mota'
  },
  FREE_MODE_TITLE: {
    es: 'Creador de tests',
    en: 'Test creator',
    cat: 'Creador de proves',
    eus: 'Proba sortzailea'
  },
  REVIEW_MODE_TITLE: {
    es: 'Modo repaso',
    en: 'Review mode',
    cat: 'Mode repàs',
    eus: 'Berrikusketa modua'
  },
  PRACTICE_MODE_TITLE: {
    es: 'Simulacros',
    en: 'Simulators',
    cat: 'Simulacres',
    eus: 'Simulakroak'
  },
  FREE_MODE_DESCRIPTION: {
    es: 'Elige un número de preguntas de los temas que quieras repasar o crea preguntas nuevas con IA.',
    en: 'Choose a number of questions from the topics you want to review or create new questions with AI.',
    cat: 'Tria un nombre de preguntes dels temes que vulguis repassar o crea preguntes noves amb IA.',
    eus: 'Aukeratu gaietako galdera kopurua berrikusi nahi dituzun edo sortu IArekin galdera berriak.'
  },
  REVIEW_MODE_DESCRIPTION: {
    es: 'Repasa las preguntas que hayas fallado de cualquier test que hayas hecho.',
    en: 'Review the questions you have failed from any test you have taken.',
    cat: 'Repassa les preguntes que hagis fallat de qualsevol prova que hagis fet.',
    eus: 'Berrikusi galdu dituzun galderak egin dituzun edozein probatik.'
  },
  PRACTICE_TESTS_DESCRIPTION: {
    es: 'Exámenes reales antiguos o tests creados por Ucademy que simulan una experiencia real.',
    en: 'Old real exams or tests created by Ucademy that simulate a real experience.',
    cat: 'Exàmens reals antics o proves creades per Ucademy que simulen una experiència real.',
    eus: 'Azterketak errealak edo Ucademyk sortutako probak errealitatea simulatzen dutenak.'
  },
  ALL_TESTS: {
    es: 'Todos los test',
    en: 'All tests',
    cat: 'Tots els tests',
    eus: 'Test guztiak'
  },
  MONTHLY_RANKING: {
    es: 'Ranking del mes en {{courseName}}',
    en: 'Monthly ranking in {{courseName}}',
    cat: 'Ranking del mes en {{courseName}}',
    eus: '{{courseName}}n hilabeteroko rankinga'
  },
  WEEKLY_POINTS: {
    es: 'Esta semana has ganado {{ points }} puntos',
    en: 'This week you have earned {{ points }} points',
    cat: 'Aquesta setmana has guanyat {{ points }} punts',
    eus: 'Aste honetan {{ points }} puntu irabazi dituzu'
  },
  UNLOCK_RANKING: {
    es: 'Desbloquea el ranking',
    en: 'Unlock the ranking',
    cat: 'Desbloqueja el ranking',
    eus: 'Desblokeatu rankinga'
  },
  GET_20_POINTS_TO_UNLOCK: {
    es: 'Consigue 20⚡ para entrar al ranking mensual',
    en: 'Get 20⚡ to enter the monthly ranking',
    cat: 'Aconsegueix 20⚡ per entrar al ranking mensual',
    eus: 'Lortu 20⚡ hilabeteroko rankingera sartzeko'
  },
  PREPARING_MONTHLY_RANKING: {
    es: 'Preparando el ranking de {{month}}',
    en: 'Preparing the ranking for {{month}}',
    cat: 'Preparant el ranking de {{month}}',
    eus: '{{month}}rako rankinga prestatzen'
  },
  PREPARING_MONTHLY_RANKING_DESCRIPTION: {
    es: 'Estamos preparando el ranking de este mes. En poco tiempo tendrás acceso.',
    en: "We are preparing this month's ranking. You will have access shortly.",
    cat: 'Estem preparant el ranking d’aquest mes. En poc temps tindràs accés.',
    eus: 'Hilabete honetako rankinga prestatzen ari gara. Laster izango duzu sarrera.'
  },
  INVITE_A_FRIEND_AND_EARN: {
    es: 'Invita a un amigo y gana {{amount}}€* 💶🫰🤑',
    en: 'Invite a friend and earn {{amount}}€* 💶🫰🤑',
    cat: 'Convida un amic i guanya {{amount}}€* 💶🫰🤑',
    eus: 'Deitu lagun bati eta irabazi {{amount}}€* 💶🫰🤑'
  },
  INVITE_4_FRIENDS_AND_EARN: {
    es: 'Invita a 4 amigos y consigue 600€',
    en: 'Invite 4 friends and get 600€',
    cat: 'Convida 4 amics i aconsegueix 600€',
    eus: 'Deitu 4 lagunei eta lortu 600€'
  },
  EMAIL_OF_YOUR_FRIEND: {
    es: 'Email de tu amigo o amiga',
    en: 'Email of your friend',
    cat: 'Email del teu amic o amiga',
    eus: 'Zure lagunaren emaila'
  },
  REFERRAL_MODAL_DESCRIPTION: {
    es: '*Nos pondremos en contacto contigo en caso de que se convierta en alumno de Ucademy para realizarte el pago.',
    en: '*We will contact you in case he becomes a Ucademy student to make the payment.',
    cat: '*Ens posarem en contacte amb tu en cas que es converteixi en alumne de Ucademy per realitzar-te el pagament.',
    eus: '*Zurekin harremanetan jarriko gara Ucademyko ikasle bihurtzen bada ordainketa egiteko.'
  },
  SEND_INVITATION: {
    es: 'Enviar invitación',
    en: 'Send invitation',
    cat: 'Enviar invitació',
    eus: 'Bidali gonbidapena'
  },
  EXIT: {
    es: 'Salir',
    en: 'Exit',
    cat: 'Sortir',
    eus: 'Irten'
  },
  INVITATION_SENT: {
    es: '¡Invitación enviada!',
    en: 'Invitation sent!',
    cat: 'Invitació enviada!',
    eus: 'Gonbidapena bidalia!'
  },
  INVITATION_FAILED: {
    es: '¡Ups! Algo ha fallado al enviar la invitación.',
    en: 'Oops! Something went wrong sending the invitation.',
    cat: '¡Ups! Alguna cosa ha fallat en enviar la invitació.',
    eus: '¡Ups! Zerbait gaizki joan da gonbidapena bidaltzean.'
  }
};
