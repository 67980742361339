import { ETaskStatus } from '@constants';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { Wrapper, TextWrapper } from './TaskStatusBadge.styles';

import { EIconType, Icon } from '@/components/Icon';
import { getTaskColorsByStatus } from '@/utils';

type Props = {
  status?: ETaskStatus;
  icon?: EIconType;
  onClick?: () => void;
};

export const TaskStatusBadge: React.FC<Props> = ({ status, icon, onClick }) => {
  const { t } = useTranslation('CourseTranslations');
  const { colors } = useTheme();

  if (!status) return null;

  const statusColors = getTaskColorsByStatus(status, colors);

  return (
    <Wrapper
      hasIcon={!!icon}
      backgroundColor={statusColors.backgroundColor}
      borderColor={statusColors.borderColor}>
      <TextWrapper color={statusColors.color}>{t(status)}</TextWrapper>
      {icon && <Icon i={icon} onClick={onClick} />}
    </Wrapper>
  );
};
