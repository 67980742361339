import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { sdk } from '@/services';

const MyAcademyStudentsService = sdk.getService('MyAcademyStudentsService');

export const useStudentsForExamGroupsSelector = (groupId: string, tags: string[]) => {
  const [search, setSearch] = useState('');

  const { isLoading, data, error } = useQuery({
    queryKey: ['exam-groups', 'students', 'selector', search, groupId, tags],
    queryFn: () => MyAcademyStudentsService.findStudentsForExamGroupsSelector(search, groupId, tags)
  });

  return {
    isLoading,
    students: data,
    setSearch,
    error
  };
};
